import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Configuration, ConfigurationAdapter} from '../model/configuration.model';
import {Room} from '../model/room.model';
import {Equipment} from '../model/equipment.model';
import {Set} from '../model/set.model';
import {ListGetterService} from "./generic/list-getter.service";
import {Job} from "../model/job.model";


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends ListGetterService<Job> implements Resolve<Configuration[]> {

  constructor(protected api: ApiService, private adapter: ConfigurationAdapter) {
    super('configuration/', api);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Configuration[]> | Promise<Configuration[]> | Configuration[] {
    return null;
  }

  getAllByRoom(room:Room, prefix:string = ''): Observable<Configuration[]> {
    return this.api.get(prefix + 'room/' + room._id + '/configurations').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  getAllBySet(set:Set, prefix:string = ''): Observable<Configuration[]> {
    return this.api.get(prefix + 'set/' + set._id + '/configurations').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  getAllByEquipment(equipment:Equipment, prefix:string = ''): Observable<Configuration[]> {
    return this.api.get(prefix + 'equipment/' + equipment._id + '/configurations').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string, prefix:string = ''): Observable<Configuration> {
    return this.api.get(prefix + 'configuration/' + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id:string, configuration:Configuration, prefix:string = ''): Observable<Configuration> {
    return this.api.put(prefix + 'configuration/' + id, configuration).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(route:string, configuration:Configuration, prefix:string = ''): Observable<Configuration> {
    return this.api.post(prefix + route + '/configurations', configuration).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options, prefix:string = ''): Observable<Configuration> {
    return this.api.delete(prefix + 'configuration/' + id, options);
  }
}
