import {Component, EventEmitter} from "@angular/core";
import {Set} from "../../../../model/set.model";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-list-set',
  templateUrl: './dialog-list-set.component.html',
  styleUrls: ['./dialog-list-set.component.scss']
})
export class DialogListSetComponent{

  select: EventEmitter<Set>;

  constructor(public dialogRef: MatDialogRef<DialogListSetComponent>) {
    this.select = new EventEmitter<Set>();
  }

  close(s:Set) {
    this.dialogRef.close(s);
  }

}
