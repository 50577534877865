import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {SpecialityService} from "../../../../services/speciality.service";
import {Speciality} from "../../../../model/speciality.model";

@Component({
  selector: 'app-dialog-speciality',
  templateUrl: './dialog-speciality.component.html',
  styleUrls: ['./dialog-speciality.component.scss']
})
export class DialogSpecialityComponent {

  profileForm;

  constructor(private service: SpecialityService, private dialogRef: MatDialogRef<DialogSpecialityComponent>, private fb:FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.profileForm = Speciality.formBuilder(this.fb, this.data.speciality);
  }

  onSubmit(){
    if (this.data.action == 'modify') {
      this.service.put(this.data.speciality._id, this.profileForm.value).subscribe((e) => {
        this.dialogRef.close(e);
      });
    }
    if (this.data.action == 'create') {
      this.service.post(this.profileForm.value).subscribe((e) => {
        this.dialogRef.close(e);
      });
    }
  }
}
