import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Tva} from './tva.model';
import {IFamily} from './interface/ifamily.interface';
import {EFamily} from './enum/family.enum';

export class Equipment implements IFamily {
  public _id: string;
  public label: string;
  public family: EFamily.Equipment;
  public cneh: string;
  public cfc: string;
  public price: number;
  public brand: string;
  public model: string;
  public provider: string;
  public hugCode: string;
  public comment: string;
  public configurations: any[] = [];
  public tva: Tva;
  public transferTva: Tva;
  public finance: string;
  public zones: string[] = [];
  public isTransfered: boolean;
  public isANewEquipment: boolean;
  public fromService: string;
  public fromUnit: string;
  public fromRoom: string;
  public inventoryNumber: string;
  public transferedPrice: number;
  public standalone: boolean;
  public reference: string;
  public inMarket: Date;
  public outMarket: Date;
  public cac: Boolean;

  static formBuilder(fb: FormBuilder, equipment: Equipment): FormGroup {
    return fb.group({
      label: [equipment.label, Validators.required],
      cneh: [{value: equipment.cneh, disabled: true}],
      cfc: [equipment.cfc],
      price: [equipment.price],
      brand: [equipment.brand],
      model: [equipment.model],
      provider: [equipment.provider],
      hugCode: [equipment.hugCode],
      comment: [equipment.comment],
      tva: [equipment.tva],
      transferTva: [equipment.transferTva],
      finance: [equipment.finance],
      zones: [equipment.zones],
      isTransfered: [equipment.isTransfered],
      isANewEquipment: [equipment.isANewEquipment],
      fromService: [equipment.fromService],
      fromUnit: [equipment.fromUnit],
      fromRoom: [equipment.fromRoom],
      inventoryNumber: [equipment.inventoryNumber],
      transferedPrice: [equipment.transferedPrice],
      inMarket: [equipment.inMarket],
      outMarket: [equipment.outMarket],
      cac: [equipment.cac],
      reference: [equipment.reference]
    });
  }

  static formBuilderLibrary(fb: FormBuilder, equipment: Equipment): FormGroup {
    return fb.group({
      label: [equipment.label, Validators.required],
      cneh: [{value: equipment.cneh, disabled: true}],
      cfc: [equipment.cfc],
      price: [equipment.price],
      brand: [equipment.brand],
      model: [equipment.model],
      provider: [equipment.provider],
      hugCode: [equipment.hugCode],
      comment: [equipment.comment],
      tva: [equipment.tva],
      inMarket: [equipment.inMarket],
      outMarket: [equipment.outMarket],
      cac: [equipment.cac],
      reference: [equipment.reference]
    });
  }

  static formBuilderCreate(fb: FormBuilder, equipment: Equipment): FormGroup {
    return fb.group({
      label: [equipment.label, Validators.required]
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class EquipmentAdapter implements Adapter<Equipment> {
  adapt(item: any): Equipment {
    const d = new Equipment();
    d._id = item._id;
    d.label = item.label;
    d.cneh = item.cneh;
    d.cfc = item.cfc;
    d.price = item.price;
    d.brand = item.brand;
    d.model = item.model;
    d.provider = item.provider;
    d.hugCode = item.hugCode;
    d.comment = item.comment;
    d.configurations = item.configurations;
    d.tva = item.tva;
    d.transferTva = item.transferTva;
    d.finance = item.finance;
    d.zones = item.zones;
    d.isTransfered = item.isTransfered;
    d.isANewEquipment = item.isANewEquipment;
    d.fromService = item.fromService;
    d.fromUnit = item.fromUnit;
    d.fromRoom = item.fromRoom;
    d.inventoryNumber = item.inventoryNumber;
    d.transferedPrice = item.transferedPrice;
    d.standalone = item.standalone;
    d.inMarket = item.inMarket;
    d.outMarket = item.outMarket;
    d.cac = item.cac;
    d.reference = item.reference;
    return d;
  }
}
