import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {EquipmentService} from '../../../../services/equipment.service';
import {SetService} from '../../../../services/set.service';
import {RoomService} from '../../../../services/room.service';
import {ApiService} from '../../../../services/api.service';
import {ImportService} from '../../../../services/import.service';
import {DialogEditLibRoomComponent} from '../../lib-room/dialog-edit-lib-room/dialog-edit-lib-room.component';
import {DialogEditSetComponent} from '../../set/dialog-edit-set/dialog-edit-set.component';
import {DialogEditEquipmentComponent} from '../../equipment/dialog-edit-equipment/dialog-edit-equipment.component';
import {ICloneObject} from '../../../atoms/dialog-clone/dialog-clone.component';
import {CloneService} from '../../../../services/clone.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-list-lib-import',
  templateUrl: './list-lib-import.component.html',
  styleUrls: ['./list-lib-import.component.scss']
})
export class ListLibImportComponent implements OnInit {

  data: any[] = [];

  filter: string = 'pending';

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns: string[] = ['type', 'name', 'comment', 'state', 'action'];
  @ViewChild('tablePaginator', {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private equipmentService: EquipmentService,
              private setService: SetService,
              private roomService: RoomService,
              private dialog: MatDialog,
              private apiService: ApiService,
              private clone: CloneService,
              private snackbar: MatSnackBar,
              private importService: ImportService) {
  }

  ngOnInit() {
    this.importService.getAll().subscribe(e => {
      this.data = e;
      this.refresh();
    })
  }

  refresh() {
    this.dataSource.data = [...this.data];
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.filter2();
  }

  filter2() {
    this.dataSource.filter = this.filter;
  }

  view(data) {
    if (data.json.object == 'Room') {
      this.roomService.get(data.json.id).subscribe(e => {
        this.dialog.open(DialogEditLibRoomComponent, {data: {room: e, write: false}, panelClass: 'dialog-full'});
      });
    }
    if (data.json.object == 'Set') {
      this.setService.get(data.json.id).subscribe(e => {
        this.dialog.open(DialogEditSetComponent, {
          data: {set: e, write: false, library: false},
          panelClass: 'dialog-full'
        });
      });
    }
    if (data.json.object == 'Equipment') {
      this.equipmentService.get(data.json.id).subscribe(e => {
        this.dialog.open(DialogEditEquipmentComponent, {
          data: {equipment: e, library: false, write: false},
          panelClass: 'dialog-full'
        });
      });
    }
  }

  done(data) {
    let clone: ICloneObject = {
      fromTarget: data.json.object,
      fromId: data.json.id,
      isFromLib: false,
      isToLib: true,
      forceStandalone: true
    };

    this.clone.clone(clone)
      .subscribe(e => {
        this.importService.put(data._id, 'accepted').subscribe((e) => {
          this.data.forEach((x, i) => {
            if (x._id == e['_id']) {
              this.data[i] = e;
            }
          });
          this.refresh();
          this.snackbar.open('Acceptation de l\'import de ' + data.json.name, null, {duration: 3000});
        })
      })
  }

  clear(data) {
    this.importService.put(data._id, 'refused').subscribe((e) => {
      this.data.forEach((x, i) => {
        if (x._id == e['_id']) {
          this.data[i] = e;
        }
      });
      this.refresh();
      this.snackbar.open('Refus de l\'import de ' + data.json.name, null, {duration: 3000});
    })
  }
}
