import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {TvaService} from "../../../../services/tva.service";
import {Tva} from "../../../../model/tva.model";

@Component({
  selector: 'app-dialog-tva',
  templateUrl: './dialog-tva.component.html',
  styleUrls: ['./dialog-tva.component.scss']
})
export class DialogTvaComponent {

  profileForm;

  constructor(private service: TvaService,
              private dialogRef: MatDialogRef<DialogTvaComponent>,
              private fb:FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.profileForm = Tva.formBuilder(this.fb, this.data.tva);
  }

  onSubmit(){
    if (this.data.action == 'modify') {
      this.service.put(this.data.tva._id, this.profileForm.value).subscribe((e) => {
        this.dialogRef.close(e);
      });
    }
    if (this.data.action == 'create') {
      this.service.post(this.profileForm.value).subscribe((e) => {
        this.dialogRef.close(e);
      });
    }
  }
}
