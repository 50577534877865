import {Component, Inject} from '@angular/core';
import {Tva} from '../../../../model/tva.model';
import {Finance} from '../../../../model/finance.model';
import {Cneh} from '../../../../model/cneh.model';
import {EquipmentService} from '../../../../services/equipment.service';
import {CnehService} from '../../../../services/cneh.service';
import {FinanceService} from '../../../../services/finance.service';
import {TvaService} from '../../../../services/tva.service';
import {FormBuilder} from '@angular/forms';
import {Equipment} from '../../../../model/equipment.model';
import {DialogImportComponent} from '../../../other/dialog-import/dialog-import.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogListCnehComponent} from '../../cneh/dialog-list-cneh/dialog-list-cneh.component';
import {Configuration} from '../../../../model/configuration.model';
import {DialogEditConfigurationComponent} from '../../configuration/dialog-edit-configuration/dialog-edit-configuration.component';
import {ConfigurationService} from '../../../../services/configuration.service';
import {Subject} from 'rxjs';
import {DialogSyncComponent} from '../../../other/dialog-sync/dialog-sync.component';
import {EFamily} from '../../../../model/enum/family.enum';
import {ApiService} from '../../../../services/api.service';

@Component({
  selector: 'app-dialog-edit-equipment',
  templateUrl: './dialog-edit-equipment.component.html',
  styleUrls: ['./dialog-edit-equipment.component.scss']
})
export class DialogEditEquipmentComponent {

  profileForm;
  edit = false;
  tvas: Tva[] = [];
  finances: Finance[] = [];
  cnehs: Cneh[] = [];
  cneh: Cneh = null;
  zones: any[] = [];
  priceTTC = '0';
  oldPriceTTC = '0';
  transferPriceTTC = '0';
  library = false;
  write: boolean = false;

  private hookConfig: Subject<string> = new Subject();
  public adminActions = ['select', 'delete', 'create', 'search'];
  public userActions = ['select', 'search'];

  constructor(private equipmentService: EquipmentService,
              private dialog: MatDialog,
              private cnehService: CnehService,
              private financeService: FinanceService,
              private tvaService: TvaService,
              private api: ApiService,
              private dialogRef: MatDialogRef<DialogEditEquipmentComponent>,
              private fb: FormBuilder,
              private configService: ConfigurationService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (!this.data) {
      this.dialogRef.close(null);
    }
    this.library = this.data.library;
    this.zones = this.data.zones;
    this.write = this.data.write;
    this.cneh = this.data.equipment.cneh;
    if (!this.data.library) {
      if (this.write) {
        this.zones = this.data.room.zones ? this.data.room.zones : []
        this.financeService.getAllByProject(this.data.project._id)
          .subscribe((e) => {
            this.finances = e;
          });
      } else {
        this.zones = [];
        this.finances = [];
      }
      this.profileForm = Equipment.formBuilder(this.fb, this.data.equipment);
    } else {
      this.profileForm = Equipment.formBuilderLibrary(this.fb, this.data.equipment);
    }
    this.edit = true;
    if (this.write) {
      this.tvaService
        .getAll()
        .subscribe((e) => {
          this.tvas = e;
        });
    } else {
      this.tvas = []
    }
    this.calculateTVA();
    this.calculateTransferTVA();
    this.calculateTotal();
  }

  sync() {
    if (!this.library) {
      this.dialog.open(DialogSyncComponent,
        {
          data: {
            targets: [this.data.equipment],
            family: EFamily.Equipment
          }
        })
        .afterClosed()
        .subscribe((result: any) => {
          if (result?.confirm) {
            this.api
              .bulkSync(result.option)
              .subscribe(() => {
                this.dialogRef.close('reload');
              })
          }
        });
    }
  }

  // =================================== SPECIFIC
  openCneh() {
    const dialogRef = this.dialog.open(
      DialogListCnehComponent,
      {
        panelClass: 'dialog-full-80'
      });
    dialogRef
      .afterClosed()
      .subscribe(cneh => {
        if (cneh) {
          this.cneh = cneh;
        }
      });
  }

  onSubmit() {
    this.calculateTotal()
    this.profileForm.value.cneh = this.cneh;
    this.dialogRef.close(this.profileForm.value);
  }

  calculateTVA() {
    if (this.profileForm.value.tva && this.profileForm.value.price) {
      this.oldPriceTTC = (this.profileForm.value.price * (1 + this.profileForm.value.tva.value / 100)).toFixed(2);
    } else {
      this.oldPriceTTC = '';
    }
    this.calculateTotal()
  }

  calculateTransferTVA() {
    if (this.profileForm.value.transferTva && this.profileForm.value.transferedPrice) {
      this.transferPriceTTC = (this.profileForm.value.transferedPrice * (1 + this.profileForm.value.transferTva.value / 100)).toFixed(2);
    } else {
      this.transferPriceTTC = '';
    }
    this.calculateTotal()
  }

  calculateTotal() {
    if (this.transferPriceTTC)
      this.priceTTC = this.transferPriceTTC;
    else if (this.profileForm.value.transferedPrice)
      this.priceTTC = this.profileForm.value.transferedPrice;
    else if (this.oldPriceTTC)
      this.priceTTC = this.oldPriceTTC;
    else
      this.priceTTC = this.profileForm.value.price
  }

  compareTva(a, b): boolean {
    return a && b && a._id == b._id
  }

  compareFinance(a, b): boolean {
    return a && b && a._id == b._id
  }

  // =================================== TRANSFERT
  importEQ() {
    this.dialog.open(DialogImportComponent, {
      data: {
        object: 'Equipment',
        id: this.data.equipment._id,
        projectId: this.data.project._id,
        versionId: this.data.version._id,
        roomId: this.data.room._id,
        setId: (this.data.set ? this.data.set._id : null),
        name: this.data.equipment.label
      }
    });
  }

  changeTransfert(type) {
    if (type == 'transfert' && this.profileForm.value.isTransfered) {
      this.profileForm.patchValue({isANewEquipment: false});
    }
    if (type == 'new' && this.profileForm.value.isANewEquipment) {
      this.profileForm.patchValue({isTransfered: false});
    }
  }

  // =================================== CONFIGS
  configSelected(config: Configuration) {
    this.dialog.open(DialogEditConfigurationComponent, {
      width: '80vw',
      height: '60vh',
      data: {
        configuration: config
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.configService.put(config._id, result, (this.library ? 'library/' : ''))
            .subscribe((e) => {
              this.hookConfig.next('refresh');
            });
        }
      });
  }

  createConfig() {
    this.dialog.open(DialogEditConfigurationComponent, {
      width: '80vw',
      height: '60vh',
      data: {
        target: 'Equipment'
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.configService
            .post('equipment/' + this.data.equipment._id, result, (this.library ? 'library/' : ''))
            .subscribe((e) => {
              this.hookConfig.next('refresh');
            });
        }
      });
  }
}
