<h2 mat-dialog-title>Importer {{'object.' + object | translate}} - {{name}}</h2>
<mat-dialog-content class="mat-typography" style="width:100%">
    <mat-form-field style="width:100%">
      <textarea placeholder="commentaire" matInput type="text" [(ngModel)]="comment"></textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Annuler</button>
  <button color="primary" mat-flat-button (click)="import()">Importer dans la bibliothèque</button>
</mat-dialog-actions>
