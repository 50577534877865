import {ChangeDetectionStrategy, Component, EventEmitter} from "@angular/core";
import {Room} from "../../../../model/room.model";
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: "app-dialog-list-room",
  templateUrl: "./dialog-list-room.component.html",
  styleUrls: ["./dialog-list-room.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogListRoomComponent {

  select: EventEmitter<Room>;

  constructor(public dialogRef: MatDialogRef<DialogListRoomComponent>) {
    this.select = new EventEmitter<Room>();
  }

  close(r:Room) {
    this.dialogRef.close(r);
  }
}

