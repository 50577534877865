import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthenticationService} from "../service/authentication.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {first, map} from "rxjs/operators";

const helper = new JwtHelperService();

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (AuthenticationService.isConnected) {
      return this.authenticationService.bearerise(request, next)
    } else {
      return next.handle(request);
    }
  }
}
