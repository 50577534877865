import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {

  @Input()
  formGroup: FormGroup;

  constructor() {
  }

  redDate() {
    if (this.formGroup && this.formGroup.controls && this.formGroup.controls.outMarket) {
      let d = new Date(this.formGroup.controls.outMarket.value);
      return d && d < new Date()
    }
    return false;
  }

}
