<div mat-dialog-title class="head">
  <h2>
    Local {{data.room.label}}
  </h2>
  <button mat-flat-button
          (click)="close()"
          color="primary">
    Fermer
  </button>
</div>
<app-edit-room (changeRoom)="close()"
               [write]="data.write"
               [library]="library"
               [room]="data.room">
</app-edit-room>
