<h2 mat-dialog-title *ngIf="title">
  {{title}}
</h2>
<mat-dialog-content class="mat-typography">
  <div *ngIf="text">
    {{text}}
  </div>
  <div class="verif" *ngIf="verif">
    <p class="red">
      Vous allez {{undo ? 'réouvrir' : 'clôturer'}} {{verif}}. Êtes-vous certain?
    </p>
    <p>
      Nous vous demandons de confirmer votre intention. Veuillez saisir
      <span>{{validationText}}</span> pour continuer ou
      fermer cette fenêtre pour annuler.
    </p>
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="verifInput">
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Annuler</button>
  <button *ngIf="verif"
          [disabled]="verifInput!=validationText"
          [class.forbidden]="verifInput!=validationText"
          color="warn"
          mat-flat-button [mat-dialog-close]="true">Clôturer
  </button>
  <button *ngIf="!verif"
          color="warn"
          mat-flat-button
          [mat-dialog-close]="true">
    Clôturer
  </button>
</mat-dialog-actions>

