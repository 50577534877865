<div class="card no-radius" (select)="noSelect($event)">
  <!-- HEADER -->
  <div class="card-header">
    <!-- LEFT -->
    <div class="left">
      <ng-container *ngIf="deletable || syncable">
        <ng-container *ngIf="!inSelection">
          <button mat-flat-button
                  (click)="toggleSelection()"
                  color="secondary">
            Selection
          </button>
        </ng-container>
        <ng-container *ngIf="inSelection">
          <button mat-flat-button
                  (click)="toggleSelection()"
                  color="primary">
            annuler
          </button>
          <button mat-flat-button
                  *ngIf="syncable"
                  [disabled]="!selection.selected.length"
                  (click)="bulkSync()"
                  color="accent">
            Synchroniser
          </button>
          <button mat-flat-button
                  [disabled]="!selection.selected.length"
                  (click)="bulkDelete()"
                  *ngIf="deletable"
                  color="warn">
            Supprimer
          </button>
        </ng-container>
      </ng-container>
    </div>
    <!-- RIGHT -->
    <div class="right">
      <!-- EXPORTING -->
      <button *ngIf="exportable"
              mat-flat-button
              (click)="export.emit(paginator.length)"
              color="accent">
        Exporter
      </button>
      <!-- ADDING -->
      <button *ngIf="creatable"
              mat-flat-button
              (click)="create.emit()"
              color="primary">
        Ajouter
      </button>
      <!-- IMPORT -->
      <button *ngIf="importable"
              [matMenuTriggerFor]="menu"
              mat-flat-button
              color="primary">
        Importer
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item
                (click)="import.emit(true)">
          Depuis la bibliothèque
        </button>
        <button mat-menu-item
                (click)="import.emit(false)">
          Depuis un projet
        </button>
        <input hidden
               type="file"
               accept=".xlsx"
               #fileInput
               (change)="uploadXlsx($event)"/>
        <button mat-menu-item
                *ngIf="csvImportable"
                (click)="fileInput.click()">
          Depuis un fichier Xlsx
        </button>
        <button mat-menu-item
                *ngIf="csvImportable"
                [style.fontSize]="'smaller'"
                (click)="downloadXlsx()">
          Télécharger le modèle (xlsx)
        </button>
      </mat-menu>
      <ng-container *ngIf="referenceFilterable">
        <mat-slide-toggle
          [style.margin]="'0 5px 0 0'"
          (change)="switchReference()">
          {{'action.referencedOnly' | translate}}
        </mat-slide-toggle>
      </ng-container>
      <ng-container *ngIf="queriables && queriables.length">
        <input class="filter" matInput
               #filter placeholder="Filtre"
               matTooltip="{{giveFilterInfo()}}"
               matTooltipPosition="above"
               *ngIf="searchable">
      </ng-container>
      <ng-container *ngIf="!queriables || !queriables.length">
        <input class="filter" matInput
               #filter placeholder="Filtre"
               *ngIf="searchable">
      </ng-container>
    </div>
  </div>

  <!--TABLE-->
  <div class="card-content no-padding">
    <table #table #tableSort="matSort" mat-table matSort [dataSource]="dataSource" class="small">

      <!-- IF SELECTION -->
      <ng-container *ngIf="inSelection">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef width="1%">
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? toggleSelect(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
      </ng-container>

      <!--FOR EACH FIELDS-->
      <ng-container *ngFor="let field of listOfFields" matColumnDef="{{field.key}}">
        <!--with sort header-->
        <ng-container *ngIf="field.searchable">
          <th mat-header-cell
              *matHeaderCellDef
              width="{{field.width}}%"
              mat-sort-header>
            {{field.i18Key | translate}}
          </th>
        </ng-container>

        <!--without-->
        <ng-container *ngIf="!field.searchable">
          <th mat-header-cell
              *matHeaderCellDef
              width="{{field.width}}%">
            {{field.i18Key | translate}}
          </th>
        </ng-container>

        <td mat-cell *matCellDef="let d; let index = index;"
            (click)="select.emit(originalDataSource[index])"
            [style.cursor]="selectable?'pointer':''">
          <ng-container [ngSwitch]="true">

            <!--CHIPS-->
            <mat-chip-list *ngSwitchCase="!!field.chips">
                <mat-chip class="small"
                          *ngFor="let chip of d[field.key]">
                  {{chip}}
                </mat-chip>
            </mat-chip-list>

            <!--ASYNC STRING-->
            <ng-container *ngSwitchCase="!!field.serviceFunctionName">
              {{d[field.key] | async | chf}}
            </ng-container>

            <!--DATE-->
            <ng-container *ngSwitchCase="!!field.isDate">
              {{d[field.key] | date:'dd.MM.yyyy'}}
            </ng-container>

            <!--CLASSICAL STRING-->
            <ng-container *ngSwitchDefault>
              {{d[field.key]}}
            </ng-container>

          </ng-container>
        </td>
      </ng-container>

      <!--TABLE DEFINITION-->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: false"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- PAGINATOR -->
    <mat-paginator #tablePaginator="matPaginator"
                   [pageSizeOptions]="[5, 10, 25, 50, 100]"
                   showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
