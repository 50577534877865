<ng-container>
  <!-- HEADER -->
  <h2 mat-dialog-title
      style="display: inline-block;">
    Équipement{{library ? ' standard' : ''}}: {{data.equipment.label}}</h2>
  <button *ngIf="write"
          mat-flat-button
          color="primary"
          style="margin-left: 10px;float: right;"
          (click)="onSubmit()">
    Sauvegarder
  </button>
  <button *ngIf="write && !library"
          (click)="importEQ()"
          style="margin-left: 10px;float:right"
          mat-stroked-button>
    Proposer dans la bibliothèque
  </button>
  <button *ngIf="write && !library"
          (click)="sync()"
          color="accent"
          style="float:right"
          mat-stroked-button>
    Synchroniser
  </button>


  <!-- CONTENT -->
  <div class="card">
    <div class="card-content no-padding">
      <mat-tab-group>

        <!-- PARAMETRE -->
        <mat-tab label="Paramètres équipement">
          <form [formGroup]="profileForm"
                [class.form-no]="!write">
            <div class="flex">
              <!-- PARAMETRE -->
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    Paramètres
                  </div>
                  <div class="card-content">
                    <div class="form">
                      <!--Label-->
                      <div>
                        <mat-form-field>
                          <input required
                                 matInput
                                 placeholder="{{'equipment.label' | translate}}"
                                 formControlName="label">
                        </mat-form-field>
                      </div>
                      <!--CODE-->
                      <div>
                        <mat-form-field>
                          <input matInput
                                 placeholder="{{'equipment.hugCode' | translate}}"
                                 formControlName="hugCode">
                        </mat-form-field>
                        <mat-form-field *ngIf="!library && data.set">
                          <mat-label>{{'equipment.zone' | translate}}</mat-label>
                          <mat-select multiple
                                      disabled
                                      [value]="data.set.zones">
                            <mat-option *ngFor="let zone of zones"
                                        value="{{zone.name}}">{{zone.name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="!library && !data.set">
                          <mat-label>{{'equipment.zone' | translate}}</mat-label>
                          <mat-select formControlName="zones"
                                      multiple>
                            <mat-option *ngFor="let zone of zones"
                                        value="{{zone.name}}">{{zone.name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <!--CNEH - CFC-->
                      <div>
                        <mat-form-field (click)="openCneh()"
                                        class="pointer">
                          <input matInput
                                 placeholder="{{'equipment.cneh' | translate}}"
                                 readonly="true"
                                 [value]="cneh?cneh.code2010:''">
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput
                                 placeholder="{{'equipment.cfc' | translate}}"
                                 formControlName="cfc">
                        </mat-form-field>
                      </div>
                      <!--PRICE HT - TTC-->
                      <div>
                        <mat-form-field>
                          <input matInput
                                 type="number"
                                 placeholder="{{'equipment.priceHT' | translate}}"
                                 formControlName="price"
                                 (ngModelChange)="calculateTVA()">
                          <span matSuffix>CHF HT</span>
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput
                                 placeholder="{{'equipment.priceTTC' | translate}}"
                                 disabled="true"
                                 [value]="oldPriceTTC">
                          <span matSuffix>CHF TTC</span>
                        </mat-form-field>
                      </div>
                      <!--TVA-->
                      <div>
                        <mat-form-field *ngIf="!library">
                          <mat-label>{{'equipment.finance' | translate}}</mat-label>
                          <mat-select formControlName="finance"
                                      [compareWith]="compareFinance">
                            <mat-option [value]="null">
                            </mat-option>
                            <mat-option *ngFor="let finance of finances"
                                        [value]="finance">{{finance.label}}
                              - {{finance.comment}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                          <mat-label>{{'equipment.tva' | translate}}</mat-label>
                          <mat-select formControlName="tva"
                                      [compareWith]="compareTva"
                                      (ngModelChange)="calculateTVA()">
                            <mat-option *ngFor="let tva of tvas"
                                        [value]="tva">{{tva.typeTva}}
                              - {{tva.value}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <!--BRAND-MODEL-PROVIDER-->
                      <div>
                        <mat-form-field>
                          <input matInput
                                 placeholder="{{'equipment.brand' | translate}}"
                                 formControlName="brand">
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput
                                 placeholder="{{'equipment.model' | translate}}"
                                 formControlName="model">
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput
                                 placeholder="{{'equipment.provider' | translate}}"
                                 formControlName="provider">
                        </mat-form-field>
                      </div>
                      <!--COMMENT-->
                      <div>
                        <mat-form-field>
                        <textarea matInput
                                  placeholder="{{'equipment.comment' | translate}}"
                                  formControlName="comment">

                        </textarea>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- FILES x2 + Maintenance-->
              <div class="col">
                <div>
                  <div class="card">
                    <div class="card-header">
                      Référence
                    </div>
                    <div class="card-content">
                      <div class="form"
                           [formGroup]="profileForm">
                        <div>
                          <mat-form-field>
                            <input matInput
                                   placeholder="{{'equipment.reference' | translate}}"
                                   formControlName="reference">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="margin-top:10px;">
                  <app-files [write]="write"
                             [viewer]="true"
                             [route]="(library? '/library/':'') + '/equipment/' + data.equipment._id + '/planFiles'"
                             [small]="false"
                             [marginTop]="0"
                             [title]="'Image/Plan'"></app-files>
                </div>
                <div style="margin-top:10px;">
                  <app-files [write]="write"
                             [route]="(library? '/library/':'') + '/equipment/' + data.equipment._id + '/docFiles'"
                             [small]="false"
                             [marginTop]="0"
                             [title]="'Offre et Documentation technique'">
                  </app-files>
                </div>
                <div style="margin-top:10px;">
                  <app-maintenance [formGroup]="profileForm">
                  </app-maintenance>
                </div>
              </div>
            </div>

            <!-- TRANSFERT -->
            <div class="flex"
                 *ngIf="!library">
              <div class="card">
                <div class="card-header">
                  Transfert
                </div>
                <div class="card-content">
                  <div class="form">
                    <div>
                      <mat-slide-toggle
                        formControlName="isANewEquipment"
                        (change)="changeTransfert('new')">
                        {{'equipment.isANewEquipment' | translate}}
                      </mat-slide-toggle>
                      <mat-slide-toggle
                        formControlName="isTransfered"
                        (change)="changeTransfert('transfert')">
                        {{'equipment.isTransfered' | translate}}
                      </mat-slide-toggle>
                    </div>
                    <div>
                      <mat-form-field>
                        <input matInput
                               placeholder="{{'equipment.inventoryNumber' | translate}}"
                               formControlName="inventoryNumber">
                      </mat-form-field>
                    </div>
                    <div *ngIf="profileForm.value.isTransfered">
                      <mat-form-field>
                        <input matInput
                               placeholder="{{'equipment.fromService' | translate}}"
                               formControlName="fromService">
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput
                               placeholder="{{'equipment.fromUnit' | translate}}"
                               formControlName="fromUnit">
                      </mat-form-field>
                    </div>
                    <div *ngIf="profileForm.value.isTransfered">
                      <mat-form-field>
                        <input matInput
                               placeholder="{{'equipment.fromRoom' | translate}}"
                               formControlName="fromRoom">
                      </mat-form-field>
                      <mat-form-field>
                        <mat-label>
                          {{'equipment.transferTva' | translate}}
                        </mat-label>
                        <mat-select formControlName="transferTva"
                                    [compareWith]="compareTva"
                                    (ngModelChange)="calculateTransferTVA()">
                          <mat-option [value]="null">
                          </mat-option>
                          <mat-option *ngFor="let tva of tvas"
                                      [value]="tva">
                            {{tva.typeTva}} - {{tva.value}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="profileForm.value.isTransfered">
                      <mat-form-field>
                        <input matInput
                               type="number"
                               placeholder="{{'equipment.transferedPrice' | translate}}"
                               formControlName="transferedPrice"
                               (ngModelChange)="calculateTransferTVA()">
                        <span matSuffix>CHF HT</span>
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput
                               placeholder="{{'equipment.transferTvaTTC' | translate}}"
                               disabled="true"
                               [value]="transferPriceTTC">
                        <span matSuffix>CHF TTC</span>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </mat-tab>

        <!-- CONFIGURATIONS -->
        <mat-tab label="Contraintes">
          <ng-template matTabContent>
            <app-list-configuration
              [actions]="write? adminActions: userActions"
              [hookEvents]="hookConfig"
              [isLibrary]="library"
              [customPath]="(library? 'library/':'') + 'equipment/' + data.equipment._id + '/configurations'"
              (select)="configSelected($event)"
              (create)="createConfig()">
            </app-list-configuration>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</ng-container>
