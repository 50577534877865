import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiService} from "./api.service";
import {ICloneObject} from "../components/atoms/dialog-clone/dialog-clone.component";

@Injectable({
  providedIn: "root"
})
export class CloneService {

  constructor(private api: ApiService) {

  }

  clone(data: ICloneObject): Observable<any> {
    return this.api.post("clone", data)
  }

}
