import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map, mergeMap, tap} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Room, RoomAdapter} from '../model/room.model';
import {Version} from '../model/version.model';
import {ListGetterService} from './generic/list-getter.service';
import {Set} from '../model/set.model';

@Injectable({
  providedIn: 'root'
})
export class RoomService extends ListGetterService<Room> implements Resolve<Room[]> {

  constructor(protected api: ApiService,
              private adapter: RoomAdapter) {
    super('room/', api)
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Room[]> | Promise<Room[]> | Room[] {
    return null;
  }

  getAll(prefix: string = '', params = {}): Observable<any> {
    return this.api.getWithHeader(prefix + 'room', params).pipe(
      map(resp => {
        resp.data.map(item => {
          return this.adapter.adapt(item);
        });
        return resp;
      })
    );
  }

  getAllUnique(versionId = '', params = {}): Observable<any> {
    return this.api.getWithHeader('pretty/nodupr' + versionId, params).pipe(
      map(resp => {
        resp.data.rooms.map(item => {
          return this.adapter.adapt(item);
        });
        return {data: resp.data.rooms, total: resp.total};
      })
    );
  }

  getCustom(room: Room): Observable<any> {
    return this.api.get('pretty/pepefer/' + room._id).pipe(
      map(data => {
        return data;
      })
    );
  }

  getLibraryCustom(room: Room): Observable<any> {
    return this.api.get('pretty/pepefelr/' + room._id).pipe(
      map(data => {
        return data;
      })
    );
  }

  getAllByVersion(version: Version): Observable<Room[]> {
    return this.api.get('version/' + version._id + '/rooms').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Room> {
    return this.api.get('room/' + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  getLibrary(id: string): Observable<Room> {
    return this.api.get('library/room/' + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id: string, room: Room): Observable<Room> {
    return this.api.put('room/' + id, room, {successSnackBar: true, errorSnackBar: true, title: room.label}).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  putLibrary(id: string, room: Room): Observable<Room> {
    return this.api.put('library/room/' + id, room, {
      successSnackBar: true,
      errorSnackBar: true,
      title: room.label
    }).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  postInVersion(version: Version, room: Room): Observable<Room> {
    return this.api.post('version/' + version._id + '/rooms', room).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  clone(data): Observable<Room> {
    return this.api.post('clone', data).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  postLibrary(room: Room): Observable<Room> {
    return this.api.post('library/room', room).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options): Observable<Room> {
    return this.api.delete('room/' + id, options);
  }

  deleteLibrary(id: string, options: Options): Observable<Room> {
    return this.api.delete('library/room/' + id, options);
  }

  patch(id: string, data: any, prefix: string = ''): Observable<Room> {
    return this.api.patch(prefix + 'room/' + id, data, {errorSnackBar: true, successSnackBar: true})
  }

  uploadXlsx(file, version: Version): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('import', file, file.name);
    return this.api.upload('room/import', formData, {versionId: version._id})
  }

  downloadXlsx() {
    this.api.download('room/model')
  }
}
