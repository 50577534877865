import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WithId} from './interface/withId.interface';

export class Speciality implements WithId {
  public _id;
  public title;
  public description;

  static formBuilder(fb:FormBuilder, speciality:Speciality):FormGroup{
    return fb.group({
      title: [speciality.title, Validators.required],
      description: [speciality.description],
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class SpecialityAdapter implements Adapter<Speciality> {
  adapt(item: any): Speciality {
    const d = new Speciality();
    d._id = item._id;
    d.title = item.title;
    d.description = item.description;
    return d;
  }
}
