import {Component, EventEmitter, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Equipment} from '../../../../model/equipment.model';

interface iDialogListEquipmentData {
  withProjectSelection: boolean;
}

@Component({
  selector: 'app-dialog-list-equipment',
  templateUrl: './dialog-list-equipment.component.html',
  styleUrls: ['./dialog-list-equipment.component.scss']
})
export class DialogListEquipmentComponent {

  select: EventEmitter<Equipment>;
  withProject: boolean;

  constructor(public dialogRef: MatDialogRef<DialogListEquipmentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: iDialogListEquipmentData) {
    this.select = new EventEmitter<Equipment>();
    this.withProject = data?.withProjectSelection;
  }

  close(e: Equipment) {
    this.dialogRef.close(e);
  }

}
