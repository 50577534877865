import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloneService} from '../../../services/clone.service';
import {forkJoin} from 'rxjs';

export interface ICloneObject {
  fromTarget: string;
  toTarget?: string;
  toId?: string;
  fromId: string;
  isFromLib: boolean;
  isToLib: boolean;
  quantity?: number;
  forceStandalone?: boolean;
}

@Component({
  selector: 'app-dialog-clone',
  templateUrl: './dialog-clone.component.html',
  styleUrls: ['./dialog-clone.component.scss']
})
export class DialogCloneComponent<T> implements AfterViewInit {
  title;
  placeholder;
  value: number = 1;
  cloneObj: ICloneObject;

  @ViewChild('input')
  input: ElementRef<HTMLInputElement>;

  constructor(private dialogRef: MatDialogRef<DialogCloneComponent<T>>,
              private service: CloneService,
              @Inject(MAT_DIALOG_DATA) public data: { i18nTitleKey: string, cloneObj: ICloneObject }) {
    if (!data) {
      this.dialogRef.close(null)
    } else {
      this.title = data.i18nTitleKey || 'all.noTitle';
      this.cloneObj = data.cloneObj;
    }
  }

  ngAfterViewInit() {
    setTimeout(()=> {
      this.input.nativeElement.select();
    }, 1)
  }

  clone() {
    if (this.value && this.value !== 1) {
      const t = [];
      for (let i = 0; i < this.value; i++) {
        t.push(this.service.clone(this.cloneObj));
      }
      ;
      forkJoin(t)
        .subscribe(() => {
          this.dialogRef.close('multiple');
        });
    } else if (this.value) {
      return this.service.clone(this.cloneObj)
        .subscribe(
          (res) => {
            this.dialogRef.close(res);
          })
    } else {
      this.dialogRef.close(null);
    }
  }

  inputKeyDown($event: KeyboardEvent) {
    if ($event && $event.code === 'Enter') {
      this.clone();
    }
  }

}
