import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Subject} from "rxjs";
import {Constraint} from "../../../../model/constraint.model";
import {Speciality} from "../../../../model/speciality.model";

@Component({
  selector: "app-list-constraint-and-speciality",
  templateUrl: "./list-constraint-and-speciality.component.html",
  styleUrls: ["./list-constraint-and-speciality.component.scss"]
})
export class ListConstraintAndSpecialityComponent implements OnInit {

  @Input()
  hookEvents: Subject<string>;

  @Input()
  actions: String[];

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Constraint> = new EventEmitter();

  @Output()
  delete: EventEmitter<Constraint> = new EventEmitter();

  @Output()
  create: EventEmitter<Constraint> = new EventEmitter();

  @Output()
  selectSpeciality: EventEmitter<Speciality> = new EventEmitter();

  @Output()
  deleteSpeciality: EventEmitter<Speciality> = new EventEmitter();

  @Output()
  createSpeciality: EventEmitter<Speciality> = new EventEmitter();

  hookConstraint: Subject<string>;
  hookSpeciality: Subject<string>;

  constructor() {
  }

  ngOnInit() {
    this.hookSpeciality = new Subject();
    this.hookConstraint = new Subject();
    this.hookEvents.subscribe((str: string) => {
      if (str === "refresh-constraint") {
        this.hookConstraint.next("refresh");
      }
      if (str === "refresh-speciality") {
        this.hookSpeciality.next("refresh");
      }
    })
  }

}
