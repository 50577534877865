import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WithId} from './interface/withId.interface';

export class Finance implements WithId {
  public _id;
  public label;
  public comment;

  static formBuilder(fb:FormBuilder, finance:Finance):FormGroup{
    return fb.group({
      label: [finance.label, Validators.required],
      comment: [finance.comment],
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class FinanceAdapter implements Adapter<Finance> {
  adapt(item: any): Finance {
    const d = new Finance();
    d._id = item._id;
    d.label = item.label;
    d.comment = item.comment;
    return d;
  }
}
