<h2 mat-dialog-title *ngIf="title">{{title}}</h2>
<mat-dialog-content class="mat-typography">
  <div *ngIf="text">
    {{text}}
  </div>
  <div class="verif" *ngIf="verif">
    <p class="red">
      Vous allez supprimer définitivement {{verif}}. Êtes-vous absolument certain?
    </p>
    <p>
      Cette action peut entraîner une perte de données.
      Pour éviter des actions accidentelles, nous vous demandons de confirmer votre intention.
      Veuillez saisir <span>SUPPRIMER</span> pour continuer ou fermer cette fenêtre pour annuler.
    </p>
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="verifInput">
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Annuler</button>
  <button *ngIf="verif" [disabled]="verifInput!='SUPPRIMER'" [class.forbidden]="verifInput!='SUPPRIMER'" color="warn"
          mat-flat-button [mat-dialog-close]="true">
    Effacer définitivement
  </button>
  <button *ngIf="!verif" color="warn" mat-flat-button [mat-dialog-close]="true">
    Effacer définitivement</button>
</mat-dialog-actions>
