import {Injectable} from "@angular/core";
import {HttpBackend, HttpClient, HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {of, BehaviorSubject, Observable, Subject, throwError, concat} from "rxjs";
import {catchError, first, flatMap, map, share, tap} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Token} from "@angular/compiler";

interface Tokens {
  accessToken: string;
  refreshToken: string;
}

const helper = new JwtHelperService();

@Injectable({providedIn: "root"})
export class AuthenticationService {
  private httpHidden: HttpClient;
  private currentlyRefreshingToken: Observable<any>;

  constructor(handler: HttpBackend, private router: Router) {
    this.httpHidden = new HttpClient(handler);
  }

  public static get isConnected(): boolean {
    return !!(localStorage.getItem("accessToken"))
  }

  login(login: string, password: string) {
    return this.httpHidden.post<any>(`${environment.url}auth/login`, {login, password})
      .pipe(map((tokens: Tokens) => {
        localStorage.setItem("refreshToken", tokens.refreshToken);
        localStorage.setItem("accessToken", tokens.accessToken);
        return tokens;
      }));
  }

  static attachTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
  }

  // manage accestoken and refreshing for request
  bearerise(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // send request after refresh
    const refreshFirst = () => {
      return this.currentlyRefreshingToken
        .pipe(flatMap(() => next.handle(AuthenticationService.attachTokenToRequest(request))))
    };

    if (this.currentlyRefreshingToken) {
      return refreshFirst()
    } else if (helper.isTokenExpired(localStorage.getItem("accessToken"))) {
      // fresh request in observable
      this.currentlyRefreshingToken = this.httpHidden
        .post<any>(`${environment.url}auth/refresh`, {
          refreshToken: localStorage.getItem("refreshToken"),
          accessToken: localStorage.getItem("accessToken")
        })
        .pipe(map((tokens: Tokens) => {
            localStorage.setItem("refreshToken", tokens.refreshToken);
            localStorage.setItem("accessToken", tokens.accessToken);
            this.currentlyRefreshingToken = null;
          }), catchError(error => {
            console.log("Refresh token is too old. Logging out..");
            this.logout();
            return throwError(error)
          })
        ).pipe(share());
      return refreshFirst()
    } else {
      return next.handle(AuthenticationService.attachTokenToRequest(request))
    }
  }

  logout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    this.currentlyRefreshingToken = null;
    this.router.navigate(["/auth/login"]);
  }
}
