import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WithId} from './interface/withId.interface';

export class Configuration implements WithId {
  public _id;
  public constraintLabel;
  public constraintSpecialityLabel;
  public isPhysic;
  public values = {};
  public comments;
  public quantity;

  static formBuilder(fb: FormBuilder, configuration: Configuration): FormGroup {
    return fb.group({
      constraintLabel: [{value: configuration.constraintLabel, disabled:true}],
      constraintSpecialityLabel: [{value: configuration.constraintSpecialityLabel, disabled:true}],
      isPhysic: [{value: configuration.isPhysic, disabled:true}],
      comments: [configuration.comments],
      quantity: [configuration.quantity],
    });
  }

  static FormBuilderValues(fb:FormBuilder, values:any):FormGroup {
    let a = {};
    for (let b in values){
      a[b] = [values[b]];
    }
    return fb.group(a);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationAdapter implements Adapter<Configuration> {
  adapt(item: any): Configuration {
    const d = new Configuration();
    d._id = item._id;
    d.constraintLabel = item.constraintLabel;
    d.constraintSpecialityLabel = item.constraintSpecialityLabel;
    d.isPhysic = item.isPhysic;
    d.values = item.values;
    d.comments = item.comments;
    d.quantity = item.quantity;
    return d;
  }
}
