import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "chf"})
export class ChfPipe implements PipeTransform {
  transform(value): any {
    if (!value || value === "") {
      return ""
    }
    if (typeof value === "number") {
      value = value.toString();
    }
    let cuted = value.split(".");
    let decimal = cuted[1] ? "." + (cuted[1].length === 1 ? cuted[1] : cuted[1].substring(0, 2)) : "";
    let full = "";
    let end = ""; // .-";
    let l = cuted[0].length;
    for (let i = l - 1; i >= 0; i--) {
      if (!((l - i - 1) % 3)) {
        full = "," + full
      }
      full = cuted[0][i] + full
    }
    full = full.substring(0, full.length - 1)
    return full + decimal + end;
  }
}
