import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {merge, Observable, of, pipe, Subscription} from 'rxjs';
import {delay, mapTo, mergeMap, tap, timeout} from 'rxjs/operators';

@Component({
  selector: 'app-price',
  template: "<span *ngIf='price!==null'>{{price}}<span>{{suffix}}</span></span>"
})
export class PriceComponent implements OnInit, OnDestroy{

  @Input() object:string;
  @Input() id:string;
  @Input() library:boolean = false;
  @Input() suffix:string = '';
  @Input() delay:number = 0;

  price = null;

  sub:Subscription = new Subscription();

  constructor(private api:ApiService) { }

  ngOnInit() {
    this.sub = new Observable((observer) => {
      let id = setTimeout(() => {
        this.api.get('price/' + (this.library?'library/':'') + this.object + '/' + this.id).subscribe(e => {
          this.price = e.toFixed(2);
        })
      }, Math.floor(Math.random() * this.delay));

      return () => {
        clearTimeout(id);
      };
    }).subscribe();

    // this.sub = this.api.get('price/' + (this.library?'library/':'') + this.object + '/' + this.id).subscribe(e => {
    //   this.price = e.toFixed(2);
    // })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
