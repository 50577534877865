<h2 *ngIf="data.action == 'modify'" mat-dialog-title>Modifier {{data.job.title}}</h2>
<h2 *ngIf="data.action == 'create'" mat-dialog-title>Créer une nouvelle fonction</h2>
<form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="mat-typography form">
    <mat-form-field>
      <input matInput placeholder="{{'job.title' | translate}}" formControlName="title">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'job.description' | translate}}" formControlName="description">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Annuler</button>
    <button *ngIf="data.action == 'create'" type="submit" color="primary" mat-flat-button >Créer</button>
    <button *ngIf="data.action == 'modify'" type="submit" color="primary" mat-flat-button >Modifier</button>
  </mat-dialog-actions>
</form>
