import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FileService} from '../../../services/file.service';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {F} from '../../../model/file.model';
import {DialogDeleteComponent} from '../dialog-delete/dialog-delete.component';
import {DialogFileViewerComponent} from '../../other/dialog/dialog-file-viewer/dialog-file-viewer.component';
import {debounceTime, delay, mergeMap, tap} from 'rxjs/operators';
import {ApiService} from '../../../services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit, OnChanges {

  @Input() marginTop: number = 0;
  @Input() route: string;
  @Input() title: string = 'Pièces-jointes';
  @Input() viewer: boolean = false;
  @Input() write: boolean = true;
  @Input() small: boolean = false;
  loading: boolean = true;

  select: String = null;

  previewRef = null;

  displayedColumns = ['name', 'createdAt', 'action'];
  displayedColumnsSmall = ['name', 'action'];
  dataSource: MatTableDataSource<F> = new MatTableDataSource([]);
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  files: F[] = [];
  stat = {total: 0, finish: 0};

  imagePreviewUrl: Record<string, Observable<string>> = {};

  public uploadFiles: NgxFileDropEntry[] = [];

  constructor(private apiService: ApiService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private fileService: FileService) {
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
  }

  giveUrl(file: F){
    if (!this.imagePreviewUrl[file._id]) {
      this.imagePreviewUrl[file._id] = this.apiService
        .openUrlWithTempToken("file/" + file._id)
    } else {
      this.imagePreviewUrl[file._id]
        .pipe(
          debounceTime(5000),
          tap(() => 'launching new request'),
          mergeMap(() => {
            return this.imagePreviewUrl[file._id] = this.apiService
              .openUrlWithTempToken("file/" + file._id)
          })
        )
    }
    return this.imagePreviewUrl[file._id];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading = true;
    this.fileService.getFiles(this.route)
      .pipe(delay(0))
      .subscribe((e) => {
        this.files = e;
        this.loading = false;
        if (this.viewer && this.files.length > 0) {
          this.files.forEach(e => {
            if (e.isImage && !this.select)
              this.selected(e);
          })
        }
        this.refresh();
      });
  }

  selected(a: F) {
    // if (a.isImage)
    //   this.select = this.fileService.getUrl(a);
    // else
    //   this.select = null;
  }

  refresh() {
    this.dataSource.data = [...this.files];
  }

  public dropped(event: any) {
    this.stat.total = 0;
    this.stat.finish = 0;
    for (const droppedFile of event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.stat.total++;
          const formData: FormData = new FormData();
          formData.append('file', file, droppedFile.relativePath);

          this.fileService.upload(formData, this.route)
            .subscribe((e) => {
              this.files.push(e);
              this.refresh();
              this.stat.finish++;
              if (this.viewer && !this.select && e.isImage)
                this.selected(e);
            });

        });
      } else {
        this.snackBar.open('Impossible d\'uploader un dossier', null, {duration: 3000});
      }
    }
  }

  view(file: F) {
    if (!this.previewRef) {
      this.previewRef = this.dialog.open(DialogFileViewerComponent, {panelClass: 'dialog-medium', data: file});
    }
  }

  closePreview() {
    this.previewRef = null;
  }

  download(file: F) {
    this.apiService.download('file/' + file._id);
  }

  delete(file: F) {
    this.dialog.open(DialogDeleteComponent, {})
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.fileService
            .delete(this.route, file._id)
            .subscribe((e) => {
              this.files = this.files.filter(x => {
                return x._id != file._id;
              });
              this.refresh();
            });
        }
      });
  }
}
