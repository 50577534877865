import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector, Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Project} from '../../../../model/project.model';
import {IFieldConfig, ListerComponent} from '../../../generics/lister/lister.component';
import {DynamicHostDirective} from '../../../../directives/dynamic-host.directive';
import {ListGetterService} from '../../../../services/generic/list-getter.service';
import {VersionService} from '../../../../services/version.service';
import {Version} from '../../../../model/version.model';

@Component({
  selector: 'app-list-version',
  templateUrl: './list-version.component.html',
  styleUrls: ['./list-version.component.scss']
})
export class ListVersionComponent implements OnInit {

  @Input()
  customPath: string;


  @Output()
  select: EventEmitter<Version> = new EventEmitter();

  component: any;
  fields: IFieldConfig[] = [
    {
      key: 'label',
      i18Key: 'project.version',
      width: 50,
      searchable: true
    },
    {
      key: 'Description',
      i18Key: 'project.description',
      width: 50,
    },
  ];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private versionService: VersionService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef
      .createComponent(factory, undefined, Injector.create({
        providers: [
          {
            provide: ListGetterService,
            useValue: this.versionService
          },
          {
            provide: 'FIELD_CONFIG',
            useValue: this.fields
          }
        ],
        parent: this.injector
      }));
    ref.instance.actions = ['select'];
    ref.instance.select = this.select;
    ref.instance.customPath = this.customPath;
  }
}
