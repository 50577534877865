import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {AuthenticationGuard} from "./modules/authentication/guard/authentication.guard";
import {HomeComponent} from "./modules/sibview/home.component";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./modules/authentication/authentication.module").then(mod => mod.AuthenticationModule),
  },
  {
    path: "",
    loadChildren: () => import("./modules/sibview/home.module").then(m => m.HomeModule),
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
