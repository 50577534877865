import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private api:ApiService) {

  }

  search(query): Observable<any> {
    return this.api.get('search/global', {query: query}).pipe();
  }

  obj(object:string, id:string): Observable<any> {
    return this.api.get('search/path/' + object + '/' + id).pipe();
  }
}
