import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  constructor(private snackBar:MatSnackBar, private apiService:ApiService) {

  }

  getAll(): Observable<any[]>{
    return this.apiService.get('transfer').pipe(
      map(data => {
        return data;
      })
    );
  }

  put(id:string, state): Observable<any[]>{
    return this.apiService.put('transfer/' + id, {state: state}).pipe(
      map(data => {
        return data;
      })
    );
  }

  post(comment:string, object:string, id:string, projectId:string, versionId:string, roomId:string, setId:string, name:string): Observable<any[]>{
    let data = {comment:comment, state: 'pending', json: {name:name, object: object, id:id, projectId: projectId, versionId: versionId, roomId:roomId, setId:setId}};
    return this.apiService.post('transfer', data).pipe(
      map(data => {
        return data;
      }),
      catchError((err) => {
        this.snackBar.open('L\'élément est déjà présent dans la bibliothèque', null, {duration: 2000});
        return throwError(err);
      })
    );
  }
}
