import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-edit-lib-room',
  templateUrl: './dialog-edit-lib-room.component.html',
  styleUrls: ['./dialog-edit-lib-room.component.scss']
})
export class DialogEditLibRoomComponent implements OnInit {

  library = true;

  constructor(private dialogRef: MatDialogRef<DialogEditLibRoomComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data.library === false)
      this.library = false;
  }

  close(){
    this.dialogRef.close();
  }
}
