import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Tva, TvaAdapter} from '../model/tva.model';
import {ListGetterService} from "./generic/list-getter.service";

@Injectable({
  providedIn: 'root'
})
export class TvaService extends ListGetterService<Tva> implements Resolve<Tva[]> {

  constructor(protected api: ApiService, private adapter: TvaAdapter) {
    super('tva', api)
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tva[]> | Promise<Tva[]> | Tva[] {
    return this.getAll();
  }

  getAll(): Observable<Tva[]> {
    return this.api.get('tva').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Tva> {
    return this.api.get('tva/' + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id:string, tva:Tva): Observable<Tva> {
    return this.api.put('tva/' + id, tva).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(tva:Tva): Observable<Tva> {
    return this.api.post('tva', tva).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  deleteInEquipment(equipmentId, options: Options, prefix:string = ''): Observable<Tva> {
    return this.api.delete(prefix + 'equipment/' + equipmentId + '/tva', options);
  }

  deleteInEquipmentTransfer(equipmentId, options: Options, prefix:string = ''): Observable<Tva> {
    return this.api.delete(prefix + 'equipment/' + equipmentId + '/transferTva', options);
  }

  delete(id: string, options: Options): Observable<Tva> {
    return this.api.delete('tva/' + id, options);
  }
}
