<h2 mat-dialog-title>
  Sélectionner un équipment provenant d'un projet.
</h2>
<ng-container *ngIf="withProject else otherwise">
  <mat-tab-group [selectedIndex]="0">
    <mat-tab label="Tous les équipements">
      <app-list-equipment
        [actions]="['select', 'search', 'referenceFilterable']"
        (select)="close($event)">
      </app-list-equipment>
    </mat-tab>
    <mat-tab label="Arborescance">
      <ng-template matTabContent>
        <app-select-equipment-stepper (equipmentSelected)="close($event)">
        </app-select-equipment-stepper>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-container>
<ng-template #otherwise>
  <app-list-equipment
    [actions]="['select', 'search', 'referenceFilterable']"
    (select)="close($event)">
  </app-list-equipment>
</ng-template>
