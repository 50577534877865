import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {Set, SetAdapter} from '../model/set.model';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Room} from '../model/room.model';
import {Equipment} from '../model/equipment.model';
import {Version} from '../model/version.model';
import {ListGetterService} from "./generic/list-getter.service";

@Injectable({
  providedIn: 'root'
})
export class SetService extends ListGetterService<Set> implements Resolve<Set> {

  constructor(protected api: ApiService, private adapter: SetAdapter) {
    super('set/', api)
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Set> | Promise<Set> | Set {
    return null;
  }

  clone(data): Observable<Set> {
    return this.api.post('clone', data).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  getAllUnique(versionId = '', params = {}): Observable<any> {
    return this.api.getWithHeader('pretty/nodups' + versionId, params).pipe(
      map(resp => {
        resp.data.sets.map(item => {
          return this.adapter.adapt(item);
        });
        return {data: resp.data.sets, total: resp.total};
      })
    );
  }

  getAllByRoom(room: Room, prefix: string = ''): Observable<Set[]> {
    return this.api.get(prefix + 'room/' + room._id + '/sets?populate=cneh,equipments').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  getAllInVersion(version:Version): Observable<Set[]> {
    return this.api.get('version/' + version._id + '/sets').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  getAllInLibrary(): Observable<Set[]> {
    return this.api.get('library/set').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  getAll(prefix:string = '', params = {}): Observable<any> {
    return this.api.getWithHeader(prefix + 'set', params).pipe(
      map(resp => {
        resp.data.map(item => {
          return this.adapter.adapt(item);
        });
        return resp;
      })
    );
  }

  LibraryGetAll(): Observable<Set[]> {
    return this.api.get('library/set?populate=cneh,equipments').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Set> {
    return this.api.get('set/' + id + '?populate=cneh,equipments,prestations').pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  getInBoth(id: string, isLibrary): Observable<Set> {
    return this.api.get((isLibrary ? "library/" : "") + 'set/' + id + '?populate=cneh,equipments,prestations').pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  getInLibrary(id: string): Observable<Set> {
    return this.api.get('library/set/' + id + '?populate=cneh,equipments,prestations').pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id: string, set: Set, prefix:string = ''): Observable<Set> {
    return this.api.put(prefix + 'set/' + id + '?populate=cneh,equipments', set).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  putInLibrary(id: string, set: Set): Observable<Set> {
    return this.api.put('library/set/' + id + '?populate=cneh,equipments', set).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  putInBoth(id: string, set: Set, isLibrary: boolean): Observable<Set> {
    return this.api.put((isLibrary? "library/":"") + 'set/' + id + '?populate=cneh,equipments', set).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  postInRoom(room: Room, set: Set, library: boolean): Observable<Set> {
    let prefix = "";
    if (library) {
      prefix = "library/";
      set.standalone = false;
    }
    return this.api.post(prefix + 'room/' + room._id + '/sets', set).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  postInLibrary(set: Set): Observable<Set> {
    return this.api.post('library/set', set).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options): Observable<Set> {
    return this.api.delete('set/' + id, options);
  }

  deleteInLibrary(id: string, options: Options): Observable<Set> {
    return this.api.delete('library/set/' + id, options);
  }
}
