import {Component, forwardRef, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ISyncOption} from '../../../model/interface/syncOption.interface';
import {EFamily} from '../../../model/enum/family.enum';
import {IFamily} from '../../../model/interface/ifamily.interface';
import {DialogListLibRoomComponent} from '../../entities/lib-room/dialog-list-lib-room/dialog-list-lib-room.component';
import {DialogListRoomComponent} from '../../entities/room/dialog-list-room/dialog-list-room.component';
import {DialogListEquipmentComponent} from '../../entities/equipment/dialog-list-equipment/dialog-list-equipment.component';
import {DialogListLibEquipmentComponent} from '../../entities/lib-equipments/dialog-list-lib-equipment/dialog-list-lib-equipment.component';
import {DialogListSetComponent} from '../../entities/set/dialog-list-set/dialog-list-set.component';
import {DialogListLibSetComponent} from '../../entities/lib-sets/dialog-list-lib-set/dialog-list-lib-set.component';
import {ListerComponent} from '../../generics/lister/lister.component';

const addPrefix = (prefix, object) => {
  const newO = {};
  Object.keys(object).forEach(key => {
    newO[prefix+key] = object[key];
  });
  return newO
};

const equipmentOptions = {
  'ED': {
    title: 'Synchroniser les données des équipements.',
  },
  'EC': {
    title: 'Synchroniser les configurations des équipements.'
  }
};

const setOptions = {
  'SD': {
    title: 'Synchroniser les données des ensembles fonctionnels.'
  },
  'SC': {
    title: 'Synchroniser les configurations des ensembles fonctionnels.'
  },
  'SP': {
    title: 'Synchroniser les prestations des ensembles fonctionnels.'
  },
  'SE': {
    title: 'Synchroniser les équipements des ensembles fonctionnels.',
    children: addPrefix('S', equipmentOptions)
  }
};

const roomOptions = {
  'RD': {
    title: 'Synchroniser les données des locaux.'
  },
  'RC': {
    title: 'Synchroniser les configurations des locaux.'
  },
  'RS': {
    title: 'Synchroniser les ensembles fonctionnels des locaux.',
    children: addPrefix('R', setOptions)
  },
  'RE': {
    title: 'Synchroniser les équipements des locaux.',
    children: addPrefix('R', equipmentOptions)
  }
};

interface IChoice {
  code: string;
  name: string;
  completed?: boolean;
  subChoices?: IChoice[];
}

@Component({
  selector: 'app-dialog-sync',
  templateUrl: './dialog-sync.component.html',
  styleUrls: ['./dialog-sync.component.scss']
})
export class DialogSyncComponent {

  private options: ISyncOption;
  private family: EFamily;
  private fromLib: boolean;
  private possibleOptions: any;

  source: any;
  targets: IFamily[];
  title: string;
  choices: IChoice[];
  public findFunction;

  constructor(private self: MatDialogRef<DialogSyncComponent>,
              private dia: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.family = data.family;
    this.options = {
      family: data.family,
      source: null,
      fromLib: false,
      targets: data.targets,
      codes: []
    };
    switch (this.family) {
      case EFamily.Room:
        this.possibleOptions = roomOptions;
        this.findFunction = this.findRoom;
        break;
      case EFamily.Equipment:
        this.possibleOptions = equipmentOptions;
        this.findFunction = this.findEquipment;
        break;
      case EFamily.Set:
        this.possibleOptions = setOptions;
        this.findFunction = this.findSet;
        break;
    }
    this._createChoices();
    this.targets = data.targets;
    this.title = `Synchronisation de ${this.targets.length} élément${this.targets.length > 1 ? 's.' : '.'}`;
  }

  private _createChoices() {
    const objectToChoice = (object) => {
      const choices = [];
      Object.keys(object).forEach(key => {
        let subChoices = [];
        if (object[key].children) {
          subChoices = objectToChoice(object[key].children)
        }
        choices.push({
          name: object[key].title,
          code: key,
          subChoices: subChoices
        });
      });
      return choices;
    };
    this.choices = [];
    this.choices = objectToChoice(this.possibleOptions);
  }

  private _getSelectedChoiceCode():string[] {
    const codes = [];
    this.choices.forEach(c => {
      if (c.completed) {
        codes.push(c.code)
      }
      if (c.subChoices) {
        c.subChoices.forEach(sc => {
          if (sc.completed) {
            codes.push(sc.code)
          }
          if (sc.subChoices) {
            sc.subChoices.forEach(sc => {
              if (sc.completed) {
                codes.push(sc.code)
              }
            })
          }
        })
      }
    });
    return codes;
  }

  atLeastOneChoice() {
    return !!this._getSelectedChoiceCode().length
  }

  // cascade uncheking of all children
  unCheckedChildren(c: IChoice) {
    if (!c.completed) {
      if (c.subChoices) {
        c.subChoices = c.subChoices.map(sc => {
          if (sc.subChoices) {
            sc.subChoices = sc.subChoices.map(ssc => {
              ssc.completed = false;
              return ssc;
            })
          }
          sc.completed = false;
          return sc;
        })
      }
    }
  }

  findRoom(fromLib: boolean) {
    this.dia.open(
      !fromLib ? DialogListRoomComponent : DialogListLibRoomComponent,
      {
        panelClass: 'dialog-full',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.source = result;
          this.fromLib = fromLib;
        }
      })
  }

  findEquipment(fromLib: boolean) {
    this.dia.open(
      !fromLib ? DialogListEquipmentComponent : DialogListLibEquipmentComponent,
      {
        panelClass: 'dialog-full',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.source = result;
          this.fromLib = fromLib;
        }
      })
  }

  findSet(fromLib: boolean) {
    this.dia.open(
      !fromLib ? DialogListSetComponent : DialogListLibSetComponent,
      {
        panelClass: 'dialog-full',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.source = result;
          this.fromLib = fromLib;
        }
      })
  }

  removeTarget(elem: IFamily) {
    this.targets = this.targets.filter(t => t._id !== elem._id)
  }

  save() {
    this.options.targets = this.targets;
    this.options.fromLib = this.fromLib;
    this.options.codes = this._getSelectedChoiceCode();
    this.options.source = this.source;
    this.self.close({
      confirm: true,
      option: this.options
    })
  }

  cancel() {
    this.self.close({confirm: false})
  }

}
