import {
  Component,
  ComponentFactoryResolver, ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {Subject} from "rxjs";
import {Cneh} from "../../../../model/cneh.model";
import {IFieldConfig, ListerComponent} from "../../../generics/lister/lister.component";
import {DynamicHostDirective} from "../../../../directives/dynamic-host.directive";
import {ListGetterService} from "../../../../services/generic/list-getter.service";
import {ConfigurationService} from "../../../../services/configuration.service";
import {Configuration} from "../../../../model/configuration.model";

@Component({
  selector: "app-list-configuration",
  templateUrl: "./list-configuration.component.html",
  styleUrls: ["./list-configuration.component.scss"]
})
export class ListConfigurationComponent implements OnInit {
  @Input()
  hookEvents: Subject<string>;

  @Input()
  actions: String[];

  @Input()
  customPath: string = null;

  @Input()
  isLibrary: boolean = true;

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Configuration> = new EventEmitter();

  @Output()
  delete: EventEmitter<Configuration> = new EventEmitter();

  @Output()
  create: EventEmitter<Configuration> = new EventEmitter();

  component: any;
  fields: IFieldConfig[] = [
    {
      key: "constraintLabel",
      i18Key: "configuration.constraintLabel",
      width: 20,
      searchable: true
    },
    {
      key: "constraintSpecialityLabel",
      i18Key: "configuration.constraintSpecialityLabel",
      width: 30,
      searchable: true
    },
    {
      key: "comments",
      i18Key: "configuration.comments",
      width: 20,
      searchable: true
    },
    {
      key: "isPhysic",
      i18Key: "configuration.isPhysic",
      width: 5,
      searchable: true,
      transform: (isPhysic) => isPhysic ? "oui" : "non"
    },
    {
      key: "values",
      i18Key: "configuration.values",
      width: 20,
      searchable: true,
      chips: true,
      transform: (values) => values ? Object.keys(values).map(k => `${values[k]} ${k}`) : values
    },
    {
      key: "quantity",
      i18Key: "configuration.quantity",
      width: 5,
      searchable: true,
    }
  ];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private configurationService: ConfigurationService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef.createComponent(factory, undefined, Injector.create({
      providers: [
        {
          provide: ListGetterService,
          useValue: this.configurationService
        },
        {
          provide: "FIELD_CONFIG",
          useValue: this.fields
        }
      ],
      parent: this.injector
    }));
    ref.instance.customPath = this.customPath;
    ref.instance.isLibrary = this.isLibrary;
    ref.instance.actions = this.actions;
    ref.instance.select = this.select;
    ref.instance.delete = this.delete;
    ref.instance.create = this.create;
    ref.instance.hookEvents = this.hookEvents;
  }

}
