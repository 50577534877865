import {Component, Inject, OnInit} from "@angular/core";
import {Prestation} from "../../../../model/prestation.model";
import {Tva} from "../../../../model/tva.model";
import {Finance} from "../../../../model/finance.model";
import {FinanceService} from "../../../../services/finance.service";
import {TvaService} from "../../../../services/tva.service";
import {PrestationService} from "../../../../services/prestation.service";
import {FormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-dialog-edit-prestation",
  templateUrl: "./dialog-edit-prestation.component.html",
  styleUrls: ["./dialog-edit-prestation.component.scss"]
})
export class DialogEditPrestationComponent {

  profileForm;
  prestation: Prestation = null;
  edit = false;

  tvas: Tva[] = [];
  finances: Finance[] = [];
  library = false;

  constructor(private financeService: FinanceService,
              private tvaService: TvaService,
              private prestationService: PrestationService,
              private dialogRef: MatDialogRef<DialogEditPrestationComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.tvaService
      .getAll()
      .subscribe((e) => {
        this.tvas = e;
      });
    if (this.data.project) {
      this.financeService
        .getAllByProject(this.data.project._id)
        .subscribe((e) => {
          this.finances = e;
        });
    } else {
      this.library = true;
    }
    if (this.data.prestation) {
      this.prestation = this.data.prestation;
      this.profileForm = Prestation.formBuilder(this.fb, this.prestation);
      this.edit = true;
    } else {
      this.profileForm = Prestation.formBuilder(this.fb, new Prestation());
      this.edit = false;
    }
  }

  finish() {
    this.dialogRef.close(this.profileForm.value);
  }

  compareTva(a, b): boolean {
    if (!a || !b) {
      return false;
    }
    if (a._id == b._id) {
      return true;
    }
    return false;
  }

  compareFinance(a, b): boolean {
    if (!a || !b) {
      return false;
    }
    if (a._id == b._id) {
      return true;
    }
    return false;
  }

}
