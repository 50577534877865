import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import {Subject} from 'rxjs';

import {IFieldConfig, ListerComponent} from '../../../generics/lister/lister.component';
import {DynamicHostDirective} from '../../../../directives/dynamic-host.directive';
import {ListGetterService} from '../../../../services/generic/list-getter.service';
import {RoomService} from '../../../../services/room.service';
import {Room} from '../../../../model/room.model';
import {EFamily} from '../../../../model/enum/family.enum';

@Component({
  selector: "app-list-room",
  templateUrl: "./list-room.component.html",
  styleUrls: ["./list-room.component.scss"]
})
export class ListRoomComponent implements OnInit {

  @Input()
  hookEvents: Subject<string>;

  @Input()
  actions: String[];

  @Input()
  customPath: string = null;

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Room> = new EventEmitter();

  @Output()
  delete: EventEmitter<Room[]> = new EventEmitter();

  @Output()
  create: EventEmitter<Room> = new EventEmitter();

  @Output()
  import: EventEmitter<Boolean> = new EventEmitter();

  @Output()
  xlsx: EventEmitter<any> = new EventEmitter();

  component: any;
  fields: IFieldConfig[] = [
    {
      key: "label",
      i18Key: "room.label",
      width: 33,
      searchable: true
    },
    {
      key: "hugCode",
      i18Key: "room.hugCode",
      width: 14,
      searchable: true
    },
    {
      key: "comment",
      i18Key: "room.comment",
      width: 33,
      searchable: true
    },
    {
      key: "area",
      i18Key: "room.area",
      width: 7,
      searchable: true
    },
    {
      key: "price",
      i18Key: "common.price",
      width: 13,
      searchable: false,
      serviceFunctionName: 'getOneElementPrice'
    },
  ];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private roomService: RoomService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef
      .createComponent(factory, undefined, Injector.create({
        providers: [
          {
            provide: ListGetterService,
            useValue: this.roomService
          },
          {
            provide: "FIELD_CONFIG",
            useValue: this.fields
          }
        ],
        parent: this.injector
      }));
    ref.instance.customPath = this.customPath;
    ref.instance.queriables = ["room.label","room.hugCode"];
    ref.instance.actions = this.actions;
    ref.instance.select = this.select;
    ref.instance.delete = this.delete;
    ref.instance.xlsx = this.xlsx;
    ref.instance.create = this.create;
    ref.instance.import = this.import;
    ref.instance.family = EFamily.Room;
    ref.instance.hookEvents = this.hookEvents;
  }
}
