import {
  Component,
  ComponentFactoryResolver, ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {Subject} from "rxjs";
import {Prestation} from "../../../../model/prestation.model";
import {IFieldConfig, ListerComponent} from "../../../generics/lister/lister.component";
import {DynamicHostDirective} from "../../../../directives/dynamic-host.directive";
import {PrestationService} from "../../../../services/prestation.service";
import {ListGetterService} from "../../../../services/generic/list-getter.service";

@Component({
  selector: 'app-list-prestation',
  templateUrl: './list-prestation.component.html',
  styleUrls: ['./list-prestation.component.scss']
})
export class ListPrestationComponent implements OnInit {

  @Input()
  hookEvents: Subject<string>;

  @Input()
  actions: String[];

  @Input()
  customPath: string = null;

  @Input()
  isLibrary: boolean = false;

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Prestation> = new EventEmitter();

  @Output()
  delete: EventEmitter<Prestation> = new EventEmitter();

  @Output()
  create: EventEmitter<Prestation> = new EventEmitter();

  component: any;
  libraryFields: IFieldConfig[] = [
    {
      key: "label",
      i18Key: "prestation.label",
      width: 25,
      searchable: true
    },
    {
      key: "comment",
      i18Key: "prestation.comment",
      width: 45,
      searchable: true
    },
    {
      key: "tva",
      i18Key: "prestation.tva",
      width: 15,
      searchable: true,
      transform: (tva) => tva?tva.value:null
    },
    {
      key: "price",
      i18Key: "prestation.price",
      width: 15,
      searchable: true,
    },
  ];

  fields: IFieldConfig[] = [
    {
      key: "label",
      i18Key: "prestation.label",
      width: 20,
      searchable: true
    },
    {
      key: "comment",
      i18Key: "prestation.comment",
      width: 40,
      searchable: true
    },
    {
      key: "finance",
      i18Key: "prestation.finance",
      width: 20,
      searchable: true
    },
    {
      key: "tva",
      i18Key: "prestation.tva",
      width: 10,
      searchable: true,
      transform: (tva) => tva?tva.value:null
    },
    {
      key: "price",
      i18Key: "prestation.price",
      width: 10,
      searchable: true,
    },
  ];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private prestationService: PrestationService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef.createComponent(factory, undefined, Injector.create({
      providers: [
        {
          provide: ListGetterService,
          useValue: this.prestationService
        },
        {
          provide: "FIELD_CONFIG",
          useValue: this.isLibrary ? this.libraryFields : this.fields
        }
      ],
      parent: this.injector
    }));
    ref.instance.customPath = this.customPath;
    ref.instance.queriables = ['prestation.label'];
    if (this.isLibrary){
      ref.instance.populate = ['tva'];
    } else {
      ref.instance.populate = ['tva', 'finance'];
    }
    ref.instance.isLibrary = this.isLibrary;
    ref.instance.actions = this.actions;
    ref.instance.select = this.select;
    ref.instance.delete = this.delete;
    ref.instance.create = this.create;
    ref.instance.hookEvents = this.hookEvents;
  }

}
