<h2 *ngIf="data.action == 'modify'" mat-dialog-title>Modifier {{data.cneh.code2010}}</h2>
<h2 *ngIf="data.action == 'create'" mat-dialog-title>Créer un nouveau CNEH</h2>
<form [formGroup]="profileForm" (ngSubmit)="onSubmit()" novalidate>
  <mat-dialog-content class="mat-typography form">
    <mat-form-field>
      <input required matInput placeholder="{{'cneh.code2010' | translate}}" formControlName="code2010">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'cneh.family' | translate}}" formControlName="family">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'cneh.codeFamily' | translate}}" formControlName="codeFamily">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'cneh.fct' | translate}}" formControlName="fct">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'cneh.codeFct' | translate}}" formControlName="codeFct">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'cneh.eqpmt' | translate}}" formControlName="eqpmt">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'cneh.eqpmtCode' | translate}}" formControlName="eqpmtCode">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'cneh.cpnt' | translate}}" formControlName="cpnt">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'cneh.cpntCode' | translate}}" formControlName="cpntCode">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Annuler</button>
    <button [disabled]="!profileForm.valid" *ngIf="data.action == 'create'" type="submit" color="primary" mat-flat-button >Créer</button>
    <button [disabled]="!profileForm.valid" *ngIf="data.action == 'modify'" type="submit" color="primary" mat-flat-button >Modifier</button>
  </mat-dialog-actions>
</form>
