import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Finance, FinanceAdapter} from '../model/finance.model';
import {Version} from '../model/version.model';
import {Project} from '../model/project.model';
import {Tva} from '../model/tva.model';

@Injectable({
  providedIn: 'root'
})
export class FinanceService implements Resolve<Finance[]> {

  constructor(private api: ApiService, private adapter: FinanceAdapter) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Finance[]> | Promise<Finance[]> | Finance[] {
    return this.getAllByProject(route.params.idProject);
  }

  getAllByProject(idProject:string): Observable<Finance[]> {
    return this.api.get('project/' + idProject + '/finances').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Finance> {
    return this.api.get('finance/' + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id:string, finance:Finance): Observable<Finance> {
    return this.api.put('finance/' + id, finance).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(project:Project, finance:Finance): Observable<Finance> {
    return this.api.post('project/' + project._id + '/finances', finance).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  deleteInEquipment(equipmentId, options: Options): Observable<Finance> {
    return this.api.delete('equipment/' + equipmentId + '/finance', options);
  }

  delete(id: string, options: Options): Observable<Finance> {
    return this.api.delete('finance/' + id, options);
  }
}
