import {NgModule} from '@angular/core';
import {DialogDeleteComponent} from './components/atoms/dialog-delete/dialog-delete.component';

import {DialogFenceComponent} from './components/other/dialog/dialog-fence/dialog-fence.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {FilesComponent} from './components/atoms/files/files.component';
import {DialogFileViewerComponent} from './components/other/dialog/dialog-file-viewer/dialog-file-viewer.component';
import {PriceComponent} from './components/atoms/price/price.component';
import {DialogImportComponent} from './components/other/dialog-import/dialog-import.component';
import {ApiService} from './services/api.service';
import {CnehService} from './services/cneh.service';
import {ConfigurationService} from './services/configuration.service';
import {ConstraintService} from './services/constraint.service';
import {EquipmentService} from './services/equipment.service';
import {FileService} from './services/file.service';
import {FinanceService} from './services/finance.service';
import {ImportService} from './services/import.service';
import {JobService} from './services/job.service';
import {OptionService} from './services/option.service';
import {PeopleService} from './services/people.service';
import {PrestationService} from './services/prestation.service';
import {ProjectService} from './services/project.service';
import {RoomService} from './services/room.service';
import {SearchService} from './services/search.service';
import {SetService} from './services/set.service';
import {SpecialityService} from './services/speciality.service';
import {TvaService} from './services/tva.service';
import {VersionService} from './services/version.service';
import {MaterialImportModule} from './material-import.module';
import {KeysPipe} from './pipes/keys.pipe';
import {DynamicHostDirective} from './directives/dynamic-host.directive';
import {ListCnehComponent} from './components/entities/cneh/list-cneh/list-cneh.component';
import {ListerComponent} from './components/generics/lister/lister.component';
import {DialogCnehComponent} from './components/entities/cneh/dialog-cneh/dialog-cneh.component';
import {ListTvaComponent} from './components/entities/tva/list-tva/list-tva.component';
import {DialogTvaComponent} from './components/entities/tva/dialog-tva/dialog-tva.component';
import {ListJobComponent} from './components/entities/job/list-job/list-job.component';
import {DialogJobComponent} from './components/entities/job/dialog-job/dialog-job.component';
import {DialogConstraintComponent} from './components/entities/constraint/dialog-constraint/dialog-constraint.component';
import {DialogSpecialityComponent} from './components/entities/constraint/dialog-speciality/dialog-speciality.component';
import {ListConstraintComponent} from './components/entities/constraint/list-constraint/list-constraint.component';
import {ListConstraintAndSpecialityComponent} from './components/entities/constraint/list-constraint-and-speciality/list-constraint-and-speciality.component';
import {ListSpecialityComponent} from './components/entities/constraint/list-speciality/list-speciality.component';
import {DialogEditLibRoomComponent} from './components/entities/lib-room/dialog-edit-lib-room/dialog-edit-lib-room.component';
import {ListLibRoomComponent} from './components/entities/lib-room/list-lib-room/list-lib-room.component';
import {LibRoomService} from './services/entities/lib-room/lib-room.service';
import {LibEquipmentService} from './services/entities/lib-equipment/lib-equipment.service';
import {ListLibEquipmentComponent} from './components/entities/lib-equipments/list-lib-equipment/list-lib-equipment.component';
import {ChfPipe} from './pipes/chf.pipe';
import {LibSetService} from './services/entities/lib-set/lib-set.service';
import {ListLibSetComponent} from './components/entities/lib-sets/list-lib-set/list-lib-set.component';
import {ListLibImportComponent} from './components/entities/lib-import/list-lib-import/list-lib-import.component';
import {ListRoomComponent} from './components/entities/room/list-room/list-room.component';
import {DialogListRoomComponent} from './components/entities/room/dialog-list-room/dialog-list-room.component';
import {EditRoomComponent} from './components/entities/room/edit-room/edit-room.component';
import {ListConfigurationComponent} from './components/entities/configuration/list-configuration/list-configuration.component';
import {DialogEditConfigurationComponent} from './components/entities/configuration/dialog-edit-configuration/dialog-edit-configuration.component';
import {DialogEditSetComponent} from './components/entities/set/dialog-edit-set/dialog-edit-set.component';
import {DialogCreateComponent} from './components/atoms/dialog-create/dialog-create.component';
import {DialogListSetComponent} from './components/entities/set/dialog-list-set/dialog-list-set.component';
import {ListSetComponent} from './components/entities/set/list-set/list-set.component';
import {DialogListCnehComponent} from './components/entities/cneh/dialog-list-cneh/dialog-list-cneh.component';
import {ListPrestationComponent} from './components/entities/prestation/list-prestation/list-prestation.component';
import {DialogEditPrestationComponent} from './components/entities/prestation/dialog-edit-prestation/dialog-edit-prestation.component';
import {DialogEditEquipmentComponent} from './components/entities/equipment/dialog-edit-equipment/dialog-edit-equipment.component';
import {ListEquipmentComponent} from './components/entities/equipment/list-equipment/list-equipment.component';
import {DialogListEquipmentComponent} from './components/entities/equipment/dialog-list-equipment/dialog-list-equipment.component';
import {DialogListLibEquipmentComponent} from './components/entities/lib-equipments/dialog-list-lib-equipment/dialog-list-lib-equipment.component';
import {DialogCloneComponent} from './components/atoms/dialog-clone/dialog-clone.component';
import {DialogListLibSetComponent} from './components/entities/lib-sets/dialog-list-lib-set/dialog-list-lib-set.component';
import {DialogListLibRoomComponent} from './components/entities/lib-room/dialog-list-lib-room/dialog-list-lib-room.component';
import {DialogZoneFormComponent} from './components/entities/room/zones/dialog-zone-form/dialog-zone-form.component';
import {ZonesComponent} from './components/entities/room/zones/zones.component';
import {MaintenanceComponent} from './components/atoms/maintenance/maintenance.component';
import {DialogSyncComponent} from './components/other/dialog-sync/dialog-sync.component';
import {TippyModule} from '@ngneat/helipopper';
import {DialogRenameComponent} from './components/atoms/dialog-rename/dialog-rename/dialog-rename.component';
import {SelectEquipmentStepperComponent} from './components/other/select-equipment-stepper/select-equipment-stepper.component';
import {ListProjectComponent} from './components/entities/project/list-projects/list-project.component';
import {ListVersionComponent} from './components/entities/project/list-version/list-version.component';
import {RoomEfEquipmentListComponent} from './components/entities/room/room-ef-equipment-list/room-ef-equipment-list.component';

@NgModule({
  declarations: [
    // old
    DialogDeleteComponent,
    DialogFenceComponent,
    KeysPipe,
    FilesComponent,
    DialogFileViewerComponent,
    PriceComponent,
    DynamicHostDirective,

    // pipe
    ChfPipe,

    // atoms
    DialogCreateComponent,
    DialogCloneComponent,
    DialogRenameComponent,
    MaintenanceComponent,

    // generic
    ListerComponent,
    DialogSyncComponent,
    ListLibImportComponent,
    DialogImportComponent,

    // Project
    ListProjectComponent,
    ListVersionComponent,

    // Room
    ListRoomComponent,
    DialogListRoomComponent,
    EditRoomComponent,
    ListLibRoomComponent,
    DialogEditLibRoomComponent,
    DialogListLibRoomComponent,
    DialogZoneFormComponent,
    ZonesComponent,
    RoomEfEquipmentListComponent,

    // Set
    ListLibSetComponent,
    ListSetComponent,
    DialogListSetComponent,
    DialogListLibSetComponent,
    DialogEditSetComponent,

    // Equipment
    ListLibEquipmentComponent,
    ListEquipmentComponent,
    DialogListEquipmentComponent,
    DialogListLibEquipmentComponent,
    DialogEditEquipmentComponent,

    // CNEH
    ListCnehComponent,
    DialogListCnehComponent,
    DialogCnehComponent,

    // TVA
    ListTvaComponent,
    DialogTvaComponent,

    // JOB
    ListJobComponent,
    DialogJobComponent,

    // Constraint & speciality
    DialogConstraintComponent,
    DialogSpecialityComponent,
    ListConstraintComponent,
    ListSpecialityComponent,
    ListConstraintAndSpecialityComponent,

    // Configurations
    ListConfigurationComponent,
    DialogEditConfigurationComponent,

    // Prestation
    ListPrestationComponent,
    DialogEditPrestationComponent,

    SelectEquipmentStepperComponent,
  ],
  imports: [
    MaterialImportModule,
    NgxFileDropModule,
    TippyModule.forRoot(),
  ],
  exports: [
    NgxFileDropModule,
    FilesComponent,
    PriceComponent,
    KeysPipe,
    MaterialImportModule,

    // Robin
    ListCnehComponent,
    ListTvaComponent,
    ListJobComponent,
    ListConstraintAndSpecialityComponent,
    ListLibRoomComponent,
    ListLibEquipmentComponent,
    ListLibSetComponent,
    ListLibImportComponent,
    EditRoomComponent,
    ListConfigurationComponent,
    ListSetComponent,
    ListPrestationComponent,
    ListEquipmentComponent,
    ListRoomComponent,
    ChfPipe,
    DynamicHostDirective,
    MaintenanceComponent,
    DialogSyncComponent
  ],
  providers: [
    ApiService,
    CnehService,
    ConfigurationService,
    ConstraintService,
    EquipmentService,
    FileService,
    FinanceService,
    ImportService,
    JobService,
    OptionService,
    PeopleService,
    PrestationService,
    ProjectService,
    RoomService,
    SearchService,
    SetService,
    SpecialityService,
    TvaService,
    VersionService,
    // lib
    LibRoomService,
    LibEquipmentService,
    LibSetService,
  ]
})
export class SharedModule {
}

