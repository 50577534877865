import {Injectable} from '@angular/core';
import {ApiService} from '../../api.service';
import {IListParams, ListGetterService} from '../../generic/list-getter.service';
import {Equipment} from '../../../model/equipment.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LibEquipmentService extends ListGetterService<Equipment> {

  constructor(protected api: ApiService) {
    super('library/equipment/', api);
  }

  // overrite super fonction because get route is a custom one
  getAllList(params: IListParams): Observable<{ total: number, data: Equipment[] }> {
    params = params || super.giveDefaultParams();
    if (!params.populate || params.populate === '') {
      params.populate = 'cneh';
    }
    return this.api.getWithHeader('library/equipment', params)
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }
}
