import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from './user.model';
import {Job} from './job.model';
import {WithId} from './interface/withId.interface';

export class People implements WithId {
  public _id:string;
  public job:Job = new Job();
  public service:string;
  public firstName:string;
  public lastName:string;
  public phone:string;
  public mail:string;
  public unity:string;

  static formBuilder(fb:FormBuilder, people:People):FormGroup{
    return fb.group({
      job: [people.job._id],
      service: [people.service],
      firstName: [people.firstName, Validators.required],
      lastName: [people.lastName, Validators.required],
      phone: [people.phone],
      mail: [people.mail],
      unity: [people.unity]
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class PeopleAdapter implements Adapter<People> {
  adapt(item: any): People {
    const d = new People();
    d._id = item._id;
    d.job = item.job || {};
    d.service = item.service;
    d.firstName = item.firstName;
    d.lastName = item.lastName;
    d.phone = item.phone;
    d.mail = item.mail;
    d.unity = item.unity;
    return d;
  }
}
