<!--HEADER-->
<h2 mat-dialog-title
    style="display: inline-block;">
  Ensemble fonctionnel: {{data.set.label}}
</h2>
<button *ngIf="write"
        mat-flat-button
        color="primary"
        style="margin-left: 10px;float: right;"
        (click)="onSubmit()">
  Sauvegarder
</button>
<button *ngIf="write && !library"
        (click)="import()"
        style="margin-left: 10px;float:right"
        mat-stroked-button>
  Proposer dans la bibliothèque
</button>
<button *ngIf="write && !library"
        (click)="sync()"
        color="accent"
        style="float:right"
        mat-stroked-button>
  Synchroniser
</button>

<div class="card">
  <div class="card-content no-padding">
    <mat-tab-group>

      <!--PARAMETER-->
      <mat-tab label="Paramètres ensemble fonctionnel">
        <form [formGroup]="profileForm"
              [class.form-no]="!write">
          <div class="flex">
            <!-- Parameter -->
            <div class="col">
              <div class="card">
                <div class="card-header">
                  Paramètres
                </div>
                <div class="card-content">
                  <div class="form">
                    <!--label-->
                    <div>
                      <mat-form-field>
                        <input matInput
                               placeholder="{{'set.label' | translate}}"
                               formControlName="label">
                      </mat-form-field>
                    </div>

                    <!--CNEH-->
                    <div>
                      <mat-form-field (click)="openCneh()"
                                      class="pointer">
                        <input matInput
                               placeholder="{{'equipment.cneh' | translate}}"
                               readonly="true"
                               [value]="cneh?cneh.code2010:''">
                      </mat-form-field>
                      <mat-form-field *ngIf="!library">
                        <mat-label>{{'set.zone' | translate}}</mat-label>
                        <mat-select formControlName="zones"
                                    multiple>
                          <mat-option *ngFor="let zone of zones"
                                      value="{{zone.name}}">{{zone.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <!--Comment0-->
                    <mat-form-field>
                      <textarea matInput
                                placeholder="{{'equipment.comment' | translate}}"
                                formControlName="comment"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <!-- Files 1 -->
            <div class="col">
              <app-files [write]="write"
                         [route]="(library?'library/':'') + 'set/' + set._id + '/plans'"
                         [small]="false"
                         [marginTop]="0"
                         [title]="'Image/Plan'">
              </app-files>
              <div style="margin-top:10px;">
                <app-maintenance [formGroup]="profileForm">
                </app-maintenance>
              </div>
            </div>
          </div>

          <!-- Files 2 -->
          <div class="flex">
            <div class="col">
              <app-files [write]="write"
                         [route]="(library?'library/':'') + 'set/' + set._id + '/docs'"
                         [small]="false"
                         [marginTop]="0"
                         [title]="'Offre et Documentation technique'"></app-files>
            </div>
          </div>
        </form>
      </mat-tab>

      <!--CONTRAINTES-->
      <mat-tab label="Contraintes">
        <ng-template matTabContent>
          <app-list-configuration
            [actions]="write? adminActions: userActions"
            [hookEvents]="hookConfig"
            [isLibrary]="library"
            [customPath]="(library? 'library/':'') + 'set/' + set._id + '/configurations'"
            (select)="configSelected($event)"
            (create)="createConfig()">
          </app-list-configuration>
        </ng-template>
      </mat-tab>

      <!--PRESTATIONS-->
      <mat-tab label="Prestation">
        <ng-template matTabContent>
          <app-list-prestation
            [actions]="write? adminActions: userActions"
            [hookEvents]="hookPresta"
            [isLibrary]="library"
            [customPath]="(library? 'library/':'') + 'set/' + set._id + '/prestations'"
            (select)="prestationSelected($event)"
            (create)="createPrestation()">
          </app-list-prestation>
        </ng-template>
      </mat-tab>

      <!--EQUIPEMENTS-->
      <mat-tab>
        <ng-template matTabLabel>
          <span>
            Equipements
          </span>
        </ng-template>
        <div class="card no-radius">

          <!-- HEADER -->
          <div class="card-header">
            <div class="right"
                 *ngIf="write">
              <!-- ADD -->
              <button mat-flat-button
                      (click)="createEquipment()"
                      color="primary">
                Ajouter un équipement
              </button>
              <!-- IMPORT -->
              <button [matMenuTriggerFor]="menu"
                      mat-flat-button
                      color="primary">
                Importer un équipement
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item
                        (click)="importEquipment(true)">
                  Depuis la bibliothèque
                </button>
                <button mat-menu-item
                        (click)="importEquipment(false)">
                  Depuis un projet
                </button>
              </mat-menu>
            </div>
          </div>

          <!-- CONTENT -->
          <div class="card-content no-padding padding-left">
            <table class="common">
              <thead>
              <tr>
                <td width="50px">Qté</td>
                <td>Désignation EQU.</td>
                <td>CNEH</td>
                <td>Marque</td>
                <td>Modèle</td>
                <td class="price">Coûts<br>(TTC)</td>
                <td style="text-align:center; width: 20px"></td>
              </tr>
              </thead>
              <tbody>
                <!--GROUPE LINE-->
                <ng-container *ngFor="let group of groupEquipment">
                  <tr style="font-weight: bold"
                      class="borderBottom selectable"
                      (click)="group.open = !group.open"
                      *ngIf="group.quantity != 1">
                    <td class="pointer">
                      {{group.quantity}}
                      <mat-icon *ngIf="group.open">
                        expand_more
                      </mat-icon>
                      <mat-icon *ngIf="!group.open">
                        expand_less
                      </mat-icon>
                    </td>
                    <td>{{group.label}}</td>
                    <td>{{group.cneh}}</td>
                    <td>{{group.brand}}</td>
                    <td>{{group.model}}</td>
                    <td class="price">{{group.computePriceTTC| chf}}</td>
                    <td>
                      <mat-icon class="z-index-on-top"
                                *ngIf="write"
                                (click)="deleteGroupEquipment(group, $event)">
                        delete
                      </mat-icon>
                    </td>
                  </tr>
                  <!--inside group-->
                  <ng-container *ngIf="group.open || group.quantity == 1">
                    <tr *ngFor="let equipment of group.equipments"
                        class="selectable"
                        (click)="equipmentSelected(equipment)"
                        [class.borderBottom]="group.quantity == 1">
                      <td>1</td>
                      <td>{{equipment.label}}</td>
                      <td>{{equipment.cneh}}</td>
                      <td>{{equipment.brand}}</td>
                      <td>{{equipment.model}}</td>
                      <td class="price">
                        {{equipment.computePriceTTC| chf}}</td>
                      <td>
                        <mat-icon class="z-index-on-top"
                                  *ngIf="write"
                                  (click)="deleteEquipment(equipment, $event)">
                          delete
                        </mat-icon>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
