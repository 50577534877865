import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IFamily} from './interface/ifamily.interface';
import {EFamily} from './enum/family.enum';

export class Room implements IFamily {
  public _id:string;
  public label:string;
  public family = EFamily.Room;
  public hugCode:string;
  public building:string;
  public floor:string;
  public departement:string;
  public service:string;
  public unit:string;
  public comment:string;
  public area:number;
  public configurations:any;
  public equipments:any;
  public sets:any;
  public zones:string[] = [];

  static formBuilderParam(fb:FormBuilder, room:Room):FormGroup{
    return fb.group({
      label: [room.label, Validators.required],
      hugCode: [room.hugCode],
      building: [room.building],
      floor: [room.floor],
      departement: [room.departement],
      service: [room.service],
      unit: [room.unit],
      comment: [room.comment],
      area: [room.area]
    });
  }

  static formBuilderZone(fb:FormBuilder, zone:any):FormGroup{
    return fb.group({
      name: [zone.name, Validators.required],
      comment: [zone.comment]
    });
  }

  static formBuilderCreate(fb:FormBuilder, room:Room):FormGroup{
    return fb.group({
      label: [room.label, Validators.required]
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class RoomAdapter implements Adapter<Room> {
  adapt(item: any): Room {
    const d = new Room();
    d._id = item._id;
    d.label = item.label;
    d.hugCode = item.hugCode;
    d.building = item.building;
    d.floor = item.floor;
    d.departement = item.departement;
    d.service = item.service;
    d.unit = item.unit;
    d.comment = item.comment;
    d.area = item.area;
    d.configurations = item.configurations;
    d.equipments = item.equipments;
    d.sets = item.sets;
    d.zones = item.zones;
    return d;
  }
}
