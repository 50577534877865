import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogListCnehComponent} from '../../entities/cneh/dialog-list-cneh/dialog-list-cneh.component';
import {Cneh} from '../../../model/cneh.model';

@Component({
  selector: 'app-dialog-create',
  templateUrl: './dialog-create.component.html',
  styleUrls: ['./dialog-create.component.scss']
})
export class DialogCreateComponent {

  title;
  value: string = '';
  private cneh: Cneh = null;
  public withCneh = false;

  constructor(private dialogRef: MatDialogRef<DialogCreateComponent>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any = {}) {
    this.title = data.i18nTitleKey || 'all.noTitle';
    this.withCneh = !!data.withCneh
  }

  save() {
    this.dialogRef.close({value: this.value, cneh: this.cneh});
  }

  inputKeyDown($event: KeyboardEvent) {
    if ($event && $event.code === 'Enter') {
      if (this.value && this.value.length) {
        this.save();
      }
    }
  }

  openCneh() {
    const dialogRef = this.dialog.open(
      DialogListCnehComponent,
      {
        panelClass: 'dialog-full-80'
      });
    dialogRef
      .afterClosed()
      .subscribe(cneh => {
        if (cneh) {
          this.cneh = cneh;
        }
      });
  }


}
