<div class="card">
  <div class="card-content no-padding">
    <mat-tab-group>

      <!--PARAMETRE-->
      <mat-tab label="Paramètres local">
        <div class="flex">
          <div class="col"
               style="flex:2">
            <div class="card">
              <!--TITLE-->
              <div class="card-header">
                Paramètres
              </div>
              <!--PARAMETER FORM-->
              <div class="card-content">
                <form [formGroup]="profileForm"
                      [class.form-no]="!write">
                  <mat-dialog-content class="mat-typography form">
                    <mat-form-field>
                      <input required
                             matInput
                             placeholder="{{'room.label' | translate}}"
                             formControlName="label">
                    </mat-form-field>
                    <mat-form-field *ngIf="!library">
                      <input matInput
                             placeholder="{{'room.hugCode' | translate}}"
                             formControlName="hugCode">
                    </mat-form-field>
                    <div *ngIf="!library">
                      <mat-form-field>
                        <input matInput
                               placeholder="{{'room.building' | translate}}"
                               formControlName="building">
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput
                               placeholder="{{'room.floor' | translate}}"
                               formControlName="floor">
                      </mat-form-field>
                    </div>
                    <div *ngIf="!library">
                      <mat-form-field>
                        <input matInput
                               placeholder="{{'room.departement' | translate}}"
                               formControlName="departement">
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput
                               placeholder="{{'room.service' | translate}}"
                               formControlName="service">
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field *ngIf="!library">
                        <input matInput
                               placeholder="{{'room.unit' | translate}}"
                               formControlName="unit">
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput
                               type="number"
                               placeholder="{{'room.area' | translate}}"
                               formControlName="area">
                        <span matSuffix>m<sup>2</sup></span>
                      </mat-form-field>
                    </div>
                    <mat-form-field>
                      <input matInput
                             placeholder="{{'room.comment' | translate}}"
                             formControlName="comment">
                    </mat-form-field>
                  </mat-dialog-content>
                </form>
              </div>
              <!--FOOTER-->
              <div class="card-footer"
                   *ngIf="write">
                <button *ngIf="!library"
                        (click)="import()"
                        style="float:left"
                        mat-stroked-button>
                  Proposer dans la bibliothèque
                </button>
                <button [disabled]="!profileForm.valid"
                        (click)="onSubmitParams()"
                        mat-flat-button
                        color="primary">
                  Sauvegarder
                </button>
              </div>

            </div>
          </div>
          <div class="col"
               style="flex:1"
               *ngIf="!library">
            <app-zones [write]="write"
                       [room]="room"
                       [library]="library"></app-zones>
          </div>
        </div>
        <div class="flex">
          <div class="col">
            <app-files [write]="write"
                       [route]="(library? 'library/':'') + 'room/'+room._id + '/files'"
                       [marginTop]="0">
            </app-files>
          </div>
        </div>
      </mat-tab>

      <!--CONTRAINTES-->
      <mat-tab label="Contraintes local">
        <ng-template matTabContent>
          <app-list-configuration
            [actions]="write? adminActions: userActions"
            [hookEvents]="hookConfig"
            [isLibrary]="library"
            [customPath]="(library? 'library/':'') + 'room/' + room._id + '/configurations'"
            (select)="configSelected($event)"
            (create)="createConfig()">
          </app-list-configuration>
        </ng-template>
      </mat-tab>

      <!--EF & EQUIPEMENTS-->
      <mat-tab label="EF & Equipements">
        <div class="card no-radius">

          <!-- HEADER -->
          <div class="card-header">
            <div class="right"
                 *ngIf="write">
              <!-- ADD EQUIPMENT-->
              <button mat-flat-button
                      (click)="createEquipment()"
                      color="primary">
                Ajouter un équipement
              </button>
              <!-- IMPORT EQUIPMENT-->
              <button [matMenuTriggerFor]="menu"
                      mat-flat-button
                      color="primary">
                Importer un équipement
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item
                        (click)="importEquipment(true)">
                  Depuis la bibliothèque
                </button>
                <button mat-menu-item
                        (click)="importEquipment(false)">
                  Depuis un projet
                </button>
              </mat-menu>
              <!-- ADD SET-->
              <button mat-flat-button
                      (click)="createSet()"
                      color="primary">
                Ajouter un E.F.
              </button>
              <!-- IMPORT SET-->
              <button [matMenuTriggerFor]="menuSet"
                      mat-flat-button
                      color="primary">
                Importer un E.F
              </button>
              <mat-menu #menuSet="matMenu">
                <button mat-menu-item
                        (click)="importSet(true)">
                  Depuis la bibliothèque
                </button>
                <button mat-menu-item
                        (click)="importSet(false)">
                  Depuis un projet
                </button>
              </mat-menu>
            </div>
          </div>

          <!-- CONTENT -->
          <div class="card-content no-padding padding-left">
            <app-room-ef-equipment-list
              [setSelection]="true"
              [groupSet]="groupSet"
              [groupEquipment]="groupEquipment"
              [withDeleteButton]="true"
              [write]="write"
              (deleteSet)="deleteSet($event)"
              (deleteSetGroup)="deleteSetGroup($event)"
              (deleteEquipment)="deleteEquipment($event)"
              (deleteEquipmentGroup)="deleteEquipmentGroup($event)"
              (setSelected)="setSelected($event)"
              (equipmentSelected)="equipmentSelected($event)"
            >

            </app-room-ef-equipment-list>
          </div>
        </div>
      </mat-tab>

      <!-- CLOSE ENTIRE ROOM TAB -->
      <mat-tab disabled
               *ngIf="!library">
        <ng-template mat-tab-label>
          <button mat-flat-button
                  color="primary"
                  (click)="onClose.emit()">
            Fermer ce local
          </button>
        </ng-template>
      </mat-tab>

    </mat-tab-group>
  </div>
</div>
