import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {People, PeopleAdapter} from '../model/people.model';
import {Project} from '../model/project.model';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PeopleService implements Resolve<People>{

  constructor(private api: ApiService, private adapter: PeopleAdapter) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<People> | Promise<People> | People {
    if (route.params.idPeople){
      return this.get(route.params.idPeople);
    }
    return null;
  }

  getAll(project:Project): Observable<People[]> {
    return this.api.get('project/' + project._id + '/peoples?populate=job').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<People> {
    return this.api.get('people/' + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id:string, people:People): Observable<People> {
    return this.api.put('people/' + id + '?populate=job', people).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(project:Project, people:People): Observable<People> {
    return this.api.post('project/' + project._id + '/peoples', people).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options): Observable<People> {
    return this.api.delete('people/' + id, options);
  }
}
