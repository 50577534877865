import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WithId} from './interface/withId.interface';

export class Job implements WithId {
  public _id;
  public title;
  public description;

  static formBuilder(fb:FormBuilder, job:Job):FormGroup{
    return fb.group({
      title: [job.title, Validators.required],
      description: [job.description],
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class JobAdapter implements Adapter<Job> {
  adapt(item: any): Job {
    const d = new Job();
    d._id = item._id;
    d.title = item.title;
    d.description = item.description;
    return d;
  }
}
