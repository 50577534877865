import {Component, Inject} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Room} from '../../../../../model/room.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-zone-form',
  templateUrl: './dialog-zone-form.component.html',
  styleUrls: ['./dialog-zone-form.component.scss']
})
export class DialogZoneFormComponent {

  profileForm;

  constructor(private dialogRef: MatDialogRef<DialogZoneFormComponent>, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.profileForm = Room.formBuilderZone(this.fb, this.data.zone || {});
  }

  onSubmit() {
    this.dialogRef.close(this.profileForm.value);
  }
}
