<!-- TITLE-->
<h2 mat-dialog-title>
  {{title}}
</h2>

<!--CONTENT -->
<mat-dialog-content class="mat-typography sync-content"
                    style="width:100%">
  <mat-horizontal-stepper linear
                          #stepper>
    <!-- LISTING STEP -->
    <mat-step>
      <ng-template matStepLabel>
        Liste des éléments
      </ng-template>
      <p>
        Vous vous apprétez à synchroniser les informations des éléments suivants:
      </p>
      <mat-chip-list>
        <ng-container *ngFor="let elem of targets">
          <mat-chip (removed)="removeTarget(elem)">
            {{elem.label}}
            <mat-icon matChipRemove>
              cancel
            </mat-icon>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </mat-step>

    <!-- SELECT OPTION STEP -->
    <mat-step>
      <ng-template matStepLabel>
        Choix des options
      </ng-template>
      <!-- OPTIONS -->
      <section class="selection">
        <ng-container *ngFor="let choice of choices">
          <span class="list">
            <mat-checkbox [(ngModel)]="choice.completed"
                          (change)="unCheckedChildren(choice)">
              {{choice.name}}
            </mat-checkbox>
            <br>
          </span>
          <!-- SUB_OPTIONS -->
          <ng-container *ngIf="choice.subChoices.length">
            <span class="sub-choices">
              <ul>
                <li *ngFor="let subChoice of choice.subChoices; let index = index">
                  <ng-container *ngIf="!index">
                    <!-- first sub choice is activated if parent is -->
                    <mat-checkbox [(ngModel)]="choice.completed"
                                  [disabled]="true">
                      {{subChoice.name}}
                    </mat-checkbox>
                  </ng-container>
                  <ng-container *ngIf="index">
                    <mat-checkbox [(ngModel)]="subChoice.completed"
                                  [disabled]="!choice.completed">
                      {{subChoice.name}}
                    </mat-checkbox>
                  </ng-container>
                  <!-- SUB_SUB_OPTIONS -->
                  <ng-container *ngIf="subChoice.subChoices.length">
                  <span class="sub-choices">
                    <ul>
                     <li *ngFor="let subSubChoice of subChoice.subChoices; let jindex = index">
                       <ng-container *ngIf="!jindex">
                         <!-- first sub choice is activated if parent is -->
                          <mat-checkbox [(ngModel)]="subChoice.completed"
                                        [disabled]="true">
                              {{subSubChoice.name}}
                          </mat-checkbox>
                       </ng-container>
                        <ng-container *ngIf="jindex">
                          <mat-checkbox [(ngModel)]="subSubChoice.completed"
                                        [disabled]="!subChoice.completed">
                            {{subSubChoice.name}}
                          </mat-checkbox>
                        </ng-container>
                     </li>
                    </ul>
                  </span>
                  </ng-container>
                </li>
              </ul>
            </span>
          </ng-container>
        </ng-container>
      </section>


    </mat-step>

    <!-- SOURCE STEP-->
    <mat-step>
      <ng-template matStepLabel>
        Trouver le modèle
      </ng-template>
      <div class="container-loading-button">
        <button mat-flat-button
                color="primary"
                (click)="this.findFunction(true)">
          Charger le modèle depuis la bibliothèque
        </button>
        <button mat-flat-button
                color="primary"
                (click)="this.findFunction(false)">
          Charger le modèle depuis les projets
        </button>
      </div>
      <div>
        <p><strong>Modèle selectionné: {{source?.label || "Veuillez choisir un modèle."}}</strong></p>
      </div>
    </mat-step>

    <!-- SYNCHRONISATION STEP-->
    <mat-step>
      <ng-template matStepLabel>
        Confirmation
      </ng-template>
      <p>
        Vous ête sur le point de synchroniser
        <strong [style.color]="'red'">
          {{targets.length}} élément(s)
        </strong>
        sur le model de
        <strong>{{source?.label}}</strong>.
        <br>
        Cette opération est irréversible.
        êtes-vous sure ?
      </p>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>

<!-- FOOTER -->
<mat-dialog-actions align="end">
  <button mat-flat-button
          color="warn"
          mat-dialog-close>
    Annuler
  </button>
  <!-- Next steps button-->
  <ng-container *ngIf="stepper.selectedIndex === 0">
    <button mat-button
            color="primary"
            [disabled]="!targets.length"
            (click)="stepper.next()">
      Continuer
    </button>
  </ng-container>
  <ng-container *ngIf="stepper.selectedIndex === 1">
    <button mat-button
            color="primary"
            [disabled]="!atLeastOneChoice()"
            (click)="stepper.next()">
      Continuer
    </button>
  </ng-container>
  <ng-container *ngIf="stepper.selectedIndex === 2">
    <button mat-button
            color="primary"
            [disabled]="!source"
            (click)="stepper.next()">
      Continuer
    </button>
  </ng-container>
  <ng-container *ngIf="stepper.selectedIndex === 3">
    <button mat-button
            color="primary"
            (click)="save()">
      Synchroniser
    </button>
  </ng-container>

</mat-dialog-actions>
