import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {WithId} from './interface/withId.interface';

export class F implements WithId {
  public _id;
  public isImage:boolean;
  public collectionName:string;
  public fieldName:string;
  public objectIdRef:string;
  public createdAt:Date;
  public name:string;
}

@Injectable({
  providedIn: 'root'
})
export class FileAdapter implements Adapter<F> {
  adapt(item: any): F {
    const d = new F();
    d._id = item._id;
    d.isImage = item.isImage;
    d.collectionName = item.collectionName;
    d.fieldName = item.fieldName;
    d.objectIdRef = item.objectIdRef;
    d.createdAt = item.createdAt;
    d.name = item.name;
    return d;
  }
}
