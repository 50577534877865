import {Component, forwardRef, Inject} from '@angular/core';
import {Cneh} from '../../../../model/cneh.model';
import {EquipmentService} from '../../../../services/equipment.service';
import {FormBuilder} from '@angular/forms';
import {Set} from '../../../../model/set.model';
import {DialogImportComponent} from '../../../other/dialog-import/dialog-import.component';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogListCnehComponent} from '../../cneh/dialog-list-cneh/dialog-list-cneh.component';
import {Configuration} from '../../../../model/configuration.model';
import {DialogEditConfigurationComponent} from '../../configuration/dialog-edit-configuration/dialog-edit-configuration.component';
import {ConfigurationService} from '../../../../services/configuration.service';
import {Prestation, PrestationAdapter} from '../../../../model/prestation.model';
import {DialogEditPrestationComponent} from '../../prestation/dialog-edit-prestation/dialog-edit-prestation.component';
import {PrestationService} from '../../../../services/prestation.service';
import {DialogCreateComponent} from '../../../atoms/dialog-create/dialog-create.component';
import {Equipment, EquipmentAdapter} from '../../../../model/equipment.model';
import {DialogEditEquipmentComponent} from '../../equipment/dialog-edit-equipment/dialog-edit-equipment.component';
import {DialogListEquipmentComponent} from '../../equipment/dialog-list-equipment/dialog-list-equipment.component';
import {DialogListLibEquipmentComponent} from '../../lib-equipments/dialog-list-lib-equipment/dialog-list-lib-equipment.component';
import {DialogCloneComponent} from '../../../atoms/dialog-clone/dialog-clone.component';
import {DialogDeleteComponent} from '../../../atoms/dialog-delete/dialog-delete.component';
import {Project} from '../../../../model/project.model';
import {Version} from '../../../../model/version.model';
import {Room} from '../../../../model/room.model';
import {DialogSyncComponent} from '../../../other/dialog-sync/dialog-sync.component';
import {EFamily} from '../../../../model/enum/family.enum';
import {ApiService} from '../../../../services/api.service';

@Component({
  selector: 'app-dialog-edit-set',
  templateUrl: './dialog-edit-set.component.html',
  styleUrls: ['./dialog-edit-set.component.scss']
})
export class DialogEditSetComponent {

  profileForm;
  cneh: Cneh = null;
  zones: any[] = [];
  priceTTC = '0';
  library = false;
  write: boolean = false;
  set: Set = null;
  room: Room = null;

  private hookConfig: Subject<string> = new Subject();
  private hookPresta: Subject<string> = new Subject();
  public adminActions = ['select', 'delete', 'create', 'search'];
  public userActions = ['select', 'search'];
  groupEquipment: any[] = [];
  private project: Project = null;
  private version: Version = null;

  constructor(private equipmentService: EquipmentService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<DialogEditSetComponent>,
              private fb: FormBuilder,
              private api: ApiService,
              private configService: ConfigurationService,
              private prestaService: PrestationService,
              private prestationAdapter: PrestationAdapter,
              private equipmentAdapter: EquipmentAdapter,
              private dia: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data) {
      this.cneh = this.data.set.cneh;
      this.set = this.data.set;
      if (this.data.write)
        this.write = true;
      if (this.data.library) {
        this.library = this.data.library;
        this.profileForm = Set.formBuilderLibrary(this.fb, this.set);
      } else {
        this.zones = this.data.room ? this.data.room.zones : [];
        this.project = this.data.project;
        this.room = this.data.room;
        this.version = this.data.version;
        this.profileForm = Set.formBuilder(this.fb, this.set);
      }
      this.refreshEquipments();
    }
  }

  onSubmit() {
    this.profileForm.value.cneh = this.cneh;
    if (this.profileForm.value.cneh) {
      this.profileForm.value.cneh = this.cneh;
    }
    this.dialogRef.close(this.profileForm.value);
  }

  close() {
    this.dialogRef.close();
  }

  openCneh() {
    const dialogRef = this.dialog.open(
      DialogListCnehComponent,
      {
        panelClass: 'dialog-full-80'
      });
    dialogRef
      .afterClosed()
      .subscribe(cneh => {
        if (cneh) {
          this.cneh = cneh;
        }
      });
  }

  import() {
    this.dialog.open(DialogImportComponent, {
      data: {
        object: 'Set',
        id: this.data.set._id,
        projectId: this.data.project._id,
        versionId: this.data.version._id,
        roomId: this.data.room._id,
        setId: null,
        name: this.data.set.label
      }
    });
  }

  sync() {
    if (!this.library) {
      this.dialog.open(DialogSyncComponent,
        {
          data: {
            targets: [this.set],
            family: EFamily.Set
          }
        })
        .afterClosed()
        .subscribe((result: any) => {
          if (result?.confirm) {
            this.api
              .bulkSync(result.option)
              .subscribe(() => {
                this.dialogRef.close('reload');
              })
          }
        });
    }
  }

  // ==================================== configurations
  configSelected(config: Configuration) {
    this.dialog.open(DialogEditConfigurationComponent, {
      width: '80vw',
      height: '60vh',
      data: {
        configuration: config
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.configService.put(config._id, result, (this.library ? 'library/' : ''))
            .subscribe((e) => {
              this.hookConfig.next('refresh');
            });
        }
      });
  }

  createConfig() {
    this.dialog.open(DialogEditConfigurationComponent, {
      width: '80vw',
      height: '60vh',
      data: {
        target: 'Set'
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.configService.post('set/' + this.set._id, result, (this.library ? 'library/' : ''))
            .subscribe((e) => {
              this.hookConfig.next('refresh');
            });
        }
      });
  }

  // ==================================== prestations
  prestationSelected(presta: Prestation) {
    this.dialog.open(DialogEditPrestationComponent, {
      width: '80vw',
      height: '60vh',
      data: {
        prestation: presta,
        project: this.project
      }
    })
      .afterClosed()
      .subscribe((result: Prestation) => {
        if (result) {
          this.prestaService.put(presta._id, result, this.library)
            .subscribe((e) => {
              this.hookPresta.next('refresh');
            });
        }
      });
  }

  createPrestation() {
    this.dialog
      .open(DialogCreateComponent, {
        data: {
          i18nTitleKey: 'creation.prestation'
        }
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result && result.value) {
          if (this.library) {
            this.prestaService
              .postInSetLibrary(this.set, this.prestationAdapter.adapt({label: result.value}))
              .subscribe((presta: Prestation) => {
                this.prestationSelected(presta);
              })
          } else {
            this.prestaService
              .postInSet(this.set, this.prestationAdapter.adapt({label: result.value}))
              .subscribe((presta: Prestation) => {
                this.prestationSelected(presta);
              })
          }
        }
      })
  }

  // ==================================== equipments
  refreshEquipments() {
    this.equipmentService
      .getAllInSetCustom(this.set, this.library)
      .subscribe((d) => {
        this.groupEquipment = d ? d.groupEquipment : [] || [];
      })
  }

  createEquipment() {
    this.dia
      .open(DialogCreateComponent, {
        data: {
          withCneh: true,
          i18nTitleKey: this.library ? 'creation.libEquipment' : 'creation.equipment'
        }
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result && result.value) {
          this.equipmentService
            .postInSet(this.set, this.equipmentAdapter.adapt({label: result.value}), this.library)
            .subscribe((equip: Equipment) => {
              this.equipmentSelected(equip);
            })
        }
      });
  }

  importEquipment(fromLib) {
    this.dia.open(
      fromLib ? DialogListLibEquipmentComponent : DialogListEquipmentComponent, {
        panelClass: 'dialog-full',
        data: {
          withProjectSelection: true
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          return this.dia.open(
            DialogCloneComponent, {
              panelClass: 'dialog-small',
              data: {
                title: 'import.equipmentQty',
                cloneObj: {
                  fromTarget: 'Equipment',
                  fromId: result._id,
                  toTarget: 'Set',
                  toId: this.set._id,
                  isFromLib: fromLib,
                  isToLib: this.library
                }
              }
            })
            .afterClosed()
            .subscribe((result: any) => {
              if (result === 'multiple') {
                this.refreshEquipments();
              } else if (result) {
                this.equipmentSelected(result);
              }
            });
        }
      });
  }

  equipmentSelected(equipment: Equipment) {
    this.equipmentService
      .getInBoth(equipment._id, this.library)
      .subscribe((e) => {
        this.dia.open(DialogEditEquipmentComponent, {
          panelClass: 'dialog-full',
          data: {
            equipment: e,
            library: this.library,
            room: this.room,
            project: this.project,
            version: this.version,
            write: this.write
          }
        })
          .afterClosed()
          .subscribe((result) => {
            if (result === 'reload') {
              this.equipmentService.get(e._id)
                .subscribe(e2 => {
                  this.equipmentSelected(e2);
                })
            } else if (result) {
              this.equipmentService
                .putInBoth(equipment._id, result, this.library)
                .subscribe((e) => {
                  this.refreshEquipments();
                });
            }
          });
      });
  }

  deleteEquipment(e: Equipment, event: MouseEvent) {
    if (!this.library) {
      this.equipmentService.delete(e._id, {}).subscribe(() => {
        this.refreshEquipments()
      });
    } else {
      this.equipmentService.deleteInLibrary(e._id, {}).subscribe(() => {
        this.refreshEquipments()
      });
    }
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  deleteGroupEquipment(group: any, event: MouseEvent) {
    if (group && group.equipments) {
      this.dia.open(DialogDeleteComponent, {
        data: {
          title: 'Suppression de plusieurs équipments dans un set.',
          verif: group.equipments.length + ' équipments (' + group.label + ')'
        },
      })
        .afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            this.equipmentService.bulkDelete(group.equipments.map(e => e._id), this.library)
              .subscribe(() => {
                this.refreshEquipments()
              })
          }
        })
    }
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

}
