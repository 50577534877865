import {ChangeDetectionStrategy, Component, EventEmitter} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {Room} from "../../../../model/room.model";

@Component({
  selector: 'app-dialog-list-lib-room',
  templateUrl: './dialog-list-lib-room.component.html',
  styleUrls: ['./dialog-list-lib-room.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogListLibRoomComponent{

  select: EventEmitter<Room>;

  constructor(public dialogRef: MatDialogRef<DialogListLibRoomComponent>) {
    this.select = new EventEmitter<Room>();
  }

  close(r:Room) {
    this.dialogRef.close(r);
  }

}
