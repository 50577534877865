import {Component, EventEmitter} from "@angular/core";
import {Equipment} from "../../../../model/equipment.model";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-list-lib-equipment',
  templateUrl: './dialog-list-lib-equipment.component.html',
  styleUrls: ['./dialog-list-lib-equipment.component.scss']
})
export class DialogListLibEquipmentComponent {

  select: EventEmitter<Equipment>;

  constructor(public dialogRef: MatDialogRef<DialogListLibEquipmentComponent>) {
    this.select = new EventEmitter<Equipment>();
  }

  close(e:Equipment) {
    this.dialogRef.close(e);
  }
}
