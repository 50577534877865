import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Cneh, CnehAdapter} from '../model/cneh.model';
import {Finance} from '../model/finance.model';
import {ListGetterService} from "./generic/list-getter.service";

@Injectable({
  providedIn: 'root'
})
export class CnehService extends ListGetterService<Cneh> implements Resolve<Cneh[]> {

  constructor(protected api: ApiService, private adapter: CnehAdapter) {
    super('cneh', api)
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Cneh[]> | Promise<Cneh[]> | Cneh[] {
    return this.getAll();
  }

  import(data): Observable<Cneh[]> {
    return this.api.post('cneh/import', data).pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  getAll(): Observable<Cneh[]> {
    return this.api.get('cneh').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Cneh> {
    return this.api.get('cneh/' + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id:string, cneh:Cneh): Observable<Cneh> {
    return this.api.put('cneh/' + id, cneh).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(cneh:Cneh): Observable<Cneh> {
    return this.api.post('cneh', cneh).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  deleteInEquipment(equipmentId, options: Options, prefix:string=''): Observable<Cneh> {
    return this.api.delete(prefix + 'equipment/' + equipmentId + '/cneh', options);
  }

  deleteInSet(equipmentId, options: Options, prefix:string=''): Observable<Cneh> {
    return this.api.delete(prefix + 'set/' + equipmentId + '/cneh', options);
  }

  delete(id: string, options: Options): Observable<Cneh> {
    return this.api.delete('cneh/' + id, options);
  }
}
