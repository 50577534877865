import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter, forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit, Output,
  ViewChild, ViewRef
} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {IListParams, ListGetterService} from '../../../services/generic/list-getter.service';
import {SelectionModel} from '@angular/cdk/collections';
import {DialogDeleteComponent} from '../../atoms/dialog-delete/dialog-delete.component';
import {TranslatePipe} from '@ngx-translate/core';
import {cloneDeep} from 'lodash'
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {WithId} from '../../../model/interface/withId.interface';
import {EFamily} from '../../../model/enum/family.enum';
import {DialogSyncComponent} from '../../other/dialog-sync/dialog-sync.component';

export interface IFieldConfig {
  key: string,
  i18Key?: string,
  width?: number;
  searchable?: boolean;
  transform?: (any) => any;
  chips?: boolean;
  serviceFunctionName?: string;
  isDate?: boolean;
}

/*
  Component dynamic to print a mat table list
  it can be sorted and query
  calls linked service to refresh data;
 */
@Component({
  selector: 'app-lister',
  templateUrl: './lister.component.html',
  styleUrls: ['./lister.component.scss'],
  providers: [TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListerComponent<T extends WithId> implements OnInit, OnDestroy {

  // =============================== INPUTS
  @Input()
  actions: string[] = [];

  @Input()
  perPage: number;

  // custom path for get request
  @Input()
  customPath: string = null;

  // add library for route
  @Input()
  isLibrary: boolean = false;

  // list of field that are used in the search
  @Input()
  queriables: string[] = [];

  @Input()
  hookEvents: Subject<string>;

  @Input()
  populate: string[];

  @Input()
  family: EFamily;

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<T> = new EventEmitter();

  @Output()
  delete: EventEmitter<T[]> = new EventEmitter();

  @Output()
  create: EventEmitter<void> = new EventEmitter();

  @Output()
  import: EventEmitter<boolean> = new EventEmitter();

  @Output()
  xlsx: EventEmitter<any> = new EventEmitter();

  @Output()
  export: EventEmitter<number> = new EventEmitter();

  // =============================== VIEWCHILDS
  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  @ViewChild('tablePaginator', {static: true})
  paginator: MatPaginator;

  @ViewChild('filter')
  filter: ElementRef;

  // =============================== VARIABLES
  public dataSource: MatTableDataSource<T> = new MatTableDataSource([]);
  public originalDataSource: T[] = [];
  public selection = new SelectionModel<T>(true, []);
  private globalSelection: T[] = [];
  public limit: number = 25;
  public page: number = 0;
  public query: string;
  public sortValue = [];
  public listOfFields = [];
  public inSelection = false;

  // config
  public deletable: boolean;
  public selectable: boolean;
  public creatable: boolean;
  public searchable: boolean;
  public importable: boolean;
  public syncable: boolean;
  public csvImportable: boolean;
  public exportable: boolean;
  public referenceFilterable: boolean;
  public referencedOnly = false;

  // =============================== PRIVATE METHOD
  private _configActions() {
    if (this.actions && this.actions.length) {
      this.deletable = this.actions.includes('delete');
      this.selectable = this.actions.includes('select');
      this.creatable = this.actions.includes('create');
      this.importable = this.actions.includes('import');
      this.syncable = this.actions.includes('sync');
      this.searchable = this.actions.includes('search');
      this.csvImportable = this.actions.includes('csv-import');
      this.exportable = this.actions.includes('export');
      this.referenceFilterable = this.actions.includes('referenceFilterable');
    }
  }

  // keep trace of selected items on refreshing data array
  private _refreshSelection() {
    const ids = this.globalSelection.map(s => s._id);
    // save selected id
    this.selection.selected.forEach((s: T) => {
      if (!ids.includes(s._id)) {
        this.globalSelection.push(s);
        ids.push(s._id);
      }
    });
    // refresh selection to match already selected item in the current list
    this.selection = new SelectionModel<T>(true,
      this.dataSource.data
        .filter(d => ids.includes(d._id))
    );
  }

  // =============================== CORE METHOD
  constructor(@Inject('FIELD_CONFIG') listOfFields: IFieldConfig[] = [],
              // this is to prevent circular because dialogSync need lister
              // @Inject(forwardRef(() => DialogSyncComponent)) private dialogSync,
              protected service: ListGetterService<T>,
              private cd: ChangeDetectorRef,
              private dialog: MatDialog,
              private translate: TranslatePipe) {
    this.listOfFields = listOfFields;
  }

  ngOnInit(): void {
    this._configActions();
    this.limit = this.perPage || 25;
    this.paginator.pageSize = this.limit;
    this.sort.sortChange.subscribe((e) => {
      if (e.direction == '') {
        this.sortValue = [];
      } else {
        this.sortValue = [[e.active, e.direction == 'asc' ? '1' : '-1']];
      }
      this.refresh()
    });
    if (this.hookEvents) {
      this.hookEvents
        .subscribe(str => {
          if (str === 'refresh') {
            this.refresh();
          }
        });
    }
    this.paginator.page.subscribe((e) => {
      this.limit = e.pageSize;
      this.page = e.pageIndex;
      this.refresh();
    });
    this.refresh();
    if (this.searchable) {
      this.cd.detectChanges();
      // filter
      fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
          map((event: any) => {
            return event.target.value;
          }),
          debounceTime(200),
          distinctUntilChanged()
        )
        .subscribe((query: string) => {
          this.query = query;
          this.refresh();
        });
    }
  }

  ngOnDestroy(): void {
    this.sort.sortChange.unsubscribe();
    this.paginator.page.unsubscribe();
    // this.hookEvents.unsubscribe();
  }

  // =============================== PUBLIC METHOD
  getParams(): IListParams {
    let final: IListParams = {
      limit: this.limit,
      skip: this.page * this.limit,
      sort: JSON.stringify(this.sortValue),
    };
    if (this.query) {
      final.query = this.query;
    }
    if (this.referencedOnly) {
      final.existFilter = ['reference'];
    }
    if (this.populate) {
      final.populate = this.populate.join(',');
    }
    return final;
  }

  refresh(force = false) {
    if (force || (this.cd && !(this.cd as ViewRef).destroyed)) {
      this.service.getAllList(this.getParams(), this.isLibrary, this.customPath)
        .subscribe((a: { total: number, data: T[] }) => {
          this.originalDataSource = cloneDeep(a.data);
          this.listOfFields.forEach((field: IFieldConfig) => {
            // map serviceFunctionName if existing
            if (field.serviceFunctionName) {
              a.data.map((d: any) => {
                d[field.key] = this.service[field.serviceFunctionName](d._id)
                  .pipe(map(thing => thing.toString()))
              })
            }
            // apply transformation if existing
            if (field.transform) {
              a.data.forEach((d: any) => {
                d[field.key] = field.transform(d[field.key])
              })
            }
          });
          this.paginator.length = a.total;
          this.dataSource.data = a.data;
          if (this.inSelection) {
            this._refreshSelection();
          }
          this.cd.detectChanges();
        })
    }
  }

  bulkDelete() {
    this.dialog.open(
      DialogDeleteComponent,
      {
        data: {
          verif: this.globalSelection.length + ' élement(s)'
        }
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.service
            .bulkDelete(this.globalSelection.map(s => s._id), this.isLibrary)
            .subscribe(() => {
              this.delete.emit(this.globalSelection);
              this.inSelection = false;
              this.globalSelection = [];
              this.selection.clear();
              this.refresh();
            })
        }
      });
  }

  bulkSync() {
    this.dialog.open(DialogSyncComponent,
      {
        data: {
          targets: this.globalSelection,
          family: this.family
        }
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result?.confirm) {
          this.service
            .bulkSync(result.option)
            .subscribe(() => {
              this.inSelection = false;
              this.globalSelection = [];
              this.selection.clear();
              this.refresh(true);
            })
        }
      });
  }

  public get displayedColumns() {
    const list = this.listOfFields.map(l => l.key);
    return this.deletable && this.inSelection ? ['select'].concat(list) : list
  }

  public toggleSelection() {
    this.inSelection = !this.inSelection;
    this.selection.clear();
    this.globalSelection = [];
    this.cd.detectChanges();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public toggleSelect(row) {
    this.selection.toggle(row);
    if (this.selection.isSelected(row)) {
      this.globalSelection.push(row)
    } else {
      this.globalSelection = this.globalSelection.filter(e => e._id !== row._id)
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle() {
    if (this.isAllSelected()) {
      const ids = this.dataSource.data.map(d=>d._id);
      this.globalSelection = this.globalSelection.filter(e => {
        return !ids.includes(e._id);
      });
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(row => {
        this.selection.select(row);
        this.globalSelection.push(row);
      });
    }
  }

  // select is a native UI event. Instead of replacing the name everywhere in the app we patch it here
  public noSelect($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  // information for user about which field the filter is apply on
  public giveFilterInfo() {
    if (this.queriables && this.queriables.length) {
      return 'Recherche effectuée sur: '
        + this.queriables
          .map(i18n => {
            return this.translate.transform(i18n)
          })
          .join(', ')
    }
    return ''
  }

  public switchReference() {
    this.referencedOnly = !this.referencedOnly;
    this.refresh();
  }

  downloadXlsx() {
    this.xlsx.emit({which: 'download', file: null})
  }

  uploadXlsx(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.xlsx.emit({which: 'import', file: fileList[0]})
    }
  }
}
