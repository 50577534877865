import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WithId} from './interface/withId.interface';

export class Prestation implements WithId {
  public _id;
  public label;
  public comment;
  public finance;
  public tva;
  public price;

  static formBuilder(fb: FormBuilder, prestation: Prestation): FormGroup {
    return fb.group({
      label: [prestation.label],
      comment: [prestation.comment],
      finance: [prestation.finance],
      tva: [prestation.tva],
      price: [prestation.price],
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class PrestationAdapter implements Adapter<Prestation> {
  adapt(item: any): Prestation {
    const d = new Prestation();
    d._id = item._id;
    d.price = item.price;
    d.tva = item.tva;
    d.finance = item.finance;
    d.comment = item.comment;
    d.label = item.label;
    return d;
  }
}
