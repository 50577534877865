import {
  Component,
  ComponentFactoryResolver, ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {Subject} from "rxjs";
import {IFieldConfig, ListerComponent} from "../../../generics/lister/lister.component";
import {DynamicHostDirective} from "../../../../directives/dynamic-host.directive";
import {ListGetterService} from "../../../../services/generic/list-getter.service";
import {ConstraintService} from "../../../../services/constraint.service";
import {Constraint} from "../../../../model/constraint.model";

@Component({
  selector: "app-list-constraint",
  templateUrl: "./list-constraint.component.html",
  styleUrls: ["./list-constraint.component.scss"]
})
export class ListConstraintComponent implements OnInit {

  @Input()
  hookEvents: Subject<string>;

  @Input()
  actions: String[];

  @Input()
  perPage: number = 25;

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Constraint> = new EventEmitter();

  @Output()
  delete: EventEmitter<Constraint> = new EventEmitter();

  @Output()
  create: EventEmitter<Constraint> = new EventEmitter();

  component: any;
  fields: IFieldConfig[] = [
    {
      key: "title",
      i18Key: "constraint.title",
      width: 20,
      searchable: true
    },
    {
      key: "specialities",
      i18Key: "constraint.specialities",
      width: 20,
      searchable: true,
      transform: (specialities) => specialities && specialities.length ? specialities[0].title : ""
    },
    {
      key: "target",
      i18Key: "constraint.target",
      width: 10,
      searchable: true,
      transform: (target) => {
        switch (target) {
          case 'room':
            return 'Local';
          case 'set' :
            return 'Équipement/EF';
          case 'equipment':
            return 'Équipement/EF';
          default:
            return ""
      }}
    },
    {
      key: "isPhysic",
      i18Key: "constraint.isPhysic",
      width: 10,
      searchable: true,
      transform: (isPhysic) => isPhysic ? "oui" : "non"
    },
    {
      key: "unities",
      i18Key: "constraint.unities",
      width: 40,
      searchable: true,
      chips: true
    }
  ];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private constraintService: ConstraintService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef.createComponent(factory, undefined, Injector.create({
      providers: [
        {
          provide: ListGetterService,
          useValue: this.constraintService
        },
        {
          provide: "FIELD_CONFIG",
          useValue: this.fields
        }
      ],
      parent: this.injector
    }));
    ref.instance.queriables = ['constraint.title'];
    ref.instance.actions = this.actions;
    ref.instance.select = this.select;
    ref.instance.perPage = this.perPage;
    ref.instance.delete = this.delete;
    ref.instance.create = this.create;
    ref.instance.populate = ['specialities'];
    ref.instance.hookEvents = this.hookEvents;
  }

}
