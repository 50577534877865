<table class="common size-trs">
  <!--HEADER-->
  <thead>
  <tr>
    <td>Qté</td>
    <td>Désignation E.F.</td>
    <td>Qté</td>
    <td>Désignation EQU.</td>
    <td>Prestation</td>
    <td>Marque</td>
    <td>Modèle</td>
    <td class="price">Coûts<br>(TTC)</td>
    <td style="text-align: center;width:20px" *ngIf="withDeleteButton"></td>
  </tr>
  </thead>
  <tbody>

    <!------ SET ----->
    <!-- MULTIPLE SET GROUPED IN ONE LINE -->
    <ng-container *ngFor="let group of groupSet">
      <tr style="font-weight: bold"
          class="selectable"
          [class.borderBottom]="!group.open"
          (click)="group.open = !group.open"
          *ngIf="group.quantity != 1">
        <td class="pointer">
          {{group.quantity}}
          <mat-icon *ngIf="group.open">
            expand_more
          </mat-icon>
          <mat-icon *ngIf="!group.open">
            expand_less
          </mat-icon>
        </td>
        <td>{{group.label}}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td class="price">{{group.computePriceTTC| chf}}</td>
        <td>
          <mat-icon class="z-index-on-top"
                    *ngIf="write"
                    (click)="deleteSetGroup.emit(group); stopPropagation($event)">
            delete
          </mat-icon>
        </td>
      </tr>

      <!-- ONE SET -->
      <ng-container *ngIf="group.open || group.quantity == 1">
        <ng-container *ngFor="let set of group.sets">
          <tr class="selectable"
              (click)="setSelection ? setSelected.emit(set) : ''">
            <td>
              1
            </td>
            <td>
              {{set.label}}
            </td>
            <td></td>
            <td class="selectable-equipment"
                (click)="set.open = !set.open; $event.stopPropagation()">
              <mat-icon *ngIf="set.open">
                expand_more
              </mat-icon>
              <mat-icon *ngIf="!set.open">
                expand_less
              </mat-icon>
              <p *ngIf="!set.open">
                Ouvrir équi.
              </p>
              <p *ngIf="set.open">
                Ranger équi
              </p>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td class="price">
              {{set.computePriceTTC| chf}}</td>
            <td *ngIf="withDeleteButton">
              <mat-icon class="z-index-on-top"
                        *ngIf="write"
                        (click)="deleteSet.emit(set); stopPropagation($event)">
                delete
              </mat-icon>
            </td>
          </tr>

          <!--EQUIPMENT INSIDE SET-->
          <ng-container *ngIf="set.open">
            <ng-container *ngFor="let group2 of set.groupEquipment; let i = index">
              <!--MULTIPLE EQUIPMENT LINE-->
              <tr style="font-weight: bold"
                  class="selectable"
                  (click)="group2.open = !group2.open"
                  *ngIf="group2.quantity != 1">
                <td></td>
                <td></td>
                <td class="pointer">
                  {{group2.quantity}}
                  <mat-icon *ngIf="group2.open">
                    expand_more
                  </mat-icon>
                  <mat-icon *ngIf="!group2.open">
                    expand_less
                  </mat-icon>
                </td>
                <td>{{group2.label}}</td>
                <td>{{group2.cneh}}</td>
                <td>{{group2.brand}}</td>
                <td>{{group2.model}}</td>
                <td class="price">{{group2.computePriceTTC| chf}}</td>
                <td>
                  <mat-icon class="z-index-on-top"
                            *ngIf="write"
                            (click)="deleteEquipmentGroup.emit(group2); stopPropagation($event)">
                    delete
                  </mat-icon>
                </td>
              </tr>
              <!--ONE QUIPMENT LINE-->
              <ng-container *ngIf="group2.open || group2.quantity == 1">
                <tr *ngFor="let equipment of group2.equipments"
                    class="selectable"
                    (click)="equipmentSelected.emit(equipment)">
                  <td></td>
                  <td></td>
                  <td>1</td>
                  <td>{{equipment.label}}</td>
                  <td>{{equipment.cneh}}</td>
                  <td>{{equipment.brand}}</td>
                  <td>{{equipment.model}}</td>
                  <td class="price">
                    {{equipment.computePriceTTC| chf}}</td>
                  <td *ngIf="withDeleteButton">
                    <mat-icon class="z-index-on-top"
                              *ngIf="write"
                              (click)="deleteEquipment.emit(equipment); stopPropagation($event)">
                      delete
                    </mat-icon>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>


    <tr *ngIf="groupEquipment.length">
      <td class="separation-line"
          colspan="8">
        Équipment sans ensemble fonctionnel
      </td>
    </tr>

    <!------ EQUIPMENTS STANDALONE (NOT IN SET)----->
    <!--GROUPE LINE-->
    <ng-container *ngFor="let group of groupEquipment">
      <tr style="font-weight: bold"
          class="selectable"
          [class.borderBottom]="!group.open"
          (click)="group.open = !group.open"
          *ngIf="group.quantity != 1">
        <td></td>
        <td></td>
        <td class="pointer">
          {{group.quantity}}
          <mat-icon *ngIf="group.open">
            expand_more
          </mat-icon>
          <mat-icon *ngIf="!group.open">
            expand_less
          </mat-icon>
        </td>
        <td>{{group.label}}</td>
        <td>{{group.cneh}}</td>
        <td>{{group.brand}}</td>
        <td>{{group.model}}</td>
        <td class="price">{{group.computePriceTTC| chf}}</td>
        <td *ngIf="withDeleteButton">
          <mat-icon class="z-index-on-top"
                    *ngIf="write"
                    (click)="deleteEquipmentGroup.emit(group); stopPropagation($event)">
            delete
          </mat-icon>
        </td>
      </tr>
      <!--inside group-->
      <ng-container *ngIf="group.open || group.quantity == 1">
        <tr *ngFor="let equipment of group.equipments"
            class="selectable"
            (click)="equipmentSelected.emit(equipment)">
          <td></td>
          <td></td>
          <td>1</td>
          <td>{{equipment.label}}</td>
          <td>{{equipment.cneh}}</td>
          <td>{{equipment.brand}}</td>
          <td>{{equipment.model}}</td>
          <td class="price">
            {{equipment.computePriceTTC| chf}}</td>
          <td *ngIf="withDeleteButton">
            <mat-icon class="z-index-on-top"
                      *ngIf="write"
                      (click)="deleteEquipment.emit(equipment); stopPropagation($event)">
              delete
            </mat-icon>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>
