import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {F, FileAdapter} from '../model/file.model';
import {Equipment} from '../model/equipment.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private apiService: ApiService,
              private adapter: FileAdapter) {

  }

  getFiles(route: string): Observable<F[]> {
    return this.apiService
      .get(route)
      .pipe(
        map(data => {
          return data.map(item => {
            return this.adapter.adapt(item);
          });
        })
      );
  }

  upload(file: FormData, route: string): Observable<F> {
    return this.apiService
      .upload(route, file).pipe(
        map(data => {
          return this.adapter.adapt(data);
        })
      )
  }

  delete(route: string, id: string): Observable<Equipment> {
    return this.apiService
      .deleteWithBody(route, {_id: id}, {});
  }
}
