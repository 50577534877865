import {ApiService} from "../api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ISyncOption} from '../../model/interface/syncOption.interface';
import {WithId} from '../../model/interface/withId.interface';

export interface IListParams {
  sort?: string;
  limit?: number;
  skip?: number;
  query?: string;
  existFilter?: string[];
  populate?: string;
}

// not injectable. Used as
export class ListGetterService<T> {

  public defaultParams: IListParams = {
    limit: 25,
    skip: 0,
    query: ""
  };
  path: string;

  constructor(path: string, protected api: ApiService) {
    this.path = path;
  }

  getAllList(params: IListParams = this.defaultParams, isLibrary: boolean, customPath?: string): Observable<{ total: number, data: T[] }> {
    const path = customPath ? customPath : (isLibrary ? "library/" + this.path : this.path);
    return this.api.getWithHeader(path, params)
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }

  getOneElementPrice(id: string): Observable<string> {
    return this.api.get("price/" + this.path + id)
  }

  bulkDelete(list: string[], isLibrary: boolean): Observable<any> {
    return this.api.bulkDelete(isLibrary? 'library/' + this.path : this.path, list)
  }

  bulkSync(options:ISyncOption): Observable<any> {
    return this.api.bulkSync(options)
  }

  giveDefaultParams() {
    return this.defaultParams;
  }
}
