import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {BehaviorSubject, Observable} from "rxjs";
import {Me, MeAdapter} from "../model/me.model";
import {map, tap} from "rxjs/operators";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {User} from "../model/user.model";
import {HomeModule} from "../../home.module";

@Injectable({
  providedIn: "root"
})
export class MeService implements Resolve<Me> {

  me = new Me();
  OnRightChanged: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private api: ApiService, private adapter: MeAdapter) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Me> | Promise<Me> | Me {
    return this.get();
  }

  initMe(): Promise<boolean> {
    return this.get()
      .toPromise()
      .then(res => {
        return true;
      });
  }

  get() {
    return this.api.get("me")
      .pipe(
        map(item => {
          return this.adapter.adapt(item);
        }),
        tap((e) => {
          this.me = e;
          this.OnRightChanged.next(this.me.rights);
        })
      );
  }

  put(me: Me): Observable<User> {
    return this.api.put("me", me).pipe(
      map(item => {
        return this.adapter.adapt(item);
      }),
      tap((e) => {
        this.me = e;
      })
    );
  }
}
