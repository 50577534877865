import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WithId} from './interface/withId.interface';

export class Version implements WithId {
  public _id:string;
  public label:string;
  public description:string;
  public createdAt:Date;

  static formBuilder(fb:FormBuilder, version:Version):FormGroup{
    return fb.group({
      label: [version.label, Validators.required]
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class VersionAdapter implements Adapter<Version> {
  adapt(item: any): Version {
    const d = new Version();
    d._id = item._id;
    d.label = item.label;
    d.description = item.description;
    d.createdAt = new Date(item.createdAt);
    return d;
  }
}
