import {Component, EventEmitter} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {Cneh} from "../../../../model/cneh.model";

@Component({
  selector: 'app-dialog-list-cneh',
  templateUrl: './dialog-list-cneh.component.html',
  styleUrls: ['./dialog-list-cneh.component.scss']
})
export class DialogListCnehComponent {

  select: EventEmitter<Cneh>;

  constructor(public dialogRef: MatDialogRef<DialogListCnehComponent>) {
    this.select = new EventEmitter<Cneh>();
  }

  close(c:Cneh) {
    this.dialogRef.close(c);
  }
}
