<h2 mat-dialog-title>Créer une nouvelle zone</h2>
<form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="mat-typography form">
    <mat-form-field>
      <input required matInput placeholder="{{'zone.name' | translate}}" formControlName="name">
    </mat-form-field>
    <mat-form-field>
    <input matInput placeholder="{{'zone.comment' | translate}}" formControlName="comment">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Annuler</button>
    <button [disabled]="!profileForm.valid" type="submit" color="primary" mat-flat-button >Créer</button>
  </mat-dialog-actions>
</form>
