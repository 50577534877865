import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from './user.model';
import {WithId} from './interface/withId.interface';

export class Project implements WithId {
  public _id:string;
  public title:string;
  public budget:number;
  public description:string;
  public beginAt:Date;
  public endAt:Date;
  public chiefUser:User;
  public users:User[];
  public state:string;
  public departement:string;
  public service:string;
  public unity:string;

  static formBuilder(fb:FormBuilder, project:Project):FormGroup{
    return fb.group({
      title: [project.title, Validators.required],
      budget: [project.budget],
      description: [project.description],
      beginAt: [project.beginAt],
      endAt: [project.endAt],
      departement: [project.departement],
      service: [project.service],
      unity: [project.unity]
    });
  }

  static formBuilderCreate(fb:FormBuilder, project:Project = new Project()):FormGroup{
    return fb.group({
      title: [project.title, Validators.required],
      description: [project.description],
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectAdapter implements Adapter<Project> {
  adapt(item: any): Project {
    const d = new Project();
    d._id = item._id;
    d.title = item.title;
    d.budget = item.budget;
    d.description = item.description;
    d.beginAt = item.beginAt;
    d.endAt = item.endAt;
    d.users = item.users;
    d.state = item.state;
    d.departement = item.departement;
    d.service = item.service;
    d.unity = item.unity;
    d.chiefUser = item.chiefUser;
    return d;
  }
}
