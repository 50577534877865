<div class="auth-page-container">
  <div class="auth-container-login">
    <!-- TITLE -->
    <div class="title-container">
      <h1>
        SIBVIEW
      </h1>
      <h3>
        version 1.6.0
      </h3>
    </div>
    <!-- INPUTS -->

    <form class="auth-fields-container"
          [formGroup]="loginForm"
          id="leform"
          (ngSubmit)="onSubmit()">
      <div class="one-input">
        <input type="text"
               placeholder="Identifiant"
               autocomplete="login"
               formControlName="login"
               class="form-control"
               [ngClass]="{ 'is-invalid': submitted && loginForm.controls.login.errors }"/>
      </div>

      <div class="one-input">
        <input type="password"
               autocomplete="password"
               placeholder="Mot de passe"
               formControlName="password"
               class="form-control password"
               [ngClass]="{ 'is-invalid': submitted && loginForm.controls.password.errors }"/>
      </div>
    </form>

    <!-- ACTION -->
    <div class="button-field-container">
      <button *ngIf="!error"
              type="submit"
              form="leform"
              [disabled]="loading"
              class="auth-login-button">
        Connexion
      </button>
      <button disabled
              *ngIf="error"
              class="auth-login-button auth-login-error">
        Identifiants incorrects.
      </button>
    </div>
  </div>
</div>
