import {Component, Inject} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {CnehService} from '../../../../services/cneh.service';
import {Cneh} from '../../../../model/cneh.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-cneh',
  templateUrl: './dialog-cneh.component.html',
  styleUrls: ['./dialog-cneh.component.scss']
})
export class DialogCnehComponent {

  profileForm;

  constructor(private service: CnehService,
              private dialogRef: MatDialogRef<DialogCnehComponent>,
              private fb:FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.profileForm = Cneh.formBuilder(this.fb, this.data.cneh);
  }

  onSubmit(){
    if (this.data.action == 'modify') {
      this.service.put(this.data.cneh._id, this.profileForm.value).subscribe((e) => {
        this.dialogRef.close(e);
      });
    }
    if (this.data.action == 'create') {
      this.service.post(this.profileForm.value).subscribe((e) => {
        this.dialogRef.close(e);
      });
    }
  }
}
