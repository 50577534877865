import {
  Component,
  ComponentFactoryResolver, ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {IFieldConfig, ListerComponent} from "../../../generics/lister/lister.component";
import {DynamicHostDirective} from "../../../../directives/dynamic-host.directive";
import {ListGetterService} from "../../../../services/generic/list-getter.service";
import {Tva} from "../../../../model/tva.model";
import {TvaService} from "../../../../services/tva.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-list-tva',
  templateUrl: './list-tva.component.html',
  styleUrls: ['./list-tva.component.scss']
})
export class ListTvaComponent implements OnInit {

  @Input()
  hookEvents: Subject<string>;

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Tva> = new EventEmitter();

  @Output()
  delete: EventEmitter<Tva> = new EventEmitter();

  @Output()
  create: EventEmitter<Tva> = new EventEmitter();

  component: any;
  fields: IFieldConfig[] = [
    {
      key: "typeTva",
      i18Key: "tva.typeTva",
      searchable: true,
      width: 50
    },
    {
      key: "value",
      i18Key: "tva.value",
      searchable: true,
      width: 50
    }
  ];

  @Input()
  actions: String[];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private tvaService: TvaService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef.createComponent(factory, undefined, Injector.create({
      providers: [
        {
          provide: ListGetterService,
          useValue: this.tvaService
        },
        {
          provide: "FIELD_CONFIG",
          useValue: this.fields
        }
      ],
      parent: this.injector
    }));
    ref.instance.actions = this.actions;
    ref.instance.select = this.select;
    ref.instance.delete = this.delete;
    ref.instance.create = this.create;
    ref.instance.hookEvents = this.hookEvents;
  }
}
