import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Job, JobAdapter} from '../model/job.model';
import {ListGetterService} from "./generic/list-getter.service";

@Injectable({
  providedIn: 'root'
})
export class JobService extends ListGetterService<Job> implements Resolve<Job[]> {

  constructor(protected api: ApiService, private adapter: JobAdapter) {
    super('job', api)
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Job[]> | Promise<Job[]> | Job[] {
    return this.getAll();
  }

  getAll(): Observable<Job[]> {
    return this.api.get('job').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Job> {
    return this.api.get('job/' + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id:string, job:Job): Observable<Job> {
    return this.api.put('job/' + id, job).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(job:Job): Observable<Job> {
    return this.api.post('job', job).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options): Observable<Job> {
    return this.api.delete('job/' + id, options);
  }
}
