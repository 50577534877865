import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ISyncOption} from '../model/interface/syncOption.interface';
import {WithId} from '../model/interface/withId.interface';


export interface Options {
  errorSnackBar?: boolean;
  successSnackBar?: boolean;
  title?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url = environment.url;

  constructor(public http: HttpClient, private snackBar: MatSnackBar, private injector: Injector) {
  }

  download(url) {
    this.http
      .get(environment.url + '/auth/tempToken')
      .pipe(
        catchError(this.handleError({}, '⚠️ ERREUR API - /'))
      )
      .subscribe((e: any) => {
        if (url.includes('?')) {
          window.open(this.url + url + '&tempToken=' + e.token);
        } else {
          window.open(this.url + url + '?tempToken=' + e.token);
        }
      });
  }

  openUrlWithTempToken(url): Observable<any> {
    return this.http
      .get(environment.url + '/auth/tempToken')
      .pipe(
        map((e: any) => {
          return this.url + url + '?tempToken=' + e.token
        }),
        catchError(this.handleError({}, '⚠️ ERREUR API - /'))
      );
  }

  get(url, params = {}): Observable<any> {
    return this.http
      .get(this.url + url, {params: params})
      .pipe(
        catchError(this.handleError({}, '⚠️ ERREUR API - /' + url))
      );
  }

  getWithHeader(url, params = {}): Observable<any> {
    return this.http
      .get(this.url + url, {params: params, observe: 'response'})
      .pipe(
        map(resp => {
          return {total: parseInt(resp.headers.get('x-total-count')), data: resp.body};
        }),
        catchError(this.handleError({}, '⚠️ ERREUR API - /' + url))
      );
  }

  put(url, data: any, options: Options = {}): Observable<any> {
    return this.http
      .put(this.url + url, data)
      .pipe(
        tap(this.handleSuccess(options, '✅️ Modification effectuée')),
        catchError(this.handleError(options, '⚠️ Erreur lors de la modification'))
      );
  }

  patch(url, data: any, options: Options = {}): Observable<any> {
    return this.http
      .patch(this.url + url, data)
      .pipe(
        tap(this.handleSuccess(options, '✅️ Modification effectuée')),
        catchError(this.handleError(options, '⚠️ Erreur lors de la modification'))
      );
  }

  post(url, data: any): Observable<any> {
    return this.http
      .post(this.url + url, data)
      .pipe(
        tap(this.handleSuccess()),
        catchError(this.handleError())
      );
  }

  upload(url, file: FormData, data?): Observable<any> {
    let path = this.url + url;
    if (data && Object.keys(data).length) {
      path += '?';
      Object.keys(data).forEach(key => {
        path+= `${key}=${data[key]}`
      })
    }
    return this.http
      .post(path, file)
      .pipe(
        tap(this.handleSuccess({successSnackBar: true}, '✅️ Import(s) effectué(s)')),
        catchError(this.handleError())
      );
  }

  delete(url, options: Options): Observable<any> {
    return this.http
      .delete(this.url + url)
      .pipe(
        tap(this.handleSuccess(options, '✅️ Suppression effectuée')),
        catchError(this.handleError(options, '⚠️ Erreur de la suppression'))
      );
  }

  deleteWithBody(url, body, options: Options): Observable<any> {
    return this.http
      .request('delete', this.url + url, {
        body: body
      })
      .pipe(
        tap(this.handleSuccess(options, '✅️ Suppression effectuée')),
        catchError(this.handleError(options, '⚠️ Erreur de la suppression'))
      );
  }

  bulkDelete(path: string, list: string[], options: Options = {}): Observable<any> {
    options.successSnackBar = true;
    return this.http
      .put(this.url + path + '/bulk-delete', list)
      .pipe(
        tap(this.handleSuccess(options, '✅️ Suppression(s) effectuée(s)')),
        catchError(this.handleError(options, '⚠️ Erreur lors de la modification'))
      );
  }

  bulkSync(options: ISyncOption): Observable<any> {
    return this.http
      .put(this.url + '/sync', {
        targets: options.targets.map(t => t._id),
        family: options.family,
        isLib: options.fromLib,
        source: options.source._id,
        codes: options.codes
      })
      .pipe(
        tap(this.handleSuccess({successSnackBar: true}, '✅️ Synchronisation(s) effectuée(s)')),
        catchError(this.handleError({successSnackBar: true}, '⚠️ Erreur lors de la synchronisation'))
      );
  }

  private handleSuccess(options: Options = {}, successMsg: string = '✅️ 200') {
    return _ => {
      if (options.successSnackBar) {
        this.openSnackBar(options, successMsg, 'success');
      }
    };
  }

  private handleError(options: Options = {}, errorMsg?: string) {
    return (err: HttpErrorResponse) => {
      // user feedback
      if (err.status === 409) {
        this.openSnackBar(options, '⚠️ Erreur: ' + err.error, 'error', 5000);
      } else {
        this.openSnackBar(options, '⚠️ Erreur production: logs envoyés à L\'administrateur.' + (errorMsg ? ` (${errorMsg})` : ''), 'error', 3000);
      }
      // manage error
      if (err.status !== 403) {
        return throwError(err);
      }
    };
  }

  private openSnackBar(options: Options, text, customClass: string = null, ms: number = 2000) {
    if (options.title) {
      text += ' - ' + options.title;
    }
    this.snackBar.open(text, null, {duration: ms, panelClass: customClass});
  }
}
