import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-rename',
  templateUrl: './dialog-rename.component.html',
  styleUrls: ['./dialog-rename.component.scss']
})
export class DialogRenameComponent {
  title;
  value: string = '';

  constructor(private dialogRef: MatDialogRef<DialogRenameComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any = {}) {
    this.title = data.i18nTitleKey || 'all.noTitle';
  }

  save() {
    this.dialogRef.close({value: this.value});
  }

  inputKeyDown($event: KeyboardEvent) {
    if ($event && $event.code === 'Enter') {
      if (this.value && this.value.length) {
        this.save();
      }
    }
  }
}
