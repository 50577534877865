<div class="card">
  <div class="card-header">
    <div>Zones</div>
    <div class="right"
         *ngIf="write">
      <button mat-flat-button
              color="primary"
              (click)="addZone()">Ajouter
      </button>
    </div>
  </div>
  <div class="card-content no-padding">
    <table mat-table
           matSort
           [dataSource]="dataSource"
           class="small">
      <ng-container matColumnDef="name">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>{{'zone.name' | translate}}</th>
        <td mat-cell
            *matCellDef="let u">{{u.name}}</td>
      </ng-container>
      <ng-container matColumnDef="comment">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>{{'zone.comment' | translate}}</th>
        <td mat-cell
            *matCellDef="let u">{{u.comment}}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell
            *matHeaderCellDef></th>
        <td mat-cell
            *matCellDef="let u">
          <mat-icon *ngIf="write"
                    (click)="edit(u)">search
          </mat-icon>
          <mat-icon *ngIf="write"
                    (click)="delete(u)">delete
          </mat-icon>
        </td>
      </ng-container>
      <tr mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: false"></tr>
      <tr mat-row
          *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <app-files [write]="write"
               [route]="(library?'library/':'') + 'room/'+room._id+'/zonesFiles'"
               [small]="true"
               [marginTop]="0"></app-files>
  </div>
</div>
