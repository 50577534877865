<h2 mat-dialog-title>
  Modifier une prestation
</h2>

<mat-dialog-content class="mat-typography form">
  <div class="col">
    <div class="card">
      <div class="card-header">
        Prestation
      </div>
      <div class="card-content">
        <div class="flex">
          <div class="col">
            <form [formGroup]="profileForm"
                  class="form">
              <!--LABEL-->
              <mat-form-field>
                <input matInput
                       required
                       placeholder="{{'prestation.label' | translate}}"
                       formControlName="label">
              </mat-form-field>

              <!--COMMENTAIRE-->
              <mat-form-field>
                <textarea matInput
                          placeholder="{{'prestation.comment' | translate}}"
                          formControlName="comment"></textarea>
              </mat-form-field>

              <!--FINANCE-->
              <mat-form-field *ngIf="!library">
                <mat-label>{{'equipment.finance' | translate}}</mat-label>
                <mat-select formControlName="finance"
                            [compareWith]="compareFinance">
                  <mat-option [value]="null"></mat-option>
                  <mat-option *ngFor="let finance of finances"
                              [value]="finance">{{finance.label}}
                    - {{finance.comment}}</mat-option>
                </mat-select>
              </mat-form-field>

              <!--TVA-->
              <mat-form-field>
                <mat-label>{{'equipment.tva' | translate}}</mat-label>
                <mat-select formControlName="tva"
                            [compareWith]="compareTva">
                  <mat-option *ngFor="let tva of tvas"
                              [value]="tva">{{tva.typeTva}}
                    - {{tva.value}}</mat-option>
                </mat-select>
              </mat-form-field>

              <!--PRICE-->
              <mat-form-field>
                <input matInput
                       type="number"
                       placeholder="{{'prestation.price' | translate}}"
                       formControlName="price">
                <span matSuffix>
                  CHF HT
                </span>
              </mat-form-field>
            </form>
          </div>
        </div>
      </div>
      <!--FOOTER-->
      <div class="card-footer">
        <button mat-flat-button
                color="primary"
                (click)="finish()"
                [disabled]="!profileForm.valid">
          Terminer
        </button>
      </div>
    </div>
  </div>
  <div class="col">
    <app-files [viewer]="true"
               [route]="(library? 'library/': '') + 'prestation/' + prestation._id + '/plans'"
               [small]="false"
               [marginTop]="0"
               [title]="'Image/Plan'">
    </app-files>
  </div>
</mat-dialog-content>
