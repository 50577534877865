import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {catchError, map, tap} from 'rxjs/operators';
// @ts-ignore
import {User, UserAdapter} from '../model/user.model';
import {Observable, throwError} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Project} from '../model/project.model';

@Injectable({
  providedIn: 'root'
})
export class UserService implements Resolve<User[]> {

  constructor(private api: ApiService, private adapter: UserAdapter) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User[]> | Promise<User[]> | User[] {
    return this.getAll();
  }

  getAll(): Observable<User[]> {
    return this.api.get('user').pipe(
      map(data => {
        data = data.filter(x => {
          return x.login != 'didierrobin';
        });
        return data.map(item => {
            return this.adapter.adapt(item);
        });
      })
    );
  }

  getAllArchived(): Observable<User[]> {
    return this.api.get('user/archived').pipe(
      map(data => {
        data = data.filter(x => {
          return x.login != 'didierrobin';
        });
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  getUsersByProject(project:Project): Observable<User[]> {
    return this.api.get('project/' + project._id + '/users').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<User> {
    return this.api.get('user/' + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id:string, user:User): Observable<User> {
    return this.api.put('user/' + id, user).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  activate(user:User): Observable<User> {
    return this.api.put('user/' + user._id + '/unarchived', {}).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(user:User): Observable<User> {
    return this.api.post('user', user).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  postInProject(project:Project, user:User): Observable<User> {
    return this.api.post('project/' + project._id + '/users', user).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options = {}): Observable<User> {
    return this.api.delete('user/' + id, options);
  }

  deleteInProject(project:Project, user:User, options: Options = {}): Observable<User> {
    return this.api.deleteWithBody('project/' + project._id + '/users', user, options);
  }
}
