import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {FormBuilder} from '@angular/forms';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {SpecialityService} from "../../../../services/speciality.service";
import {ConstraintService} from "../../../../services/constraint.service";
import {Constraint} from "../../../../model/constraint.model";
import {Speciality} from "../../../../model/speciality.model";

@Component({
  selector: 'app-dialog-constraint',
  templateUrl: './dialog-constraint.component.html',
  styleUrls: ['./dialog-constraint.component.scss']
})
export class DialogConstraintComponent {

  profileForm;
  fileRoute: string;

  specialities:Speciality[] = [];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  removable = true;
  selectable = true;

  constructor(private specialityService:SpecialityService, private service: ConstraintService, private dialogRef: MatDialogRef<DialogConstraintComponent>, private fb:FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data.constraint.specialities && this.data.constraint.specialities[0])
      this.data.constraint['specialitie'] = this.data.constraint.specialities[0];
    if (this.data.constraint.target == 'equipment')
      this.data.constraint.target = 'set';
    this.profileForm = Constraint.formBuilder(this.fb, this.data.constraint);
    this.fileRoute ='constraint/' + this.data.constraint._id + '/files';
    this.specialityService.getAll().subscribe((e) => {
      this.specialities = e;
    })
  }

  compare(a ,b){
    if (a && b && a.title == b.title)
      return true;
    return false;
  }

  compare2(a ,b){
    if (a && b && a.title == b.title)
      return true;
    return false;
  }

  onSubmit(){
    if (this.data.action == 'modify') {
      this.service.put(this.data.constraint._id, this.profileForm.value).subscribe((e) => {
        this.dialogRef.close(e);
      });
    }
    if (this.data.action == 'create') {
      this.service.post(this.profileForm.value).subscribe((e) => {
        this.dialogRef.close(e);
      });
    }
  }

  add(event): void {
    const input = event.input;
    let value = event.value;
    value = value.replace(/\./, ' ');
    if ((value || '').trim()) {
      if (!this.data.constraint.unities) {
        this.data.constraint.unities = [value.trim()];
      } else {
        this.data.constraint.unities.push(value.trim());
      }
    }

    if (input) {
      input.value = '';
    }
  }

  remove(fruit): void {
    const index = this.data.constraint.unities.indexOf(fruit);

    if (index >= 0) {
      this.data.constraint.unities.splice(index, 1);
    }
  }
}
