<h2 *ngIf="data.action == 'modify'" mat-dialog-title>Modifier {{data.tva.typeTva}}</h2>
<h2 *ngIf="data.action == 'create'" mat-dialog-title>Créer une nouvelle TVA</h2>
<form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="mat-typography form">
    <mat-form-field>
      <input required matInput placeholder="{{'tva.typeTva' | translate}}" formControlName="typeTva">
    </mat-form-field>
    <mat-form-field>
      <input required matInput placeholder="{{'tva.value' | translate}}" formControlName="value" type="number">
      <span matSuffix>%</span>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Annuler</button>
    <button [disabled]="!profileForm.valid" *ngIf="data.action == 'create'" type="submit" color="primary" mat-flat-button>Créer</button>
    <button [disabled]="!profileForm.valid" *ngIf="data.action == 'modify'" type="submit" color="primary" mat-flat-button>Modifier</button>
  </mat-dialog-actions>
</form>
