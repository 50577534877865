<h2 *ngIf="data.action == 'modify'"
    mat-dialog-title>Modifier {{data.constraint.title}}</h2>
<h2 *ngIf="data.action == 'create'"
    mat-dialog-title>Créer une nouvelle contrainte
</h2>
<form [formGroup]="profileForm"
      (ngSubmit)="onSubmit()"
      novalidate>
  <mat-dialog-content class="mat-typography form">
    <mat-form-field>
      <input required
             matInput
             placeholder="{{'constraint.title' | translate}}"
             formControlName="title">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'constraint.specialitie' | translate}}</mat-label>
      <mat-select required
                  formControlName="specialitie"
                  [compareWith]="compare2">
        <mat-option *ngFor="let speciality of specialities"
                    [value]="speciality">{{speciality.title}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'constraint.target' | translate}}</mat-label>
      <mat-select required
                  formControlName="target">
        <mat-option value="set">Equipement / Ensemble fonctionnel</mat-option>
        <mat-option value="room">Local</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'constraint.description' | translate}}</mat-label>
      <textarea placeholder="Description"
                matInput
                type="text"
                formControlName="description"></textarea>
    </mat-form-field>
    <mat-slide-toggle formControlName="isPhysic">{{'constraint.isPhysic' | translate}}</mat-slide-toggle>
    <mat-form-field class="example-chip-list">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let unity of data.constraint.unities"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(unity)">
          {{unity}}
          <mat-icon matChipRemove
                    *ngIf="removable">cancel
          </mat-icon>
        </mat-chip>
        <input placeholder="{{'constraint.unities' | translate}}"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
    </mat-form-field>
    <app-files *ngIf="data.action == 'modify'"
               [write]="true"
               [route]="fileRoute"
               [small]="true"
               [marginTop]="0"></app-files>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button
            mat-dialog-close>Annuler
    </button>
    <button [disabled]="!profileForm.valid"
            *ngIf="data.action == 'create'"
            type="submit"
            color="primary"
            mat-flat-button>Créer
    </button>
    <button [disabled]="!profileForm.valid"
            *ngIf="data.action == 'modify'"
            type="submit"
            color="primary"
            mat-flat-button>Modifier
    </button>
  </mat-dialog-actions>
</form>
