import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ImportService} from '../../../services/import.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialog-import',
  templateUrl: './dialog-import.component.html',
  styleUrls: ['./dialog-import.component.scss']
})
export class DialogImportComponent implements OnInit {

  comment: string = '';
  object = null;
  id = null;
  projectId = null;
  versionId = null;
  roomId = null;
  setId = null;
  name = '';

  constructor(private snackBar:MatSnackBar, private importService: ImportService, private dialogRef: MatDialogRef<DialogImportComponent>, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.object = this.data.object;
    this.id = this.data.id;
    this.projectId = this.data.projectId;
    this.versionId = this.data.versionId;
    this.roomId = this.data.roomId;
    this.setId = this.data.setId;
    this.name = this.data.name;
  }

  ngOnInit(): void {
  }

  import() {
    this.importService.post(this.comment, this.object, this.id, this.projectId, this.versionId, this.roomId, this.setId, this.name).subscribe(e => {
      this.snackBar.open('Demande d\'import dans la bibliothèque effectuée', null, {duration: 3000});
      this.dialogRef.close();
    });
  }

}
