// angular component list-project-with-equipment
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ProjectService} from '../../../services/project.service';
import {Project} from '../../../model/project.model';
import {MatStepper} from '@angular/material/stepper';
import {Version} from '../../../model/version.model';
import {Room} from '../../../model/room.model';
import {Equipment} from '../../../model/equipment.model';
import {RoomService} from '../../../services/room.service';

@Component({
  selector: 'app-select-equipment-stepper',
  templateUrl: './select-equipment-stepper.component.html',
  styleUrls: ['./select-equipment-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectEquipmentStepperComponent {

  public customVersionPath = '';
  public customRoomPath = '';
  public groupSet = [];
  public groupEquipment = [];

  @Output() equipmentSelected = new EventEmitter<Equipment>();

  @ViewChild('stepper', {static: true}) stepper: MatStepper;

  constructor(private cd: ChangeDetectorRef, private roomService: RoomService) {

  }

  selectProject(p: Project) {
    this.customVersionPath = 'project/' + p._id + '/versions';
    this.stepper.next();
    this.cd.detectChanges();
  }

  selectVersion(v: Version) {
    this.customRoomPath = 'version/' + v._id + '/rooms';
    this.stepper.next();
    this.cd.detectChanges();
  }

  selectRoom(r: Room) {
    this.roomService.getCustom(r)
      .subscribe(
        (res: any) => {
          this.groupSet = res.groupSet;
          this.groupEquipment = res.groupEquipment;
          this.stepper.next();
          this.cd.detectChanges();
        }
      );
  }

  selectEquipment(e: Equipment) {
    this.equipmentSelected.emit(e);
  }

  reSelectLocal() {
    this.groupEquipment = [];
    this.groupSet = [];
    this.customRoomPath = '';
    this.stepper.selectedIndex = 2;
    this.cd.detectChanges();
  }

  reSelectVersion() {
    this.customVersionPath = '';
    this.stepper.selectedIndex = 1;
    this.reSelectLocal();
  }

  reSelectProject() {
    this.stepper.selectedIndex = 0;
    this.reSelectVersion()
  }
}
