import {
  Component,
  ComponentFactoryResolver,
  ComponentRef, EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {DynamicHostDirective} from "../../../../directives/dynamic-host.directive";
import {ListGetterService} from "../../../../services/generic/list-getter.service";
import {CnehService} from "../../../../services/cneh.service";
import {IFieldConfig, ListerComponent} from "../../../generics/lister/lister.component";
import {Cneh} from "../../../../model/cneh.model";
import {Subject} from "rxjs";



@Component({
  selector: "app-list-cneh",
  templateUrl: "./list-cneh.component.html",
  styleUrls: ["./list-cneh.component.scss"]
})
export class ListCnehComponent implements OnInit {

  @Input()
  hookEvents: Subject<string>;

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Cneh> = new EventEmitter();

  @Output()
  delete: EventEmitter<Cneh> = new EventEmitter();

  @Output()
  create: EventEmitter<Cneh> = new EventEmitter();

  component: any;
  fields: IFieldConfig[] = [
    {
      key: "code2010",
      i18Key: "cneh.code2010",
      width: 10,
      searchable: true
    },
    {
      key: "family",
      i18Key: "cneh.family",
      width: 20,
      searchable: true
    },
    {
      key: "fct",
      i18Key: "cneh.fct",
      width: 25,
      searchable: true
    },
    {
      key: "eqpmt",
      i18Key: "cneh.eqpmt",
      width: 25,
      searchable: true
    },
    {
      key: "cpnt",
      i18Key: "cneh.cpnt",
      width: 10,
      searchable: true
    }
  ];

  @Input()
  actions: String[];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private cnehService: CnehService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef.createComponent(factory, undefined, Injector.create({
      providers: [
        {
          provide: ListGetterService,
          useValue: this.cnehService
        },
        {
          provide: "FIELD_CONFIG",
          useValue: this.fields
        }
      ],
      parent: this.injector
    }));
    ref.instance.queriables = ['cneh.code2010', "cneh.family", "cneh.fct", "cneh.eqpmt","cneh.cpnt"];
    ref.instance.actions = this.actions;
    ref.instance.select = this.select;
    ref.instance.delete = this.delete;
    ref.instance.create = this.create;
    ref.instance.hookEvents = this.hookEvents;
  }

}
