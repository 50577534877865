import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Room} from '../../../../model/room.model';
import {Set, SetAdapter} from '../../../../model/set.model';
import {RoomService} from '../../../../services/room.service';
import {FormBuilder} from '@angular/forms';
import {Project} from '../../../../model/project.model';
import {Version} from '../../../../model/version.model';
import {DialogImportComponent} from '../../../other/dialog-import/dialog-import.component';
import {DialogDeleteComponent} from '../../../atoms/dialog-delete/dialog-delete.component';
import {Subject} from 'rxjs';
import {Configuration} from '../../../../model/configuration.model';
import {DialogEditConfigurationComponent} from '../../configuration/dialog-edit-configuration/dialog-edit-configuration.component';
import {ConfigurationService} from '../../../../services/configuration.service';
import {Equipment, EquipmentAdapter} from '../../../../model/equipment.model';
import {DialogCreateComponent} from '../../../atoms/dialog-create/dialog-create.component';
import {EquipmentService} from '../../../../services/equipment.service';
import {SetService} from '../../../../services/set.service';
import {DialogListLibEquipmentComponent} from '../../lib-equipments/dialog-list-lib-equipment/dialog-list-lib-equipment.component';
import {DialogListEquipmentComponent} from '../../equipment/dialog-list-equipment/dialog-list-equipment.component';
import {DialogCloneComponent} from '../../../atoms/dialog-clone/dialog-clone.component';
import {DialogEditEquipmentComponent} from '../../equipment/dialog-edit-equipment/dialog-edit-equipment.component';
import {DialogListLibSetComponent} from '../../lib-sets/dialog-list-lib-set/dialog-list-lib-set.component';
import {DialogListSetComponent} from '../../set/dialog-list-set/dialog-list-set.component';
import {DialogEditSetComponent} from '../../set/dialog-edit-set/dialog-edit-set.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.component.html',
  styleUrls: ['./edit-room.component.scss']
})
export class EditRoomComponent implements OnInit, OnChanges {

  @Input() room: Room = null;
  @Input() project: Project;
  @Input() version: Version;
  @Input() library: boolean = false;
  @Input() write: boolean = false;
  @Output() changeRoom = new EventEmitter<Room>();
  @Output() onClose = new EventEmitter<void>();

  profileForm;

  private hookConfig: Subject<string> = new Subject();
  public adminActions = ['select', 'delete', 'create', 'search'];
  public userActions = ['select', 'search'];

  groupEquipment: any[] = [];
  groupSet: any[] = [];


  constructor(private dia: MatDialog,
              private fb: FormBuilder,
              private roomService: RoomService,
              private equipmentService: EquipmentService,
              private equipmentAdapter: EquipmentAdapter,
              private setAdapter: SetAdapter,
              private setService: SetService,
              private configService: ConfigurationService) {
  }

  ngOnInit() {
    this.refreshData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.room) {
      this.profileForm = Room.formBuilderParam(this.fb, changes.room.currentValue);
      this.room = changes.room.currentValue;
    }
  }

  import() {
    this.dia.open(DialogImportComponent, {
      data: {
        object: 'Room',
        id: this.room._id,
        projectId: this.project._id,
        versionId: this.version._id,
        roomId: null,
        setId: null,
        name: this.room.label
      }
    });
  }

  onSubmitParams() {
    if (this.library) {
      this.roomService.putLibrary(this.room._id, this.profileForm.value).subscribe((e: Room) => {
        this.room = e;
        this.changeRoom.emit(e);
      });
    } else {
      this.roomService.put(this.room._id, this.profileForm.value).subscribe((e: Room) => {
        this.room = e;
        this.changeRoom.emit(e);
      });
    }
  }

  // ==================================== configurations
  configSelected(config: Configuration) {
    this.dia.open(DialogEditConfigurationComponent, {
      width: '80vw',
      height: '60vh',
      data: {
        configuration: config
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.configService.put(config._id, result, (this.library ? 'library/' : ''))
            .subscribe((e) => {
              this.hookConfig.next('refresh');
            });
        }
      });
  }

  createConfig() {
    this.dia.open(DialogEditConfigurationComponent, {
      width: '80vw',
      height: '60vh',
      data: {
        target: 'Room'
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.configService.post('room/' + this.room._id, result, (this.library ? 'library/' : ''))
            .subscribe((e) => {
              this.hookConfig.next('refresh');
            });
        }
      });
  }

  // ==================================== Set & Equipments
  refreshData() {
    if (this.library) {
      this.roomService.getLibraryCustom(this.room)
        .subscribe((e) => {
          if (e) {
            this.groupEquipment = e.groupEquipment;
            this.groupSet = e.groupSet;
          }
        });
    } else {
      this.roomService.getCustom(this.room).subscribe((e) => {
        if (e) {
          this.groupEquipment = e.groupEquipment;
          this.groupSet = e.groupSet;
        }
      });
    }
  }

  // ==================================== Equipment
  createEquipment() {
    this.dia
      .open(DialogCreateComponent, {
        data: {
          withCneh: true,
          i18nTitleKey: this.library ? 'creation.libEquipment' : 'creation.equipment'
        }
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          this.equipmentService
            .postInRoom(this.room, this.equipmentAdapter.adapt({
              label: result.value,
              cneh: result.cneh
            }), this.library)
            .subscribe((equip: Equipment) => {
              this.equipmentSelected(equip);
            })
        }
      });
  }

  importEquipment(fromLib: boolean) {
    this.dia.open(
      fromLib ? DialogListLibEquipmentComponent : DialogListEquipmentComponent, {
        panelClass: 'dialog-full',
        data: {
          withProjectSelection: true
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          return this.dia.open(
            DialogCloneComponent, {
              panelClass: 'dialog-small',
              data: {
                title: 'import.equipmentQty',
                cloneObj: {
                  fromTarget: 'Equipment',
                  fromId: result._id,
                  toTarget: 'Room',
                  toId: this.room._id,
                  isFromLib: fromLib,
                  isToLib: this.library
                }
              }
            })
            .afterClosed()
            .subscribe((result: any) => {
              if (result === 'multiple') {
                this.refreshData();
              } else if (result) {
                this.equipmentSelected(result);
              }
            });
        }
      });
  }

  equipmentSelected(e: Equipment) {
    this.equipmentService
      .getInBoth(e._id, this.library)
      .subscribe((e) => {
        this.dia.open(DialogEditEquipmentComponent, {
          panelClass: 'dialog-full',
          data: {
            equipment: e,
            version: this.version,
            project: this.project,
            room: this.room,
            library: this.library,
            write: this.write
          }
        })
          .afterClosed()
          .subscribe((result) => {
            if (result === 'reload') {
              this.equipmentService.get(e._id)
                .subscribe(e2 => {
                  this.equipmentSelected(e2);
                })
            } else if (result) {
              this.equipmentService
                .putInBoth(e._id, result, this.library)
                .subscribe((e) => {
                  this.refreshData();
                });
            }
          });
      });
  }

  deleteEquipment(e: Equipment) {
    if (!this.library) {
      this.equipmentService.delete(e._id, {}).subscribe(() => {
        this.refreshData()
      });
    } else {
      this.equipmentService.deleteInLibrary(e._id, {}).subscribe(() => {
        this.refreshData()
      });
    }
  }

  deleteEquipmentGroup(group: any) {
    if (group && group.equipments) {
      this.dia.open(DialogDeleteComponent, {
        data: {
          title: 'Suppression de plusieurs équipments dans un local.',
          verif: group.equipments.length + ' équipments (' + group.label + ')'
        },
      })
        .afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            this.equipmentService.bulkDelete(group.equipments.map(e => e._id), this.library)
              .subscribe(() => {
                this.refreshData()
              })
          }
        })
    }
  }

  // ==================================== Set
  createSet() {
    this.dia
      .open(DialogCreateComponent, {
        data: {
          withCneh: true,
          i18nTitleKey: this.library ? 'creation.libSet' : 'creation.set'
        }
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result && result.value) {
          this.setService
            .postInRoom(this.room, this.setAdapter.adapt({
              label: result.value,
              cneh: result.cneh
            }), this.library)
            .subscribe((set: Set) => {
              this.setSelected(set);
            })
        }
      });
  }

  importSet(fromLib: boolean) {
    this.dia.open(
      fromLib ? DialogListLibSetComponent : DialogListSetComponent, {
        panelClass: 'dialog-full',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          return this.dia.open(
            DialogCloneComponent, {
              panelClass: 'dialog-small',
              data: {
                title: 'import.setQty',
                cloneObj: {
                  fromTarget: 'Set',
                  fromId: result._id,
                  toTarget: 'Room',
                  toId: this.room._id,
                  isFromLib: fromLib,
                  isToLib: this.library
                }
              }
            })
            .afterClosed()
            .subscribe((result: any) => {
              if (result === 'multiple') {
                this.refreshData();
              } else if (result) {
                this.setSelected(result);
              }
            });
        }
      });
  }

  setSelected(set: Set) {
    this.setService
      .getInBoth(set._id, this.library)
      .subscribe((set) => {
        this.dia.open(DialogEditSetComponent, {
          panelClass: 'dialog-full',
          data: {
            set: set,
            version: this.version,
            project: this.project,
            library: this.library,
            room: this.room,
            write: this.write
          }
        })
          .afterClosed()
          .subscribe((result) => {
            if (result === 'reload') {
              this.setService.get(set._id)
                .subscribe(set2 => {
                  this.setSelected(set2);
                })
            } else if (result) {
              this.setService
                .putInBoth(set._id, result, this.library)
                .subscribe((e) => {
                  this.refreshData();
                });
            }
          });
      });
  }

  deleteSet(s: Set) {
    if (!this.library) {
      this.setService.delete(s._id, {}).subscribe(() => {
        this.refreshData()
      });
    } else {
      this.setService.deleteInLibrary(s._id, {}).subscribe(() => {
        this.refreshData()
      });
    }
  }

  deleteSetGroup(group: any) {
    if (group && group.sets) {
      this.dia.open(DialogDeleteComponent, {
        data: {
          title: 'Suppression de plusieurs E.F. dans un local.',
          verif: group.sets.length + ' E.F. (' + group.label + ')'
        },
      })
        .afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            this.setService.bulkDelete(group.sets.map(s => s._id), this.library)
              .subscribe(() => {
                this.refreshData()
              })
          }
        })
    }
  }

}
