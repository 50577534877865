import {
  Component,
  ComponentFactoryResolver, ComponentRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild
} from "@angular/core";
import {Subject} from "rxjs";
import {Set} from "../../../../model/set.model";
import {IFieldConfig, ListerComponent} from "../../../generics/lister/lister.component";
import {DynamicHostDirective} from "../../../../directives/dynamic-host.directive";
import {SetService} from "../../../../services/set.service";
import {ListGetterService} from "../../../../services/generic/list-getter.service";

@Component({
  selector: 'app-list-set',
  templateUrl: './list-set.component.html',
  styleUrls: ['./list-set.component.scss']
})
export class ListSetComponent {
  @Input()
  hookEvents: Subject<string>;

  @Input()
  actions: String[];

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Set> = new EventEmitter();

  @Output()
  delete: EventEmitter<Set> = new EventEmitter();

  @Output()
  create: EventEmitter<Set> = new EventEmitter();

  @Output()
  import: EventEmitter<void> = new EventEmitter();

  component: any;
  fields: IFieldConfig[] = [
    {
      key: "label",
      i18Key: "set.label",
      width: 30,
      searchable: true
    },
    {
      key: "comment",
      i18Key: "set.comment",
      width: 55,
      searchable: true,
    },
    {
      key: "price",
      i18Key: "common.price",
      width: 15,
      searchable: false,
      serviceFunctionName: 'getOneElementPrice'
    },
  ];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private setService: SetService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef
      .createComponent(factory, undefined, Injector.create({
        providers: [
          {
            provide: ListGetterService,
            useValue: this.setService
          },
          {
            provide: "FIELD_CONFIG",
            useValue: this.fields
          }
        ],
        parent: this.injector
      }));
    ref.instance.queriables = ["set.label"];
    ref.instance.actions = this.actions;
    ref.instance.select = this.select;
    ref.instance.delete = this.delete;
    ref.instance.create = this.create;
    ref.instance.import = this.import;
    ref.instance.hookEvents = this.hookEvents;
  }

}
