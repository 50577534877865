import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Speciality} from './speciality.model';
import {WithId} from './interface/withId.interface';

export class Constraint implements WithId {
  public _id:string;
  public title:string;
  public specialities:Speciality[] = [];
  public specialitie:Speciality;
  public isPhysic:boolean;
  public unities:string[] = [];
  public description:string;
  public target:string;

  static formBuilder(fb:FormBuilder, constraint:Constraint):FormGroup{
    return fb.group({
      title: [constraint.title, Validators.required],
      specialitie: [constraint.specialitie, Validators.required],
      isPhysic: [constraint.isPhysic],
      unities: [constraint.unities],
      description: [constraint.description],
      target: [constraint.target, Validators.required]
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConstraintAdapter implements Adapter<Constraint> {
  adapt(item: any): Constraint {
    const d = new Constraint();
    d._id = item._id;
    d.title = item.title;
    d.specialities = item.specialities || [];
    d.specialitie = d.specialities[0] || null;
    d.isPhysic = item.isPhysic;
    d.unities = item.unities;
    d.description = item.description;
    d.target = item.target;
    return d;
  }
}
