import {Injectable} from "@angular/core";
import {ApiService, Options} from "./api.service";
import {map} from "rxjs/operators";
// @ts-ignore
import {Observable} from "rxjs";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Constraint, ConstraintAdapter} from "../model/constraint.model";
import {ListGetterService} from "./generic/list-getter.service";

@Injectable({
  providedIn: "root"
})
export class ConstraintService extends ListGetterService<Constraint> implements Resolve<Constraint[]> {

  constructor(protected api: ApiService, private adapter: ConstraintAdapter) {
    super("constraint", api)
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Constraint[]> | Promise<Constraint[]> | Constraint[] {
    return this.getAll();
  }

  getAll(): Observable<Constraint[]> {
    return this.api.get("constraint?populate=specialities").pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Constraint> {
    return this.api.get("constraint/" + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id: string, constraint: Constraint): Observable<Constraint> {
    constraint.specialities = [];
    constraint.specialities[0] = constraint.specialitie;
    if (!constraint.unities) {
      constraint.unities = []
    }
    return this.api.put("constraint/" + id + "?populate=specialities", constraint).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(constraint: Constraint): Observable<Constraint> {
    constraint.specialities = [];
    constraint.specialities[0] = constraint.specialitie;
    if (!constraint.unities) {
      constraint.unities = []
    }
    return this.api.post("constraint?populate=specialities", constraint).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options): Observable<Constraint> {
    return this.api.delete("constraint/" + id, options);
  }
}
