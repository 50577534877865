import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {MeService} from './me.service';

@Injectable({
  providedIn: 'root'
})
export class OptionService {

  theme: any = {
    sideNavSmall: false
  };

  rights: any = {
    project: false,
    library: false,
    admin: false
  };

  OnRightChanged: Subscription = null;

  constructor(private me: MeService) {
    this.OnRightChanged = this.me.OnRightChanged.subscribe(rights => {
      this.rights = rights;
    });
  }
}
