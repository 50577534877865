import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-fence.component.html',
  styleUrls: ['./dialog-fence.component.scss']
})
export class DialogFenceComponent {

  title = 'Confirmation nécessaire';
  text = null;
  //text user has to type in to validate
  validationText = 'SUPPR';
  verif = null;
  verifInput = '';
  undo = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.undo)
      this.undo = true;
    if (data) {
      if (data.validationText) {
        this.validationText = data.validationText
      }
      if (data.title) {
        this.title = data.title;
      }
      if (data.text) {
        this.text = data.text;
      }
      if (data.verif) {
        this.verif = data.verif
          .toLowerCase()
          .trim()
          .replace(/ /g, '-') ;
      }
    }
  }
}
