import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import {Constraint} from "../../../../model/constraint.model";
import {Configuration} from "../../../../model/configuration.model";
import {FileService} from "../../../../services/file.service";
import {ApiService} from "../../../../services/api.service";
import {ConstraintService} from "../../../../services/constraint.service";
import {FormBuilder} from "@angular/forms";
import {delay} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatStepper} from "@angular/material/stepper";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-dialog-edit-configuration",
  templateUrl: "./dialog-edit-configuration.component.html",
  styleUrls: ["./dialog-edit-configuration.component.scss"]
})
export class DialogEditConfigurationComponent{

  profileForm;
  profileFormValues;

  constraints: Constraint[] = [];
  displayedColumns: string[] = ["title", "target", "specialitie", "isPhysic", "unities", "description", "pj"];
  dataSource: MatTableDataSource<Constraint>;

  constraint: Constraint = null;
  configuration: Configuration = new Configuration();

  edit = false;

  @ViewChild("constraintTablePaginator", {static: true}) paginator: MatPaginator;
  @ViewChild("constraintTableSort", {static: true}) sort: MatSort;
  @ViewChild("stepper", {static: true}) private stepper: MatStepper;

  constructor(private snackBar: MatSnackBar,
              private fileService: FileService,
              private apiService: ApiService,
              private constraintService: ConstraintService,
              private dialogRef: MatDialogRef<DialogEditConfigurationComponent>,
              private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data.configuration) {
      this.edit = true;
      this.configuration = this.data.configuration;
      this.profileForm = Configuration.formBuilder(this.fb, this.configuration);
      this.profileFormValues = Configuration.FormBuilderValues(this.fb, this.configuration.values);
    } else {
      this.profileForm = Configuration.formBuilder(this.fb, new Configuration());
      this.profileFormValues = Configuration.FormBuilderValues(this.fb, {});
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constraintSelected(constraint: Constraint) {
      this.constraint = constraint;
      this.configuration = new Configuration();
      this.configuration.constraintLabel = constraint.title;
      this.configuration.constraintSpecialityLabel = constraint.specialities[0].title || "";
      this.configuration.isPhysic = constraint.isPhysic;
      this.configuration.values = {};
      this.configuration.quantity = 1;
      this.constraint.unities.forEach((e) => {
        this.configuration.values[e] = "";
      });
      this.profileForm = Configuration.formBuilder(this.fb, this.configuration);
      this.profileFormValues = Configuration.FormBuilderValues(this.fb, this.configuration.values);
      this.stepper.next();
  }

  contraintUnselected() {
    this.constraint = null;
    this.stepper.previous();
  }

  finish() {
    this.configuration.comments = this.profileForm.value.comments;
    this.configuration.quantity = this.profileForm.value.quantity;
    for (let a in this.profileFormValues.value) {
      this.configuration.values[a] = this.profileFormValues.value[a];
    }
    this.dialogRef.close(this.configuration);
  }

  getKeys(obj) {
    return Object.keys(obj)
  }

  onSubmit() {
  }

  // pj(u) {
  //   this.fileService
  //     .getFiles(u._id, "Constraint", "PJ")
  //     .pipe(delay(0))
  //     .subscribe((e) => {
  //       if (e && e[0])
  //         this.apiService.download("file/" + e[0]._id);
  //       else
  //         this.snackBar.open("Aucune PJ trouvée", null, {duration: 1000});
  //     });
  // }
}
