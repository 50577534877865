<h2 mat-dialog-title>
  {{title | translate}}
</h2>

<mat-dialog-content class="mat-typography form">
  <mat-form-field>
    <input matInput
           (keydown)="inputKeyDown($event)"
           [(ngModel)]="value"
           required>
  </mat-form-field>

  <ng-container *ngIf="withCneh">
    <mat-form-field (click)="openCneh()"
                    class="pointer">
      <input matInput
             placeholder="{{'equipment.cneh' | translate}}"
             readonly="true"
             [value]="cneh?cneh.code2010:''">
    </mat-form-field>
  </ng-container>
</mat-dialog-content>

<!-- CONTROLS-->
<mat-dialog-actions align="end">
  <button mat-flat-button
          mat-dialog-close>
    Annuler
  </button>
  <button (click)="save()"
          color="primary"
          mat-flat-button>
    Créer
  </button>
</mat-dialog-actions>
