<div class="card"
     [style.margin-top.px]="marginTop"
     *ngIf="!small">
  <div class="card-header">{{title}}</div>
  <div class="card-content no-padding">
    <div *ngIf="viewer && select"
         class="viewer"
         [style.backgroundImage]="'url(' + select + ')'"></div>
    <table mat-table
           matSort
           [dataSource]="dataSource"
           class="small">
      <ng-container matColumnDef="name">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Nom
        </th>
        <td mat-cell
            [tippy]="template"
            placement="auto"
            [style.cursor]="'pointer'"
            (click)="download(u)"
            *matCellDef="let u">
          <!-- preview image template-->
          <ng-template #template
                       let-hide>
            <ng-container *ngIf="u.isImage">
              <img [style.boxShadow]="'0px 0px 2px 2px rgb(0 0 0 / 12%)'"
                   [style.maxWidth]="'600px'"
                   [style.maxHeight]="'300px'"
                   src="{{giveUrl(u) | async}}">
            </ng-container>
          </ng-template>
          <div>
            {{u.name}}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Date
        </th>
        <td mat-cell
            [style.cursor]="'pointer'"
            (click)="download(u)"
            *matCellDef="let u">{{u.createdAt | date:'dd.LL.yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell
            *matHeaderCellDef></th>
        <td mat-cell
            *matCellDef="let u">
          <mat-icon *ngIf="write"
                    (click)="delete(u)">delete
          </mat-icon>
        </td>
      </ng-container>
      <tr mat-row
          *matRowDef="let row; columns: displayedColumns;"
          (click)="selected(row)"
          [class.pointer]="viewer"></tr>
    </table>
    <ngx-file-drop *ngIf="write"
                   dropZoneClassName="drop"
                   [showBrowseBtn]="true"
                   [browseBtnClassName]="'file-import-button'"
                   [browseBtnLabel]="'Cliquez ou glissez'"
                   (onFileDrop)="dropped($event)"
                   dropZoneLabel="">
      <ng-container *ngIf="stat.total > stat.finish">
        <ng-template ngx-file-drop-content-tmp>
          <span *ngIf="stat.total > stat.finish">{{stat.finish}} / {{stat.total}}</span>
        </ng-template>
      </ng-container>
    </ngx-file-drop>
  </div>
</div>
<span [style.margin-top.px]="marginTop"
      *ngIf="small">
  <div class="flex"
       *ngIf="!(!write && dataSource.data.length == 0)">
  <div class="card">
    <div class="card-content no-padding">
      <table mat-table
             matSort
             [dataSource]="dataSource"
             class="small">
      <ng-container matColumnDef="name">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Nom</th>
        <td mat-cell
            [tippy]="template2"
            placement="auto"
            [style.cursor]="'pointer'"
            (click)="download(u)"
            *matCellDef="let u">
          <!-- preview image template-->
          <ng-template #template2
                       let-hide>
            <ng-container *ngIf="u.isImage">
              <img [style.boxShadow]="'0px 0px 2px 2px rgb(0 0 0 / 12%)'"
                   [style.maxWidth]="'600px'"
                   [style.maxHeight]="'300px'"
                   src="{{giveUrl(u) | async}}">
            </ng-container>
          </ng-template>
          <div>
            {{u.name}}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell
            *matHeaderCellDef></th>
        <td mat-cell
            [style.cursor]="'pointer'"
            (click)="download(u)"
            *matCellDef="let u">
          <mat-icon *ngIf="write"
                    (click)="delete(u)">delete</mat-icon>
        </td>
      </ng-container>
      <tr mat-row
          *matRowDef="let row; columns: displayedColumnsSmall;"></tr>
    </table>
    <div style="flex:0 100px"
         *ngIf="write">
      <ngx-file-drop dropZoneClassName="drop"
                     (onFileDrop)="dropped($event)"
                     [showBrowseBtn]="true"
                     [browseBtnClassName]="'file-import-button'"
                     [browseBtnLabel]="'Cliquez ou glissez'"
                     dropZoneLabel="">
        <ng-container *ngIf="stat.total > stat.finish">
          <ng-template ngx-file-drop-content-tmp>
            <span *ngIf="stat.total > stat.finish">{{stat.finish}} / {{stat.total}}</span>
          </ng-template>
        </ng-container>
    </ngx-file-drop>
    </div>
    </div>
  </div>
  </div>
</span>
