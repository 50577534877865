import {Component, EventEmitter} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {Set} from '../../../../model/set.model'

@Component({
  selector: 'app-dialog-list-lib-set',
  templateUrl: './dialog-list-lib-set.component.html',
  styleUrls: ['./dialog-list-lib-set.component.scss']
})
export class DialogListLibSetComponent {

  select: EventEmitter<Set>;

  constructor(public dialogRef: MatDialogRef<DialogListLibSetComponent>) {
    this.select = new EventEmitter<Set>();
  }

  close(s:Set) {
    this.dialogRef.close(s);
  }

}
