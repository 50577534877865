import {Injectable} from "@angular/core";
import {ApiService} from "../../api.service";
import {IListParams, ListGetterService} from "../../generic/list-getter.service";
import {Observable} from "rxjs";
import {Set} from "../../../model/set.model"
import {map} from "rxjs/operators";


@Injectable({
  providedIn: "root"
})
export class LibSetService extends ListGetterService<Set> {

  constructor(protected api: ApiService) {
    super("library/set/", api);
  }

  //overrite super fonction because get route is a custom one
  getAllList(params: IListParams = super.giveDefaultParams()): Observable<{ total: number, data: Set[] }> {
    return this.api.getWithHeader("library/set", params)
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }
}
