<div class="card no-radius">
  <!-- HEADER -->
  <div class="card-header">
    <div class="right">
      <mat-form-field class="no-margin">
        <mat-select [(ngModel)]="filter"
                    (ngModelChange)="filter2()">
          <mat-option [value]="'pending'">En attente</mat-option>
          <mat-option [value]="'accepted'">Accepté</mat-option>
          <mat-option [value]="'refused'">Refusé</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- CONTENT -->
  <div class="card-content no-padding">
    <table #table
           #tableSort="matSort"
           mat-table
           matSort
           [dataSource]="dataSource"
           class="small">

      <!--TYPE-->
      <ng-container matColumnDef="type">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
          Type
        </th>
        <td mat-cell
            *matCellDef="let u">{{'object.' + u.json.object | translate}}</td>
      </ng-container>

      <!--LABEL-->
      <ng-container matColumnDef="name">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
          Désignation
        </th>
        <td mat-cell
            *matCellDef="let u">{{u.json.name}}</td>
      </ng-container>

      <!--COMMENT-->
      <ng-container matColumnDef="comment">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
          Commentaire
        </th>
        <td mat-cell
            *matCellDef="let u">{{u.comment}}</td>
      </ng-container>

      <!--STATE-->
      <ng-container matColumnDef="state">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
          État
        </th>
        <td mat-cell
            *matCellDef="let u">{{'state.' + u.state | translate}}</td>
      </ng-container>

      <!-- ACTIONS -->
      <ng-container matColumnDef="action">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header></th>
        <td mat-cell
            *matCellDef="let u">
          <mat-icon (click)="view(u)">search</mat-icon>
          <mat-icon *ngIf="u.state == 'pending'"
                    (click)="done(u)">done
          </mat-icon>
          <mat-icon *ngIf="u.state == 'pending'"
                    (click)="clear(u)">clear
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: false"></tr>
      <tr mat-row
          *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #tablePaginator="matPaginator"
                   [pageSize]="50"
                   [pageSizeOptions]="[5, 10, 25, 50, 100]"
                   showFirstLastButtons></mat-paginator>
  </div>
</div>
