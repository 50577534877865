// angular component list-projects
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {IFieldConfig, ListerComponent} from '../../../generics/lister/lister.component';
import {DynamicHostDirective} from '../../../../directives/dynamic-host.directive';
import {RoomService} from '../../../../services/room.service';
import {ListGetterService} from '../../../../services/generic/list-getter.service';
import {Project} from '../../../../model/project.model';
import {ProjectService} from '../../../../services/project.service';

@Component({
  selector: 'app-list-project',
  templateUrl: './list-project.component.html',
  styleUrls: ['./list-project.component.scss']
})
export class ListProjectComponent implements OnInit {
  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Project> = new EventEmitter();

  component: any;
  fields: IFieldConfig[] = [
    {
      key: "title",
      i18Key: "project.title",
      width: 50,
      searchable: true
    },
    {
      key: "Description",
      i18Key: "project.description",
      width: 50,
    },
  ];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private projectService: ProjectService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef
      .createComponent(factory, undefined, Injector.create({
        providers: [
          {
            provide: ListGetterService,
            useValue: this.projectService
          },
          {
            provide: "FIELD_CONFIG",
            useValue: this.fields
          }
        ],
        parent: this.injector
      }));
    ref.instance.actions = ['select'];
    ref.instance.select = this.select;
  }
}
