import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Prestation, PrestationAdapter} from '../model/prestation.model';
import {Set} from '../model/set.model';
import {ListGetterService} from "./generic/list-getter.service";

@Injectable({
  providedIn: 'root'
})
export class PrestationService extends ListGetterService<Prestation> implements Resolve<Prestation[]> {

  constructor(protected api: ApiService, private adapter: PrestationAdapter) {
    super('prestation/', api)
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Prestation[]> | Promise<Prestation[]> | Prestation[] {
    return null;
  }

  getAllBySet(set: Set): Observable<Prestation[]> {
    return this.api.get('set/' + set._id + '/prestations?populate=tva,finance').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  getAllBySetLibrary(set: Set): Observable<Prestation[]> {
    return this.api.get('library/set/' + set._id + '/prestations?populate=tva').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Prestation> {
    return this.api.get('prestation/' + id + '?populate=tva,finance').pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id: string, prestation: Prestation, library: boolean = false): Observable<Prestation> {
    return this.api.put((library?'library/':'') + 'prestation/' + id + '?populate=tva' + (library?'':',finance'), prestation).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  putLibrary(id: string, prestation: Prestation): Observable<Prestation> {
    return this.api.put('library/prestation/' + id + '?populate=tva', prestation).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  postInSet(set: Set, prestation: Prestation): Observable<Prestation> {
    return this.api.post( 'set/' + set._id + '/prestations?populate=tva,finance', prestation).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  postInSetLibrary(set: Set, prestation: Prestation): Observable<Prestation> {
    return this.api.post( 'library/set/' + set._id + '/prestations?populate=tva', prestation).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(prestation: Prestation): Observable<Prestation> {
    return this.api.post('prestation?populate=tva,finance', prestation).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options, prefix:string = ''): Observable<Prestation> {
    return this.api.delete(prefix + 'prestation/' + id, options);
  }
}
