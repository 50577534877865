import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup } from '@angular/forms';
import {Equipment} from './equipment.model';
import {Configuration} from './configuration.model';
import {Prestation} from './prestation.model';
import {WithId} from './interface/withId.interface';
import {IFamily} from './interface/ifamily.interface';
import {EFamily} from './enum/family.enum';

export class Set implements IFamily {
  public _id: string;
  public label: string;
  public family = EFamily.Set;
  public cneh: string;
  public comment: string;
  public zones:string;
  public standalone: boolean;
  public prestations:Prestation[] = [];
  public equipments:Equipment[] = [];
  public configurations: Configuration[] = [];
  public inMarket: Date;
  public outMarket: Date;
  public cac: Boolean;

  static formBuilder(fb: FormBuilder, set: Set): FormGroup {
    return fb.group({
      label: [set.label],
      cneh: [{value: set.cneh, disabled: true}],
      comment: [set.comment],
      zones: [set.zones],
      inMarket: [set.inMarket],
      outMarket: [set.outMarket],
      cac: [set.cac]
    });
  }

  static formBuilderLibrary(fb: FormBuilder, set: Set): FormGroup {
    return fb.group({
      label: [set.label],
      cneh: [{value: set.cneh, disabled: true}],
      comment: [set.comment],
      inMarket: [set.inMarket],
      outMarket: [set.outMarket],
      cac: [set.cac]
    });
  }

  static formBuilderCreate(fb: FormBuilder, set: Set): FormGroup {
    return fb.group({
      label: [set.label]
    });
  }
}

@Injectable({
  providedIn: 'root'
})

export class SetAdapter implements Adapter<Set> {
  adapt(item: any): Set {
    const d = new Set();
    d._id = item._id;
    d.label = item.label;
    d.cneh = item.cneh;
    d.standalone = item.standalone;
    d.comment = item.comment;
    d.zones = item.zones;
    d.equipments = item.equipments;
    d.prestations = item.prestations;
    d.inMarket = item.inMarket;
    d.outMarket = item.outMarket;
    d.cac = item.cac;
    return d;
  }
}
