import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WithId} from './interface/withId.interface';



export class User implements WithId {

  public _id;
  public deleted;
  public firstName;
  public lastName;
  public login;
  public mail;
  public createdAt;
  public level;
  public rights = {
    admin: false,
    library: false,
    project: false
  };

  static formBuilderModify(fb:FormBuilder, user:User): FormGroup{
    return fb.group({
      firstName: [user.firstName, Validators.required],
      lastName: [user.lastName, Validators.required],
      mail: [user.mail],
      level: [user.level, Validators.required]
    });
  }

  static formBuilderModifyPassword(fb:FormBuilder, user:User): FormGroup{
    return fb.group({
      password: ['', Validators.required],
      password2: ['', Validators.required],
    });
  }

  static formBuilderCreate(fb:FormBuilder, user:User):FormGroup{
    return fb.group({
      login: [user.login, Validators.required],
      firstName: [user.firstName, Validators.required],
      lastName: [user.lastName, Validators.required],
      password: ['', Validators.required],
      password2: ['', Validators.required],
      mail: [user.mail],
      level: [user.level, Validators.required]
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {

  static getRights(level: string): any {
    if (level === 'admin') {
      return {
        admin: true,
        library: true,
        project: true
      };
    }
    if (level === 'user') {
      return {
        admin: false,
        library: false,
        project: true
      };
    }
    return {
      admin: true,
      library: true,
      project: true
    };
  }

  adapt(item: any): User {
    const d = new User();
    d._id = item._id;
    d.firstName = item.firstName;
    d.lastName = item.lastName;
    d.login = item.login;
    d.mail = item.mail;
    d.createdAt = new Date(item.createdAt);
    d.deleted = item.deleted;
    d.rights = UserAdapter.getRights(item.level);
    d.level = item.level;
    return d;
  }
}
