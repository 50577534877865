import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {Version, VersionAdapter} from '../model/version.model';
import {Project} from '../model/project.model';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ListGetterService} from './generic/list-getter.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService extends ListGetterService<Version> implements Resolve<Version>{

  constructor(protected api: ApiService, private adapter: VersionAdapter) {
    super('/versions', api);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Version> | Promise<Version> | Version {
    if (route.params.idVersion){
      return this.get(route.params.idVersion);
    }
    return null;
  }

  getAll(project:Project): Observable<Version[]> {
    return this.api.get('project/' + project._id + '/versions').pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Version> {
    return this.api.get('version/' + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  clone(idProject:string, idVersion:string, newName:string): Observable<Version> {
    return this.api.post('project/' + idProject + '/versions/clone', {_id: idVersion, newLabel:newName}).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id:string, version:Version): Observable<Version> {
    return this.api.put('version/' + id, version).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(project:Project, version:Version): Observable<Version> {
    return this.api.post('project/' + project._id + '/versions', version).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options): Observable<Version> {
    return this.api.delete('version/' + id, options);
  }
}
