import {
  Component,
  ComponentFactoryResolver, ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {Subject} from "rxjs";
import {Cneh} from "../../../../model/cneh.model";
import {IFieldConfig, ListerComponent} from "../../../generics/lister/lister.component";
import {DynamicHostDirective} from "../../../../directives/dynamic-host.directive";
import {ListGetterService} from "../../../../services/generic/list-getter.service";
import {JobService} from "../../../../services/job.service";

@Component({
  selector: 'app-list-job',
  templateUrl: './list-job.component.html',
  styleUrls: ['./list-job.component.scss']
})
export class ListJobComponent implements OnInit {

  @Input()
  hookEvents: Subject<string>;

  @Input()
  actions: String[];

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Cneh> = new EventEmitter();

  @Output()
  delete: EventEmitter<Cneh> = new EventEmitter();

  @Output()
  create: EventEmitter<Cneh> = new EventEmitter();

  component: any;
  fields: IFieldConfig[] = [
    {
      key: "title",
      i18Key: "job.title",
      width: 35,
      searchable: true
    },
    {
      key: "description",
      i18Key: "job.description",
      width: 65,
      searchable: true
    },
  ];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private jobService: JobService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef.createComponent(factory, undefined, Injector.create({
      providers: [
        {
          provide: ListGetterService,
          useValue: this.jobService
        },
        {
          provide: "FIELD_CONFIG",
          useValue: this.fields
        }
      ],
      parent: this.injector
    }));
    ref.instance.actions = this.actions;
    ref.instance.select = this.select;
    ref.instance.delete = this.delete;
    ref.instance.create = this.create;
    ref.instance.hookEvents = this.hookEvents;
  }
}
