import {Injectable} from "@angular/core";
import {ApiService, Options} from "./api.service";
import {map} from "rxjs/operators";
// @ts-ignore
import {Observable} from "rxjs";
import {Equipment, EquipmentAdapter} from "../model/equipment.model";
import {Project} from "../model/project.model";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Room} from "../model/room.model";
import {Set} from "../model/set.model";
import {Version} from "../model/version.model";
import {IListParams, ListGetterService} from './generic/list-getter.service';


@Injectable({
  providedIn: "root"
})
export class EquipmentService extends ListGetterService<Equipment> implements Resolve<Equipment> {

  constructor(protected api: ApiService, private adapter: EquipmentAdapter) {
    super("equipment/", api)
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Equipment> | Promise<Equipment> | Equipment {
    return null;
  }

  getAll(prefix: string = "", params = {}): Observable<any> {
    return this.api.getWithHeader(prefix + "equipment?populate=cneh", params).pipe(
      map(resp => {
        resp.data.map(item => {
          return this.adapter.adapt(item);
        });
        return resp;
      })
    );
  }

  getAllByRoom(room: Room, prefix: string = ""): Observable<Equipment[]> {
    return this.api.get(prefix + "room/" + room._id + "/equipments?populate=cneh,tva").pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  getAllUnique(versionId = "", params = {}): Observable<any> {
    return this.api.getWithHeader("pretty/nodupe" + versionId, params).pipe(
      map(resp => {
        resp.data.equipments.map(item => {
          return this.adapter.adapt(item);
        });
        return {data: resp.data.equipments, total: resp.total};
      })
    );
  }

  // overrite super fonction because we want populate cneh
  getAllList(params: IListParams): Observable<{ total: number, data: Equipment[] }> {
    params = params || super.giveDefaultParams();
    if (!params.populate || params.populate === '') {
      params.populate = 'cneh';
    }
    return this.api.getWithHeader('equipment', params)
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }

  // getAllLibraryUnique(prefix = '', params = {}): Observable<any> {
  //   return this.api.getWithHeader('pretty/noduple', params).pipe(
  //     map(resp => {
  //       resp.data.map(item => {
  //         return this.adapter.adapt(item);
  //       });
  //       return {data: resp.data, total: resp.total};
  //     })
  //   );
  // }

  getAllInSet(set: Set, prefix: string = ""): Observable<Equipment[]> {
    return this.api.get(prefix + "set/" + set._id + "/equipments?populate=cneh").pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  getAllInSetCustom(set: Set, library: boolean = false): Observable<any> {
    return this.api.get("pretty/pepefe" + (library ? "ls/" : "s/") + set._id)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getAllInVersion(version: Version): Observable<Equipment[]> {
    return this.api.get("version/" + version._id + "/equipments?populate=cneh").pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  LibraryGetAll(): Observable<Equipment[]> {
    return this.api.get("library/equipment?populate=cneh").pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Equipment> {
    return this.api.get("equipment/" + id + "?populate=tva,cneh,finance").pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  getInBoth(id: string, isLibrary: boolean): Observable<Equipment> {
    let populate = "populate=tva,cneh";
    if (!isLibrary){
      populate += ',finance'
    }
    return this.api.get((isLibrary ? "library/" : "") + "equipment/" + id + "?" + populate).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  clone(data): Observable<Equipment> {
    return this.api.post("clone", data).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  getLibrary(id: string): Observable<Equipment> {
    return this.api.get("library/equipment/" + id + "?populate=tva,cneh").pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  getInLibrary(id: string): Observable<Equipment> {
    return this.api.get("library/equipment/" + id + "?populate=tva,cneh").pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id: string, equipment: Equipment, prefix: string = ""): Observable<Equipment> {
    return this.api.put(prefix + "equipment/" + id + "?populate=tva,cneh", equipment).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  putInLibrary(id: string, equipment: Equipment): Observable<Equipment> {
    return this.api.put("library/equipment/" + id + "?populate=tva,cneh", equipment).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  putInBoth(id: string, equipment: Equipment, isLibrary: boolean): Observable<Equipment> {
    return this.api.put((isLibrary ? "library/" : "") + "equipment/" + id + "?populate=tva,cneh", equipment).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  postInRoom(room: Room, equipment: Equipment, library: boolean): Observable<Equipment> {
    let prefix = "";
    if (library) {
      prefix = "library/";
      equipment.standalone = false;
    }
    return this.api.post(prefix + "room/" + room._id + "/equipments", equipment).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  postInLibrary(equipment: Equipment): Observable<Equipment> {
    return this.api.post("library/equipment", equipment).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  postInSet(set: Set, equipment: Equipment, library: boolean): Observable<Equipment> {
    let prefix = "";
    if (library) {
      prefix = "library/";
      equipment.standalone = false;
    }
    return this.api.post(prefix + "set/" + set._id + "/equipments", equipment).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options): Observable<Equipment> {
    return this.api.delete("equipment/" + id, options);
  }

  deleteInLibrary(id: string, options: Options): Observable<Equipment> {
    return this.api.delete("library/equipment/" + id, options);
  }

  export() {
    this.api.download("library/export/equipment")
  }
}
