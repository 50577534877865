import {Injectable} from '@angular/core';
import {ApiService, Options} from './api.service';
import {map, tap} from 'rxjs/operators';
// @ts-ignore
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Project, ProjectAdapter} from '../model/project.model';
import {VersionService} from './version.service';
import {Version} from '../model/version.model';
import {UserService} from './user.service';
import {ListGetterService} from './generic/list-getter.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ListGetterService<Project> implements Resolve<Project[] | Project> {

  constructor(private userService: UserService,
              private versionService: VersionService,
              protected api: ApiService,
              private adapter: ProjectAdapter) {
    super('project', api);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project[] | Project> | Promise<Project[] | Project> | Project[] | Project {
    if (route.params.idProject) {
      return this.get(route.params.idProject);
    }
    return this.getAll();
  }

  getAll(withoutChief?: boolean): Observable<Project[]> {
    return this.api.get(`project?${withoutChief ? '' : 'populate=chiefUser'}`).pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Project> {
    return this.api.get('project/' + id + '?populate=chiefUser,users').pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id: string, project: Project): Observable<Project> {
    return this.api.put('project/' + id + '?populate=chiefUser', project, {
      errorSnackBar: true,
      successSnackBar: true,
      title: project.title
    }).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(project: Project): Observable<Project> {
    return this.api.post('project?populate=chiefUser', project).pipe(
      map(item => {
        return this.adapter.adapt(item);
      }),
      tap((project: Project) => {
        this.userService.postInProject(project, project.chiefUser).subscribe((e) => {
          //return e;
        });
      }),
      tap((project: Project) => {
        let version = new Version();
        version.label = 'Version initiale';
        this.versionService.post(project, version).subscribe((e) => {
          return e;
        });
      })
    );
  }

  delete(id: string, options: Options): Observable<Project> {
    return this.api.delete('project/' + id, options);
  }
}
