import {Injectable} from "@angular/core";
import {IListParams, ListGetterService} from "../../generic/list-getter.service";
import {ApiService} from "../../api.service";
import {Room} from "../../../model/room.model";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LibRoomService extends ListGetterService<Room> {

  constructor(protected api: ApiService) {
    super("library/room/", api);
  }
}
