import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DialogZoneFormComponent} from './dialog-zone-form/dialog-zone-form.component';
import {Room} from '../../../../model/room.model';
import {RoomService} from '../../../../services/room.service';
import {DialogDeleteComponent} from '../../../atoms/dialog-delete/dialog-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit, OnChanges {

  @Input() room: Room;
  @Input() library: boolean = false;
  @Input() write:boolean = false;

  displayedColumns: string[] = ['name', 'comment', 'action'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private roomService: RoomService, private dialog: MatDialog) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource<any>(changes.room.currentValue.zones);
    this.dataSource.sort = this.sort;
    this.refresh();
  }

  addZone() {
    const dialogRef = this.dialog.open(DialogZoneFormComponent, {data: {zone: ''}});
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.roomService.patch(this.room._id, {
          patchs: [{
            'op': 'add', 'path': '/zones/-', 'value': result
          }]
        }, (this.library?'library/':'')).subscribe((e) => {
          this.room.zones = e.zones;
          this.refresh();
        });
      }
    });
  }

  refresh() {
    this.dataSource.data = [...this.room.zones];
  }

  delete(zone){
    let index = -1;
    this.room.zones.forEach((e, k) => {
      if (e == zone) {
        index = k;
      }
    });
    const dialogRef = this.dialog.open(DialogDeleteComponent);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.roomService.patch(this.room._id, {
          patchs: [{
            'op': 'remove', 'path': '/zones/' + index
          }]
        }, (this.library?'library/':'')).subscribe((e) => {
          this.room.zones = e.zones;
          this.refresh();
        });
      }
    })
  }

  edit(zone: any) {
    let index = -1;
    this.room.zones.forEach((e, k) => {
      if (e == zone) {
        index = k;
      }
    });
    const dialogRef = this.dialog.open(DialogZoneFormComponent, {data: {zone: zone}});
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.roomService.patch(this.room._id, {
          patchs: [{
            'op': 'replace', 'path': '/zones/' + index, 'value': result
          }]
        },(this.library?'library/':'')).subscribe((e) => {
          this.room.zones = e.zones;
          this.refresh();
        });
      }
    })
  }
}
