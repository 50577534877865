<div class="card">
  <mat-tab-group>
    
    <!--Constraint-->
    <mat-tab label="Contrainte">
      <ng-template matTabContent>
        <app-list-constraint
          [actions]="actions"
          [hookEvents]="hookConstraint"
          (select)="select.emit($event)"
          (delete)="delete.emit($event)"
          (create)="create.emit()">
        </app-list-constraint>
      </ng-template>
    </mat-tab>
    
    <!--Specialité-->
    <mat-tab label="Spécialité de contrainte">
      <ng-template matTabContent>
        <app-list-speciality
          [actions]="actions"
          [hookEvents]="hookSpeciality"
          (select)="selectSpeciality.emit($event)"
          (delete)="deleteSpeciality.emit($event)"
          (create)="createSpeciality.emit()">
        </app-list-speciality>
      </ng-template>
    </mat-tab>
    
  </mat-tab-group>
</div>
