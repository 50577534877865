<div class="card">
  <div class="card-header">
    Maintenance
  </div>
  <div class="card-content">
    <div class="form" [formGroup]="formGroup">
      <div>
        <mat-slide-toggle
          color="warn"
          formControlName="cac">
          {{(formGroup.controls.cac.value ? 'common.cac.r' : 'common.cac.a') | translate}}
        </mat-slide-toggle>
      </div>
      <div>
        <!--In market-->
        <mat-form-field>
          <mat-label>
            {{'common.inMarket' | translate}}
          </mat-label>
          <input matInput
                 formControlName="inMarket"
                 [matDatepicker]="dp">
          <mat-datepicker-toggle matSuffix
                                 [for]="dp">
          </mat-datepicker-toggle>
          <mat-datepicker #dp>
          </mat-datepicker>
        </mat-form-field>
        <!--Out market-->
        <mat-form-field>
          <mat-label>
            {{'common.outMarket' | translate}}
          </mat-label>
          <input matInput
                 formControlName="outMarket"
                 [ngStyle]="{
                                    'color': redDate()? 'red':''
                                 }"
                 [matDatepicker]="dp2">
          <mat-datepicker-toggle matSuffix
                                 [for]="dp2">
          </mat-datepicker-toggle>
          <mat-datepicker #dp2>
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
