import {Component, Inject} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {MAT_DIALOG_DATA,} from '@angular/material/dialog';
import {TIPPY_REF, TippyInstance} from '@ngneat/helipopper';

@Component({
  selector: "app-dialog-file-viewer",
  templateUrl: "./dialog-file-viewer.component.html",
  styleUrls: ["./dialog-file-viewer.component.scss"]
})
export class DialogFileViewerComponent {

  url: string = "";

  constructor(private apiService: ApiService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              @Inject(TIPPY_REF) tippy: TippyInstance) {
    this.apiService
      .openUrlWithTempToken("file/" + this.data._id)
      .subscribe((e) => {
        this.url = e;
      })
  }

  download() {
    this.apiService.download("file/" + this.data._id);
  }
}
