import {Injectable} from "@angular/core";
import {ApiService, Options} from "./api.service";
import {map} from "rxjs/operators";
// @ts-ignore
import {Observable} from "rxjs";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Speciality, SpecialityAdapter} from "../model/speciality.model";
import {ListGetterService} from "./generic/list-getter.service";

@Injectable({
  providedIn: "root"
})
export class SpecialityService extends ListGetterService<Speciality> implements Resolve<Speciality[]> {

  constructor(protected api: ApiService, private adapter: SpecialityAdapter) {
    super("speciality", api)
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Speciality[]> | Promise<Speciality[]> | Speciality[] {
    return this.getAll();
  }

  getAll(): Observable<Speciality[]> {
    return this.api.get("speciality").pipe(
      map(data => {
        return data.map(item => {
          return this.adapter.adapt(item);
        });
      })
    );
  }

  get(id: string): Observable<Speciality> {
    return this.api.get("speciality/" + id).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  put(id: string, speciality: Speciality): Observable<Speciality> {
    return this.api.put("speciality/" + id, speciality).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  post(speciality: Speciality): Observable<Speciality> {
    return this.api.post("speciality", speciality).pipe(
      map(item => {
        return this.adapter.adapt(item);
      })
    );
  }

  delete(id: string, options: Options): Observable<Speciality> {
    return this.api.delete("speciality/" + id, options);
  }
}
