/* tslint:disable:variable-name */
import {Injectable} from '@angular/core';
import {Adapter} from '../adapter';
import {UserAdapter} from './user.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WithId} from './interface/withId.interface';

export class Me implements WithId {

  public _id;
  public deleted;
  public firstName;
  public lastName;
  public login;
  public mail;
  public createdAt;
  public level;
  public rights = {
    admin: false,
    library: false,
    project: false
  };

  static formBuilderModify(fb:FormBuilder, me:Me):FormGroup{
    return fb.group({
      firstName: [me.firstName, Validators.required],
      lastName: [me.lastName, Validators.required],
      mail: [me.mail],
      password: [''],
      password2: ['']
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class MeAdapter implements Adapter<Me> {

  adapt(item: any): Me {
    const d = new Me();
    d._id = item._id;
    d.firstName = item.firstName;
    d.lastName = item.lastName;
    d.login = item.login;
    d.mail = item.mail;
    d.createdAt = new Date(item.createdAt);
    d.deleted = item.deleted;
    d.rights = UserAdapter.getRights(item.level);
    d.level = item.level;
    return d;
  }
}
