import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Set} from '../../../../model/set.model';
import {Equipment} from '../../../../model/equipment.model';

@Component({
  selector: 'app-room-ef-equipment-list',
  templateUrl: './room-ef-equipment-list.component.html',
  styleUrls: ['./room-ef-equipment-list.component.scss']
})
export class RoomEfEquipmentListComponent {

  @Input()
  write: boolean;

  @Input()
  setSelection: boolean;

  @Input()
  groupSet: any[];

  @Input()
  groupEquipment: any[];

  @Input()
  withDeleteButton: boolean;

  @Output()
  deleteSet: EventEmitter<Set> = new EventEmitter();

  @Output()
  deleteSetGroup: EventEmitter<any> = new EventEmitter();

  @Output()
  deleteEquipment: EventEmitter<Equipment> = new EventEmitter();

  @Output()
  deleteEquipmentGroup: EventEmitter<any> = new EventEmitter();

  @Output()
  setSelected: EventEmitter<Set> = new EventEmitter();

  @Output()
  equipmentSelected: EventEmitter<Equipment> = new EventEmitter();

  constructor() {
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }
}
