<h2 *ngIf="!edit"
    mat-dialog-title>
  Créer une nouvelle configuration
</h2>

<h2 *ngIf="edit"
    mat-dialog-title>
  Modifier une configuration
</h2>

<mat-dialog-content class="mat-typography form">
  <mat-horizontal-stepper linear
                          #stepper>

    <!--    STEP CONTRAINTE-->
    <mat-step *ngIf="!edit"
              label="Choix de la contrainte"
              editable="true">
      <!--Constraint-->
      <app-list-constraint
        [actions]="['search', 'select']"
        [perPage]="10"
        (select)="constraintSelected($event)">
      </app-list-constraint>
    </mat-step>


    <!--    STEP CONFIGURATION-->
    <mat-step label="Paramétrage"
              editable="false">
      <div class="col">
        <div class="card">
          <div class="card-header">
            Paramètres
          </div>
          <div class="card-content">
            <div class="flex">
              <div class="col">
                <form [formGroup]="profileForm"
                      class="form">
                  <mat-form-field>
                    <input matInput
                           placeholder="{{'configuration.constraintLabel' | translate}}"
                           formControlName="constraintLabel">
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput
                           placeholder="{{'configuration.constraintSpecialityLabel' | translate}}"
                           formControlName="constraintSpecialityLabel">
                  </mat-form-field>
                  <mat-slide-toggle formControlName="isPhysic"
                                    disabled="true">{{'configuration.isPhysic' | translate}}</mat-slide-toggle>
                  <mat-form-field>
                    <input matInput
                           placeholder="{{'configuration.quantity' | translate}}"
                           formControlName="quantity">
                  </mat-form-field>
                  <mat-form-field>
                <textarea matInput
                          placeholder="{{'configuration.comments' | translate}}"
                          formControlName="comments"></textarea>
                  </mat-form-field>
                </form>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    Valeurs
                  </div>
                  <div class="card-content">
                    <form [formGroup]="profileFormValues"
                          class="form">
                      <mat-form-field *ngFor="let v of configuration.values | keys">
                        <input matInput
                               placeholder="{{v.key}}"
                               [formControlName]="v.key">
                        <span matSuffix>{{v.key}}</span>
                      </mat-form-field>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button *ngIf="!edit"
                    mat-flat-button
                    color="warn"
                    (click)="contraintUnselected()"
                    matStepperPrevious>
              Retour
            </button>
            <button *ngIf="!edit"
                    mat-flat-button
                    color="primary"
                    (click)="finish()"
                    [disabled]="!profileFormValues.valid || !profileForm.valid">
              Terminer
            </button>
            <button *ngIf="edit"
                    mat-flat-button
                    color="primary"
                    (click)="finish()"
                    [disabled]="!profileFormValues.valid || !profileForm.valid">
              Modifier
            </button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>
