import {
  Component,
  ComponentFactoryResolver, ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {IFieldConfig, ListerComponent} from '../../../generics/lister/lister.component';
import {DynamicHostDirective} from '../../../../directives/dynamic-host.directive'
import {ListGetterService} from '../../../../services/generic/list-getter.service';
import {LibEquipmentService} from '../../../../services/entities/lib-equipment/lib-equipment.service';
import {Equipment} from '../../../../model/equipment.model';

@Component({
  selector: 'app-list-lib-equipment',
  templateUrl: './list-lib-equipment.component.html',
  styleUrls: ['./list-lib-equipment.component.scss']
})
export class ListLibEquipmentComponent implements OnInit {

  @Input()
  hookEvents: Subject<string>;

  @Input()
  actions: String[];

  // =============================== OUTPUTS
  @Output()
  select: EventEmitter<Equipment> = new EventEmitter();

  @Output()
  delete: EventEmitter<Equipment> = new EventEmitter();

  @Output()
  create: EventEmitter<Equipment> = new EventEmitter();

  @Output()
  import: EventEmitter<Equipment> = new EventEmitter();

  @Output()
  export: EventEmitter<number> = new EventEmitter();

  component: any;
  fields: IFieldConfig[] = [
    {
      key: 'label',
      i18Key: 'equipment.label',
      width: 25,
      searchable: true
    },
    {
      key: 'cneh',
      i18Key: 'cneh.code2010',
      width: 15,
      searchable: true,
      transform: (cneh) => cneh ? cneh.code2010 : ''
    },
    {
      key: 'brand',
      i18Key: 'equipment.brand',
      width: 15,
      searchable: true
    },
    {
      key: 'model',
      i18Key: 'equipment.model',
      width: 35,
      searchable: true
    },
    {
      key: 'price',
      i18Key: 'common.price',
      width: 13,
      searchable: false,
      serviceFunctionName: 'getOneElementPrice'
    },
  ];

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  constructor(private factory: ComponentFactoryResolver,
              private libEquipmentService: LibEquipmentService,
              private injector: Injector
  ) {
    this.component = ListerComponent
  }

  ngOnInit(): void {
    let factory = this.factory.resolveComponentFactory(this.component);
    const ref: ComponentRef<any> = this.dynamicHost.viewContainerRef
      .createComponent(factory, undefined, Injector.create({
        providers: [
          {
            provide: ListGetterService,
            useValue: this.libEquipmentService
          },
          {
            provide: 'FIELD_CONFIG',
            useValue: this.fields
          }
        ],
        parent: this.injector
      }));
    ref.instance.actions = this.actions;
    ref.instance.select = this.select;
    ref.instance.delete = this.delete;
    ref.instance.create = this.create;
    ref.instance.export = this.export;
    ref.instance.queriables = ['equipment.label'];
    ref.instance.import = this.import;
    ref.instance.hookEvents = this.hookEvents;
  }
}
