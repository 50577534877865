import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss']
})
export class DialogDeleteComponent {

  title = 'Confirmation nécessaire';
  text = null;
  verif = null;
  verifInput = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.title) {
        this.title = data.title;
      }
      if (data.text) {
        this.text = data.text;
      }
      if (data.verif) {
        this.verif = data.verif;
      }
    }
  }
}
