<div class="card no-radius">
  <div class="card-content">
    <mat-horizontal-stepper linear #stepper>
      <!-- PROJECT -->
      <mat-step>
        <ng-template matStepLabel>
          <div (click)="reSelectProject()">
            Sélectionnez un projet
          </div>
        </ng-template>

        <app-list-project (select)="selectProject($event)">
        </app-list-project>
      </mat-step>

      <!-- Version -->
      <mat-step>
        <ng-template matStepLabel>Version</ng-template>
        <!-- lazy loading -->
        <ng-container *ngIf="stepper.selectedIndex === 1">
          <app-list-version [customPath]="customVersionPath"
                            (select)="selectVersion($event)">
          </app-list-version>
        </ng-container>
      </mat-step>

      <!-- Room -->
      <mat-step>
        <ng-template matStepLabel>Local</ng-template>
        <!-- lazy loading -->
        <ng-container *ngIf="stepper.selectedIndex === 2">
          <app-list-room [customPath]="customRoomPath"
                         [actions]="['select']"
                         (select)="selectRoom($event)">
          </app-list-room>
        </ng-container>
      </mat-step>

      <!-- Ef ou Equipement -->
      <mat-step>
        <ng-template matStepLabel>Sélectionnez l'équipment à importer</ng-template>
        <!-- lazy loading -->
        <ng-container *ngIf="stepper.selectedIndex === 3">
          <app-room-ef-equipment-list
            [setSelection]="false"
            [groupSet]="groupSet"
            [groupEquipment]="groupEquipment"
            [withDeleteButton]="false"
            (equipmentSelected)="selectEquipment($event)"
          >
          </app-room-ef-equipment-list>
        </ng-container>
      </mat-step>

    </mat-horizontal-stepper>
  </div>
</div>
