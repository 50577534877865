import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WithId} from './interface/withId.interface';

export class Tva implements WithId {
  public _id;
  public typeTva;
  public value;

  static formBuilder(fb:FormBuilder, tva:Tva):FormGroup{
    return fb.group({
      typeTva: [tva.typeTva, Validators.required],
      value: [tva.value, Validators.required],
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class TvaAdapter implements Adapter<Tva> {
  adapt(item: any): Tva {
    const d = new Tva();
    d._id = item._id;
    d.typeTva = item.typeTva;
    d.value = item.value;
    return d;
  }
}
