import {Injectable} from '@angular/core';

// @ts-ignore
import {Adapter} from '../adapter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WithId} from './interface/withId.interface';

export class Cneh implements WithId {
  public _id;
  public code2010;
  public family;
  public codeFamily;
  public fct;
  public codeFct;
  public cpnt;
  public cpntCode;
  public eqpmt;
  public eqpmtCode;

  static formBuilder(fb:FormBuilder, cneh:Cneh):FormGroup{
    return fb.group({
      code2010: [cneh.code2010, Validators.required],
      family: [cneh.family],
      codeFamily: [cneh.codeFamily],
      fct: [cneh.fct],
      codeFct: [cneh.codeFct],
      eqpmt: [cneh.eqpmt],
      eqpmtCode: [cneh.eqpmtCode],
      cpnt: [cneh.cpnt],
      cpntCode: [cneh.cpntCode]
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class CnehAdapter implements Adapter<Cneh> {
  adapt(item: any): Cneh {
    const d = new Cneh();
    d._id = item._id;
    d.code2010 = item.code2010;
    d.family = item.family;
    d.codeFamily = item.codeFamily;
    d.fct = item.fct;
    d.codeFct = item.codeFct;
    d.eqpmt = item.eqpmt;
    d.eqpmtCode = item.eqpmtCode;
    d.cpnt = item.cpnt;
    d.cpntCode = item.cpntCode;
    return d;
  }
}
